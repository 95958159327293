/** @jsx jsx */
import { Fragment } from "react"
import { jsx } from "theme-ui"

export const plusCircleFilledIcon = {
  path: (
    <Fragment>
      <circle cx="12.3335" cy="12" r="12" fill="currentColor" />
      <path
        d="M7.10254 12.3076H18.1795"
        stroke="white"
        strokeWidth="1.5"
        strokeLinecap="round"
      />
      <path
        d="M12.6411 6.15381V18.4615"
        stroke="white"
        strokeWidth="1.5"
        strokeLinecap="round"
      />
    </Fragment>
  ),
  viewBox: "0 0 25 24",
}
