/** @jsx jsx */
import { forwardRef } from "react"

import { jsx } from "@trueskin-web/theme"

import NavLink from "./nav-link"

const HeaderNavLink = forwardRef((props, ref) => (
  <NavLink
    ref={ref}
    sx={{
      justifyContent: "start",
    }}
    {...props}
  />
))

export default HeaderNavLink
