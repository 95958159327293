/** @jsx jsx */
import { Fragment } from "react"
import { jsx } from "theme-ui"

export const exchangeIcon = {
  path: (
    <Fragment>
      <rect x="6" y="8" width="14" height="14" fill="#F2CCD6" />
      <path
        d="M15.3536 4.35355C15.5488 4.15829 15.5488 3.84171 15.3536 3.64645L12.1716 0.464467C11.9763 0.269205 11.6597 0.269205 11.4645 0.464467C11.2692 0.659729 11.2692 0.976312 11.4645 1.17157L14.2929 4L11.4645 6.82843C11.2692 7.02369 11.2692 7.34027 11.4645 7.53553C11.6597 7.7308 11.9763 7.7308 12.1716 7.53553L15.3536 4.35355ZM1 4.5L15 4.5L15 3.5L1 3.5L1 4.5Z"
        fill="currentColor"
      />
      <path
        d="M0.646446 14.6464C0.451184 14.8417 0.451184 15.1583 0.646446 15.3536L3.82843 18.5355C4.02369 18.7308 4.34027 18.7308 4.53553 18.5355C4.7308 18.3403 4.7308 18.0237 4.53553 17.8284L1.70711 15L4.53553 12.1716C4.7308 11.9763 4.7308 11.6597 4.53553 11.4645C4.34027 11.2692 4.02369 11.2692 3.82843 11.4645L0.646446 14.6464ZM15 14.5L1 14.5L1 15.5L15 15.5L15 14.5Z"
        fill="currentColor"
      />
    </Fragment>
  ),
  viewBox: "0 0 20 22",
  fill: "none",
}
