/** @jsx jsx */
import { graphql, useStaticQuery } from "gatsby"

import { TopBanner as TopBannerContainer } from "@trueskin-web/components"
import { jsx } from "@trueskin-web/theme"

const TopBanner = () => {
  const {
    strapi: { banner },
  } = useStaticQuery(graphql`
    {
      strapi {
        banner {
          active
          linkCta {
            label
            targetUrl
          }
          description
          backgroundColor
          fontColor
          bannedSlugs {
            description
          }
        }
      }
    }
  `)

  return <TopBannerContainer {...banner} />
}

export default TopBanner
