/** @jsx jsx */
import { Fragment } from "react"
import { jsx } from "theme-ui"

export const pregnancyIcon = {
  path: (
    <Fragment>
      <path
        d="M7.94868 8.00142C10.1546 8.00142 11.9494 6.20668 11.9494 4.00071C11.9494 1.79474 10.1547 0 7.94868 0C5.74201 0 3.94727 1.79474 3.94727 4.00071C3.94727 6.20668 5.74201 8.00142 7.94868 8.00142ZM7.94868 0.727273C9.75337 0.727273 11.2221 2.19566 11.2221 4.00071C11.2221 5.80576 9.75337 7.27415 7.94868 7.27415C6.14329 7.27415 4.67454 5.80576 4.67454 4.00071C4.67454 2.19566 6.14329 0.727273 7.94868 0.727273Z"
        fill="currentColor"
      />
      <path
        d="M9.75867 11.2994C9.57188 11.2213 9.36023 11.3072 9.28211 11.4926L7.59603 15.4745C7.49802 15.7053 7.30839 15.89 7.07472 15.9809L4.09106 17.1492C3.83253 17.2497 3.55128 17.2444 3.29702 17.1336C3.04347 17.0228 2.84816 16.8193 2.7473 16.5608C2.64574 16.303 2.65142 16.0214 2.76222 15.7675C2.87302 15.5136 3.07685 15.3179 3.33538 15.2167L5.72955 14.2795C5.82046 14.2437 5.89362 14.1727 5.93125 14.0825L7.37088 10.6833C7.44972 10.4986 7.36307 10.2852 7.17841 10.2067C6.99517 10.1286 6.77997 10.2146 6.70185 10.3999L5.32188 13.6581L3.07046 14.5395C2.63083 14.7114 2.28495 15.0441 2.09603 15.4763C1.90711 15.9081 1.89788 16.3875 2.06975 16.8261C2.24163 17.2653 2.57402 17.6112 3.00584 17.8001C3.2324 17.8992 3.47245 17.9489 3.71251 17.9489C3.92984 17.9486 4.14788 17.9081 4.35669 17.8264L7.33964 16.6585C7.75441 16.4962 8.09177 16.168 8.26506 15.7579L9.95114 11.776C10.03 11.5913 9.94333 11.3779 9.75867 11.2994Z"
        fill="currentColor"
      />
      <path
        d="M13.3814 19.8861L11.2024 10.9791C10.9901 10.1098 10.4517 9.37541 9.68679 8.91128C8.92117 8.44679 8.02272 8.30865 7.15199 8.52101C5.93466 8.81895 4.9929 9.76071 4.6946 10.9788L4.53764 11.6183C2.01065 11.7675 0 13.8712 0 16.4354C0 17.9301 0.70952 19.3641 1.89773 20.2714C2.06676 20.4006 2.11861 20.6311 2.01989 20.82L0.816058 23.1265C0.73864 23.2746 0.772022 23.4557 0.897025 23.5665C1.92756 24.4834 3.44532 24.5469 4.54333 23.7596C4.89724 24.0133 5.29555 24.1728 5.70597 24.2497V29.7572C5.70597 30.994 6.71236 32 7.94886 32C9.18536 32 10.1911 30.994 10.1911 29.7572V24.2496C10.6015 24.1728 10.9998 24.0133 11.3537 23.7596C11.8615 24.1236 12.4588 24.3054 13.0561 24.3054C13.7507 24.3054 14.446 24.059 15 23.5665C15.125 23.4557 15.1584 23.2746 15.081 23.1265L13.3814 19.8861ZM7.94885 31.2728C7.11292 31.2728 6.43323 30.5927 6.43323 29.7571V24.2913C6.96742 24.2572 7.4944 24.0855 7.94885 23.7596C8.40336 24.0851 8.93003 24.2567 9.46377 24.291V29.7571C9.46378 30.5927 8.78408 31.2728 7.94885 31.2728ZM11.5952 23.0231C11.5263 22.9617 11.4403 22.9312 11.3537 22.9312C11.267 22.9312 11.1811 22.9617 11.1122 23.0231C10.2798 23.7632 9.02272 23.7632 8.19033 23.0231C8.05326 22.901 7.84587 22.9003 7.70738 23.0228C6.87358 23.7628 5.61718 23.7632 4.7848 23.0231C4.64702 22.9003 4.43963 22.9003 4.30185 23.0231C3.53977 23.701 2.42188 23.7582 1.60085 23.1954L2.66477 21.1567C2.92756 20.6531 2.79119 20.0377 2.33878 19.6932C1.32954 18.9226 0.727273 17.7049 0.727273 16.4354C0.727273 14.1755 2.56534 12.3371 4.82529 12.3371C4.9929 12.3371 5.13849 12.2227 5.17827 12.0601L5.40057 11.152C5.63423 10.1982 6.37144 9.46064 7.32528 9.22768C8.00496 9.06042 8.71022 9.16979 9.30894 9.53272C9.90838 9.89635 10.3295 10.4713 10.4964 11.1517L12.6733 20.0512C12.6804 20.0824 12.7017 20.14 12.7166 20.1684L14.2962 23.1954C13.4744 23.7582 12.3572 23.701 11.5952 23.0231Z"
        fill="currentColor"
      />
    </Fragment>
  ),
  viewBox: "0 0 16 32",
}
