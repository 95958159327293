/** @jsx jsx */
import { Fragment } from "react"
import { jsx } from "theme-ui"

export const shareIcon = {
  path: (
    <path
      d="M10.5 10C9.642 10 8.869 10.364 8.3215 10.9435L5.736 9.225C6.071 8.484 6.0955 7.6345 5.763 6.8375C5.754 6.816 5.7415 6.7975 5.732 6.776L8.317 5.054C8.8845 5.657 9.6645 6 10.5 6C12.1545 6 13.5 4.6545 13.5 3C13.5 1.3455 12.1545 0 10.5 0C8.8455 0 7.5 1.3455 7.5 3C7.5 3.427 7.598 3.836 7.769 4.218L5.181 5.942C4.63 5.349 3.8595 5 3 5C1.346 5 0 6.3455 0 8C0 9.6545 1.3455 11 3 11C3.798 11 4.5515 10.6895 5.1255 10.1215C5.1455 10.101 5.161 10.078 5.1805 10.057L7.764 11.774C7.596 12.149 7.5 12.563 7.5 13C7.5 14.6545 8.8455 16 10.5 16C12.1545 16 13.5 14.6545 13.5 13C13.5 11.3455 12.154 10 10.5 10ZM10.5 1C11.603 1 12.5 1.897 12.5 3C12.5 4.1025 11.603 5 10.5 5C9.882 5 9.307 4.7195 8.925 4.2335C8.6465 3.8755 8.5 3.449 8.5 3C8.5 1.897 9.3965 1 10.5 1ZM3 10C1.897 10 1 9.1025 1 8C1 6.897 1.897 6 3 6C5.6065 6 5.676 10 3 10ZM10.5 15C9.397 15 8.5 14.1025 8.5 13C8.5 11.897 9.397 11 10.5 11C11.603 11 12.5 11.897 12.5 13C12.5 14.1025 11.603 15 10.5 15Z"
      fill="currentColor"
    />
  ),
  viewBox: "0 0 13.5 16",
}
