/** @jsx jsx */
import { Fragment } from "react"
import { jsx } from "theme-ui"

export const moisturizerIcon = {
  path: (
    <Fragment>
      <path
        d="M5.68213 6.54433C5.68213 6.50328 5.69057 6.46287 5.70672 6.42668C5.72286 6.39049 5.74621 6.35964 5.77468 6.33687C5.80315 6.3141 5.98163 6.13556 6.01569 6.1316C6.04975 6.12765 6.41286 5.64254 7.2072 5.15347C9.9874 3.68628 10.0249 3.90756 12.3704 3.68628C14.7164 3.79844 17.5337 4.17534 19.3357 6.14157C19.368 6.1214 19.4041 6.11174 19.4404 6.11355C19.4767 6.11536 19.512 6.12857 19.5428 6.15186C19.5735 6.17515 19.5986 6.20772 19.6156 6.2463C19.6326 6.28488 19.6408 6.32812 19.6395 6.37171L19.9113 56.544C19.9204 56.7489 19.8837 56.9529 19.805 57.135C19.7263 57.3171 19.6084 57.4706 19.4636 57.5797C17.4802 58.9164 15.3735 59.3638 12.9566 59.4976C10.6896 59.6231 8.43627 59.0136 6.43356 57.7331C6.28808 57.6235 6.16886 57.4704 6.08758 57.2888C6.0063 57.1073 5.96575 56.9035 5.96991 56.6975L5.68213 6.54433Z"
        fill="white"
      />
      <path
        d="M16 35V44.9326C15.404 45.3133 14.7109 45.6026 13.9613 45.7834C13.2117 45.9641 12.4208 46.0329 11.6347 45.9855C9.96519 46.0767 8.31032 45.7008 7 44.9326V35C9.88224 35.8764 13.1178 35.8764 16 35Z"
        fill="#DBA5BA"
      />
      <path
        d="M4.0874 4.79758C4.0874 4.61645 4.25721 4.4957 4.4058 4.5762C6.27184 6.0347 8.65881 6.75546 11.0708 6.58872C13.4671 6.68685 15.8221 5.97348 17.7145 4.5762C17.8631 4.5762 18.0117 4.5762 18.0117 4.79758L18.3088 54.0642C18.3172 54.2546 18.2795 54.4443 18.1985 54.6187C18.1176 54.7931 17.9956 54.9477 17.8419 55.0705C15.9563 56.3613 13.671 57.0199 11.3467 56.9421C9.03706 57.055 6.75305 56.4393 4.85154 55.1912C4.69738 55.0644 4.57534 54.9062 4.4945 54.7285C4.41365 54.5509 4.37608 54.3582 4.38457 54.1648L4.0874 4.79758Z"
        stroke="currentColor"
      />
      <path
        d="M4.19287 25.3655C6.21101 26.6719 8.6313 27.3022 11.0701 27.1566C13.5759 27.2387 16.0442 26.562 18.1171 25.2246"
        stroke="currentColor"
      />
      <path
        d="M4.0874 4.43516C5.9186 2.91464 8.25518 2.0527 10.6887 2C13.2902 2.00126 15.8189 2.8146 17.8843 4.3144"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </Fragment>
  ),
  viewBox: "0 0 20 60",
  fill: "none",
}
