/** @jsx jsx */
import { jsx } from "@trueskin-web/theme"

const AspectRatioImg = ({
  ratio = 4 / 3,
  src,
  alt = "",
  maxHeight,
  loading = "lazy",
  ...otherProps
}) => (
  <div
    sx={{
      position: "relative",
      overflow: "hidden",
      maxHeight,
    }}
    {...otherProps}
  >
    <div sx={{ width: "100%", paddingBottom: 100 / ratio + "%" }} />
    <img
      src={src}
      alt={alt}
      sx={{
        position: "absolute",
        top: 0,
        left: 0,
        width: "100%",
        height: "100%",
        objectFit: "cover",
        objectPosition: "center center",
      }}
      loading={loading}
    />
  </div>
)

export default AspectRatioImg
