/** @jsx jsx */
import { Fragment } from "react"
import { jsx } from "theme-ui"

export const halfVesselIcon = {
  path: (
    <Fragment>
      <path
        d="M5.6075 10.2315C2.35477 12.161 0.333984 15.7042 0.333984 19.4782C0.333984 24.0702 3.25461 28.1567 7.60179 29.6468C7.6401 29.66 7.68021 29.6667 7.72067 29.6667H14.4673C14.5078 29.6667 14.5479 29.66 14.5862 29.6468C18.9334 28.1567 21.854 24.0702 21.854 19.4782C21.854 15.7043 19.8334 12.1612 16.5806 10.2315C15.1972 9.41084 14.3379 7.92824 14.3379 6.36239V2.93688C14.8975 2.81031 15.3381 2.35036 15.3945 1.78711C15.4318 1.41507 15.31 1.04357 15.0606 0.767857C14.8107 0.491783 14.4537 0.333336 14.0813 0.333336H8.16038C7.46035 0.333336 6.85986 0.853616 6.79344 1.51784C6.7562 1.8897 6.87795 2.2612 7.12734 2.53674C7.31909 2.74872 7.57368 2.89123 7.85011 2.94619V6.36239C7.85011 7.92824 6.99074 9.41084 5.6075 10.2315Z"
        stroke="currentColor"
        strokeWidth="0.5"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M2.31884 17.084C4.95797 18.069 7.90696 18.5615 11.1658 18.5615C14.4246 18.5615 17.402 18.069 20.0979 17.084C27.6932 13.4991 -5.57717 13.1218 2.31884 17.084Z"
        stroke="currentColor"
        strokeWidth="0.5"
      />
      <path
        d="M4.02648 23.5748C4.17138 23.808 4.32745 24.0313 4.4947 24.2447C5.32305 25.3016 6.23606 26.0919 7.23372 26.6154M2.83203 20.3459C3.02451 21.1933 3.08405 21.3059 3.23013 21.6855L2.83203 20.3459Z"
        stroke="currentColor"
        strokeWidth="0.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path d="M7.84961 2.94622H14.3277" stroke="black" strokeWidth="0.5" />
    </Fragment>
  ),
  viewBox: "0 0 23 30",
  fill: "none",
}
