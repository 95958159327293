/** @jsx jsx */
import { Fragment } from "react"
import { jsx } from "theme-ui"

export const fileFilledIcon = {
  path: (
    <Fragment>
      <path
        d="M4 2h10l6 6v14H4V2z"
        fill="currentColor"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M14 2v6h6"
        stroke="#fff"
        strokeLinecap="round"
        strokeLinejoin="round"
        fill="none"
      />
      <path
        d="M16 13H8M16 17H8M10 9H8"
        stroke="#fff"
        strokeLinecap="square"
        strokeLinejoin="round"
      />
    </Fragment>
  ),
}
