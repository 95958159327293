/** @jsx jsx */
import { Fragment } from "react"
import { jsx } from "theme-ui"

export const choiceIcon = {
  path: (
    <Fragment>
      <g clipPath="url(#clip0_6555_5799)">
        <path
          d="M23.3845 7.99985C23.3845 12.1537 20.1537 15.3845 15.9999 15.3845C11.846 15.3845 8.61523 12.1537 8.61523 7.99985C8.61523 6.38447 9.07677 4.99985 9.76908 3.846C10.4614 2.92293 11.3845 1.99985 12.3075 1.53831C13.4614 1.07677 14.6152 0.615234 15.7691 0.615234C20.1537 0.615234 23.3845 3.846 23.3845 7.99985Z"
          stroke="currentColor"
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M12.3066 8.544L14.7485 10.8059L19.3959 4.92285"
          stroke="currentColor"
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <g clipPath="url(#clip1_6555_5799)">
        <path
          d="M23.3845 25.9999C23.3845 30.1537 20.1537 33.3845 15.9999 33.3845C11.846 33.3845 8.61523 30.1537 8.61523 25.9999C8.61523 24.3845 9.07677 22.9998 9.76908 21.846C10.4614 20.9229 11.3845 19.9999 12.3075 19.5383C13.4614 19.0768 14.6152 18.6152 15.7691 18.6152C20.1537 18.6152 23.3845 21.846 23.3845 25.9999Z"
          stroke="currentColor"
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_6555_5799">
          <rect width="16" height="16" fill="white" transform="translate(8)" />
        </clipPath>
        <clipPath id="clip1_6555_5799">
          <rect
            width="16"
            height="16"
            fill="white"
            transform="translate(8 18)"
          />
        </clipPath>
      </defs>
    </Fragment>
  ),
  viewBox: "0 0 32 34",
  fill: "none",
}
