/** @jsx jsx */
import { Fragment } from "react"
import { jsx } from "theme-ui"

export const sentimentNeutralIcon = {
  path: (
    <Fragment>
      <circle
        cx="12.5"
        cy="12"
        r="11.1"
        stroke="currentColor"
        strokeWidth="1.8"
      />
      <path
        d="M8.2998 16.7998H16.6998"
        stroke="currentColor"
        strokeWidth="1.92"
      />
      <circle cx="9.2002" cy="9.90039" r="1.5" fill="currentColor" />
      <circle
        cx="1.5"
        cy="1.5"
        r="1.5"
        transform="matrix(-1 0 0 1 17.417 8.40039)"
        fill="currentColor"
      />
    </Fragment>
  ),
  viewBox: "0 0 25 24",
  fill: "none",
}
