/** @jsx jsx */
import { forwardRef } from "react"

import { jsx } from "@trueskin-web/theme"

import Button from "./button"

const PGRButton = forwardRef(
  (
    { href, as, name, method = "post", value, onHomepage, ...otherProps },
    ref
  ) => {
    return (
      <form method="get" action={href} sx={{ display: "flex" }}>
        <Button
          ref={ref}
          as="input"
          name={name || value?.replace(/\s/gi, "-")?.toLowerCase()}
          type="submit"
          value={value}
          {...otherProps}
          {...(onHomepage && {
            id: "hero-cta",
          })}
          sx={{
            width: "100%",
          }}
        />
      </form>
    )
  }
)

export default PGRButton
