/** @jsx jsx */
import { jsx } from "theme-ui"

export const menuIcon = {
  path: (
    <path
      d="M4 16h24M4 8h16M4 24h16"
      stroke="currentColor"
      strokeWidth="1.75"
      strokeLinecap="square"
      strokeLinejoin="round"
    />
  ),
  viewBox: "0 0 32 32",
}
