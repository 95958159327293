/** @jsx jsx */
import { Fragment } from "react"
import { jsx } from "theme-ui"

export const crossedEyeIcon = {
  path: (
    <Fragment>
      <path
        d="M4.04804 3.875L2.10271 1.92967C1.7109 1.53786 1.70713 0.906372 2.10038 0.513125C2.4909 0.122601 3.12659 0.125121 3.51693 0.515459L20.4828 17.4814C20.8746 17.8732 20.8784 18.5047 20.4852 18.8979C20.0946 19.2884 19.4589 19.2859 19.0686 18.8956L16.3827 16.2096C15.0355 16.7214 13.5747 16.9984 11.9999 16.9984C8.42734 16.9984 5.44117 15.5726 3.03835 13.2155C2.20464 12.3977 1.50122 11.5232 0.922119 10.6472C0.570443 10.1152 0.334771 9.69336 0.210656 9.43581C0.0942446 9.19424 0.0953978 8.80018 0.210656 8.56101C0.334771 8.30345 0.570443 7.88162 0.922119 7.34963C1.50122 6.47362 2.20464 5.59915 3.03835 4.7813C3.36419 4.46166 3.70075 4.15915 4.04804 3.875L4.04804 3.875ZM14.5867 14.4137L13.036 12.8629C12.7055 12.9513 12.3582 12.9984 11.9999 12.9984C9.79074 12.9984 7.99988 11.2075 7.99988 8.99841C7.99988 8.64009 8.04699 8.29277 8.13536 7.96232L6.58462 6.41158C6.20978 7.19485 5.99988 8.07211 5.99988 8.99841C5.99988 12.3121 8.68617 14.9984 11.9999 14.9984C12.9262 14.9984 13.8034 14.7885 14.5867 14.4137ZM7.61707 1.78718C8.96424 1.27545 10.4251 0.998407 11.9999 0.998407C15.5724 0.998407 18.5586 2.4242 20.9614 4.7813C21.7951 5.59915 22.4985 6.47362 23.0776 7.34963C23.4293 7.88162 23.665 8.30345 23.7891 8.56101C23.9055 8.80258 23.9044 9.19663 23.7891 9.43581C23.665 9.69336 23.4293 10.1152 23.0776 10.6472C22.4985 11.5232 21.7951 12.3977 20.9614 13.2155C20.6356 13.5352 20.299 13.8377 19.9517 14.1218L17.4151 11.5852C17.79 10.802 17.9999 9.9247 17.9999 8.99841C17.9999 5.6847 15.3136 2.99841 11.9999 2.99841C11.0736 2.99841 10.1963 3.20831 9.41304 3.58315L7.61707 1.78718ZM10.9638 5.13389C11.2942 5.04552 11.6416 4.99841 11.9999 4.99841C14.209 4.99841 15.9999 6.78927 15.9999 8.99841C15.9999 9.35673 15.9528 9.70404 15.8644 10.0345L10.9638 5.13389Z"
        fill="#B4B2B2"
      />
    </Fragment>
  ),
  viewBox: "0 0 24 20",
  fill: "none",
}
