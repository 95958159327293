/** @jsx jsx */
import { Fragment } from "react"
import { jsx } from "theme-ui"

export const medicalKitIcon = {
  path: (
    <Fragment>
      <path
        d="M16.75 12.5C16.75 12.3674 16.6973 12.2402 16.6036 12.1464C16.5098 12.0527 16.3826 12 16.25 12H13.75V9.5C13.75 9.36739 13.6973 9.24021 13.6036 9.14645C13.5098 9.05268 13.3826 9 13.25 9H11.25C11.1174 9 10.9902 9.05268 10.8964 9.14645C10.8027 9.24021 10.75 9.36739 10.75 9.5V12H8.25C8.11739 12 7.99021 12.0527 7.89645 12.1464C7.80268 12.2402 7.75 12.3674 7.75 12.5V14.5C7.75 14.6326 7.80268 14.7598 7.89645 14.8536C7.99021 14.9473 8.11739 15 8.25 15H10.75V17.5C10.75 17.6326 10.8027 17.7598 10.8964 17.8536C10.9902 17.9473 11.1174 18 11.25 18H13.25C13.3826 18 13.5098 17.9473 13.6036 17.8536C13.6973 17.7598 13.75 17.6326 13.75 17.5V15H16.25C16.3826 15 16.5098 14.9473 16.6036 14.8536C16.6973 14.7598 16.75 14.6326 16.75 14.5V12.5Z"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M21.75 4.5H2.75C1.64543 4.5 0.75 5.50736 0.75 6.75V20.25C0.75 21.4926 1.64543 22.5 2.75 22.5H21.75C22.8546 22.5 23.75 21.4926 23.75 20.25V6.75C23.75 5.50736 22.8546 4.5 21.75 4.5Z"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M8.75 4.5V3C8.75 2.60218 8.90804 2.22064 9.18934 1.93934C9.47064 1.65804 9.85218 1.5 10.25 1.5H14.25C14.6478 1.5 15.0294 1.65804 15.3107 1.93934C15.592 2.22064 15.75 2.60218 15.75 3V4.5"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </Fragment>
  ),
  viewBox: "0 0 25 24",
  fill: "none",
}
