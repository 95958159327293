/** @jsx jsx */
import { useEffect, useState } from "react"

import { Button, Spinner } from "@trueskin-web/components"
import { jsx } from "@trueskin-web/theme"

import Buttons from "./buttons"
import Colors from "./colors"
import Dialogs from "./dialogs"
import Fonts from "./fonts"
import Forms from "./forms"
import Headings from "./headings"
import Icons from "./icons"
import Sizes from "./sizes"
import Spaces from "./spaces"

const pages = [
  { name: "colors", component: Colors },
  { name: "icons", component: Icons },
  { name: "buttons", component: Buttons },
  { name: "fonts", component: Fonts },
  { name: "headings", component: Headings },
  { name: "spaces", component: Spaces },
  { name: "sizes", component: Sizes },
  { name: "forms", component: Forms },
  { name: "dialogs", component: Dialogs },
].map((page) => ({
  ...page,
  type: "page",
}))

const setHistory = (searchParams) => {
  const queryString = `${searchParams}` ? `?${searchParams}` : ""

  window.history.replaceState(
    null,
    "",
    `${window.location.origin}${window.location.pathname}${queryString}`
  )
}

const setHistorySearch = ({ type, name }) => {
  const searchParams = new URLSearchParams(window.location.search)
  searchParams.set(type, name)

  setHistory(searchParams)
}

const clearHistorySearch = ({ type }) => {
  const searchParams = new URLSearchParams(window.location.search)
  searchParams.delete(type)

  setHistory(searchParams)
}

const Menu = ({ items, active, onClick }) => (
  <div
    sx={{
      borderBottom: "1px",
    }}
  >
    {items.map((item) => (
      <Button
        key={item.name}
        variant="link"
        className={item.name === active.name ? "active" : ""}
        onClick={() => {
          onClick(item)
        }}
        size="sm"
        sx={{
          m: 2,
        }}
      >
        {item.name}
      </Button>
    ))}
  </div>
)

const Styleguide = ({ items = pages }) => {
  const [item, setItem] = useState({
    component: () => <Spinner isFullPage />,
  })

  const { component: Component } = item

  useEffect(() => {
    if (item.name) {
      return
    }

    const defaultItem = items[0]
    const lookupType = defaultItem.type

    const searchParams = new URLSearchParams(window.location.search)
    const lookupName = searchParams.get(lookupType)

    if (!lookupName) {
      setItem(defaultItem)
      return
    }

    const lookupItem = items.find(({ name }) => name === lookupName)
    if (!lookupItem) {
      setItem(defaultItem)

      if (lookupType === "page") {
        clearHistorySearch({ type: "section" })
      }

      return
    }

    setItem(lookupItem)
  }, [items, item])

  useEffect(() => {
    if (!item.name) {
      return
    }

    setHistorySearch(item)

    return () => {
      clearHistorySearch(item)
    }
  }, [item])

  return (
    <div>
      <Menu
        items={items}
        active={item}
        onClick={(selection) => {
          if (selection.name === item.name) {
            return
          }

          setItem(selection)
        }}
      />

      <Component />
    </div>
  )
}

export default Styleguide
