/** @jsx jsx */
import { Fragment } from "react"
import { jsx } from "theme-ui"

export const comedonesIcon = {
  path: (
    <Fragment>
      <path
        d="M16 31C24.2843 31 31 24.2843 31 16C31 7.71573 24.2843 1 16 1C7.71573 1 1 7.71573 1 16C1 24.2843 7.71573 31 16 31Z"
        fill="#FDF3F6"
      />
      <path
        d="M16.4553 18.6152C17.4614 18.6152 18.277 17.7995 18.277 16.7934C18.277 15.7873 17.4614 14.9717 16.4553 14.9717C15.4492 14.9717 14.6335 15.7873 14.6335 16.7934C14.6335 17.7995 15.4492 18.6152 16.4553 18.6152Z"
        fill="#1D160F"
      />
      <path
        d="M25.2993 13.8405C26.3054 13.8405 27.121 13.0249 27.121 12.0188C27.121 11.0126 26.3054 10.197 25.2993 10.197C24.2932 10.197 23.4775 11.0126 23.4775 12.0188C23.4775 13.0249 24.2932 13.8405 25.2993 13.8405Z"
        fill="#1D160F"
      />
      <path
        d="M18.6592 8.03741C19.0324 8.03741 19.335 7.73484 19.335 7.3616C19.335 6.98836 19.0324 6.68579 18.6592 6.68579C18.286 6.68579 17.9834 6.98836 17.9834 7.3616C17.9834 7.73484 18.286 8.03741 18.6592 8.03741Z"
        fill="#1D160F"
      />
      <path
        d="M23.9918 22.0825C24.1946 22.0825 24.3591 21.918 24.3591 21.7152C24.3591 21.5123 24.1946 21.3479 23.9918 21.3479C23.789 21.3479 23.6245 21.5123 23.6245 21.7152C23.6245 21.918 23.789 22.0825 23.9918 22.0825Z"
        fill="#1D160F"
      />
      <path
        d="M11.0343 25.52C11.2371 25.52 11.4016 25.3555 11.4016 25.1527C11.4016 24.9498 11.2371 24.7854 11.0343 24.7854C10.8314 24.7854 10.667 24.9498 10.667 25.1527C10.667 25.3555 10.8314 25.52 11.0343 25.52Z"
        fill="#1D160F"
      />
      <path
        d="M20.437 11.8571C20.9319 11.8571 21.3331 11.4558 21.3331 10.9609C21.3331 10.4659 20.9319 10.0647 20.437 10.0647C19.942 10.0647 19.5408 10.4659 19.5408 10.9609C19.5408 11.4558 19.942 11.8571 20.437 11.8571Z"
        fill="#1D160F"
      />
      <path
        d="M10.0793 19.5111C10.5742 19.5111 10.9755 19.1099 10.9755 18.6149C10.9755 18.12 10.5742 17.7188 10.0793 17.7188C9.58434 17.7188 9.18311 18.12 9.18311 18.6149C9.18311 19.1099 9.58434 19.5111 10.0793 19.5111Z"
        fill="#1D160F"
      />
      <path
        d="M12.7826 17.9833C13.091 17.9833 13.3409 17.7333 13.3409 17.425C13.3409 17.1166 13.091 16.8667 12.7826 16.8667C12.4743 16.8667 12.2244 17.1166 12.2244 17.425C12.2244 17.7333 12.4743 17.9833 12.7826 17.9833Z"
        fill="#1D160F"
      />
      <path
        d="M11.945 6.90586C12.2533 6.90586 12.5033 6.65591 12.5033 6.34758C12.5033 6.03926 12.2533 5.78931 11.945 5.78931C11.6367 5.78931 11.3867 6.03926 11.3867 6.34758C11.3867 6.65591 11.6367 6.90586 11.945 6.90586Z"
        fill="#1D160F"
      />
      <path
        d="M9.18328 26.607C9.4916 26.607 9.74155 26.3571 9.74155 26.0488C9.74155 25.7404 9.4916 25.4905 9.18328 25.4905C8.87495 25.4905 8.625 25.7404 8.625 26.0488C8.625 26.3571 8.87495 26.607 9.18328 26.607Z"
        fill="#1D160F"
      />
    </Fragment>
  ),
  viewBox: "0 0 32 32",
  fill: "none",
}
