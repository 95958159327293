/** @jsx jsx */
import { jsx } from "theme-ui"

export const minusIcon = {
  path: (
    <path
      d="M5 12h14"
      stroke="currentColor"
      strokeWidth="2"
      strokeLinecap="square"
      strokeLinejoin="round"
      fill="none"
    />
  ),
}
