/** @jsx jsx */
import { jsx } from "theme-ui"

export const checkIcon = {
  path: (
    <path
      d="M8.333 2.5L3.75 7.083 1.667 5"
      stroke="currentColor"
      strokeWidth="0.5"
      strokeLinecap="square"
      fill="none"
    />
  ),
  viewBox: "0 0 10 10",
}
