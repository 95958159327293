/** @jsx jsx */
import { Fragment } from "react"
import { jsx } from "theme-ui"

export const checkCircleIcon = {
  path: (
    <Fragment>
      <path d="M 4 10 L 7 13 L 14 6" stroke="currentColor" fill="none" />
      <path
        d="m 9 17 C 4 17 1 13 1 9 C 1 5 4 1 9 1 C 14 1 17 5 17 9 C 17 13 14 17 9 17 z"
        stroke="currentColor"
        fill="none"
      />
    </Fragment>
  ),
  viewBox: "0 0 18 18",
}
