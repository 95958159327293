/** @jsx jsx */
import { Fragment } from "react"
import { jsx } from "theme-ui"

export const dhlIcon = {
  path: (
    <Fragment>
      <rect x="0.5" y="0.5" width="57" height="39" rx="5.5" fill="white" />
      <g clipPath="url(#clip0_2538_12303)">
        <path d="M2 26V14H56V26H2Z" fill="#FECC00" />
        <path
          d="M19.4108 19.014C19.1748 19.3357 18.78 19.8951 18.5397 20.2218C18.4177 20.388 18.1974 20.6896 18.9278 20.6896C19.7005 20.6896 22.7731 20.6896 22.7731 20.6896C22.7731 20.6896 23.3928 19.8407 23.9124 19.1306C24.6191 18.1647 23.9736 16.1549 21.447 16.1549C19.1856 16.1549 11.4967 16.1549 11.4967 16.1549L9.77148 18.5152C9.77148 18.5152 18.7155 18.5152 19.1745 18.5152C19.6492 18.5152 19.6428 18.6972 19.4108 19.014ZM16.5859 21.2719C15.8555 21.2719 16.0758 20.9696 16.1978 20.8035C16.4381 20.4768 16.8393 19.9233 17.0753 19.6015C17.3076 19.2847 17.3137 19.1028 16.8384 19.1028C16.3797 19.1028 12.538 19.1028 12.538 19.1028L9.07308 23.8445C9.07308 23.8445 15.2616 23.8445 17.523 23.8445C20.3138 23.8445 21.8676 21.9331 22.3466 21.2722C22.3466 21.2719 17.3583 21.2719 16.5859 21.2719ZM22.0827 23.8445H27.0397L28.9189 21.2716L23.9625 21.2722C23.961 21.2719 22.0827 23.8445 22.0827 23.8445ZM34.8723 16.1549L32.971 18.7553H30.7587L32.6591 16.1549H27.7037L24.3884 20.6896H36.5128L39.8272 16.1549H34.8723ZM29.252 23.8445H34.2071L36.0872 21.2722H31.1321C31.1302 21.2719 29.252 23.8445 29.252 23.8445ZM2 22.2867V22.8331H8.90348L9.3023 22.2867H2ZM10.0443 21.2719H2V21.8177H9.64458L10.0443 21.2719ZM2 23.8445H8.16391L8.56088 23.3012H2V23.8445ZM48.7254 22.8328H56V22.2864H49.1248L48.7254 22.8328ZM47.9864 23.8445H56V23.3012H48.3831L47.9864 23.8445ZM49.8656 21.2719L49.4671 21.8183H56V21.2719H49.8656ZM43.3751 20.6896L46.6901 16.1549H41.4418C41.44 16.1549 38.1243 20.6896 38.1243 20.6896H43.3751ZM37.6994 21.2719C37.6994 21.2719 37.3371 21.7703 37.1611 22.0104C36.5389 22.8569 37.0892 23.8445 39.1202 23.8445C41.4664 23.8445 47.0785 23.8445 47.0785 23.8445L48.9586 21.2722L37.6994 21.2719Z"
          fill="#D50029"
        />
      </g>
      <rect x="0.5" y="0.5" width="57" height="39" rx="5.5" stroke="#F2F4F7" />
      <defs>
        <clipPath id="clip0_2538_12303">
          <rect
            width="54"
            height="12"
            fill="white"
            transform="translate(2 14)"
          />
        </clipPath>
      </defs>
    </Fragment>
  ),
  fill: "none",
  viewBox: "0 0 58 40",
}
