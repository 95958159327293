/** @jsx jsx */
import { Fragment } from "react"
import { jsx } from "theme-ui"

export const cleanserMoisturizerIcon = {
  path: (
    <Fragment>
      <path
        d="M44.9922 16.0053C44.9922 15.9697 44.9995 15.9347 45.0135 15.9033C45.0275 15.872 45.0477 15.8452 45.0724 15.8255C45.0971 15.8058 45.2518 15.651 45.2813 15.6476C45.3108 15.6442 45.6255 15.2237 46.3139 14.7999C48.7234 13.5283 48.7559 13.7201 50.7887 13.5283C52.8219 13.6255 55.2635 13.9522 56.8253 15.6562C56.8533 15.6388 56.8845 15.6304 56.916 15.632C56.9475 15.6335 56.9781 15.645 57.0048 15.6652C57.0314 15.6853 57.0532 15.7136 57.0679 15.747C57.0826 15.7804 57.0897 15.8179 57.0886 15.8557L57.3241 59.3384C57.332 59.5159 57.3002 59.6927 57.232 59.8505C57.1638 60.0083 57.0616 60.1414 56.9362 60.236C55.2172 61.3944 53.3914 61.7822 51.2967 61.8981C49.332 62.0069 47.3791 61.4787 45.6434 60.3689C45.5173 60.2739 45.414 60.1412 45.3436 59.9839C45.2731 59.8265 45.238 59.6499 45.2416 59.4714L44.9922 16.0053Z"
        fill="white"
      />
      <path
        d="M53.9328 40.666V49.2743C53.4162 49.6042 52.8156 49.8549 52.1659 50.0116C51.5163 50.1683 50.8308 50.2278 50.1496 50.1867C48.7026 50.2658 47.2684 49.94 46.1328 49.2743V40.666C48.6308 41.4255 51.4349 41.4255 53.9328 40.666Z"
        fill="#DBA5BA"
      />
      <path
        d="M43.6094 14.4912C43.6094 14.3343 43.7565 14.2296 43.8853 14.2994C45.5026 15.5634 47.5713 16.1881 49.6616 16.0436C51.7384 16.1286 53.7794 15.5103 55.4195 14.2994C55.5483 14.2994 55.6771 14.2994 55.6771 14.4912L55.9346 57.189C55.9419 57.354 55.9092 57.5184 55.839 57.6696C55.7689 57.8207 55.6631 57.9547 55.5299 58.0611C53.8958 59.1798 51.9151 59.7506 49.9008 59.6832C47.8991 59.781 45.9196 59.2474 44.2716 58.1657C44.138 58.0558 44.0323 57.9187 43.9622 57.7647C43.8921 57.6107 43.8596 57.4438 43.8669 57.2762L43.6094 14.4912Z"
        stroke="#1D160F"
      />
      <path
        d="M43.7012 32.3164C45.4502 33.4486 47.5478 33.9949 49.6614 33.8688C51.8332 33.9399 53.9723 33.3534 55.7689 32.1943"
        stroke="#1D160F"
      />
      <path
        d="M43.6094 14.1769C45.1964 12.8591 47.2214 12.1121 49.3305 12.0664C51.5852 12.0675 53.7767 12.7724 55.5667 14.0722"
        stroke="#1D160F"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M22.5332 8.59974C22.5332 8.56337 22.5424 8.52756 22.56 8.4955C22.5776 8.46343 22.6031 8.4361 22.6342 8.41593C22.6652 8.39575 22.86 8.23756 22.8971 8.23405C22.9343 8.23054 23.3305 7.80072 24.1971 7.36738C27.2305 6.06738 27.2714 6.26345 29.8305 6.06738C32.3901 6.16677 35.4638 6.50072 37.43 8.24288C37.4651 8.22501 37.5045 8.21645 37.5442 8.21805C37.5838 8.21965 37.6223 8.23136 37.6559 8.252C37.6894 8.27264 37.7168 8.30149 37.7353 8.33567C37.7538 8.36985 37.7628 8.40817 37.7614 8.4468L38.0579 52.9017C38.0678 53.0832 38.0278 53.264 37.9419 53.4253C37.8561 53.5866 37.7275 53.7227 37.5695 53.8194C35.4055 55.0037 33.107 55.4001 30.47 55.5187C27.9966 55.6299 25.5381 55.0899 23.353 53.9553C23.1943 53.8582 23.0643 53.7225 22.9756 53.5616C22.8869 53.4008 22.8426 53.2202 22.8472 53.0377L22.5332 8.59974Z"
        fill="white"
      />
      <path
        d="M32.9342 32.9336V43.1205C31.7046 43.9025 30.2208 44.278 28.7269 44.1853C27.1565 44.2812 25.5937 43.9143 24.2676 43.1382V32.9868C27.0641 33.898 30.1184 33.898 32.9149 32.9868L32.9342 32.9336Z"
        fill="#89ABF2"
      />
      <path
        d="M20.8008 6.44926C20.8008 6.41289 20.81 6.37708 20.8276 6.34502C20.8452 6.31295 20.8707 6.28562 20.9018 6.26545C20.9328 6.24527 20.9685 6.23288 21.0057 6.22937C21.0428 6.22587 21.0803 6.23135 21.1148 6.24534C23.3071 7.54614 25.8645 8.14075 28.4236 7.94468C30.9832 8.04407 33.5153 7.39928 35.6975 6.0924C35.7327 6.07453 35.7721 6.06597 35.8117 6.06757C35.8514 6.06917 35.8899 6.08088 35.9234 6.10152C35.957 6.12216 35.9844 6.15101 36.0029 6.18519C36.0214 6.21937 36.0304 6.25769 36.029 6.29632L36.3255 50.7512C36.3354 50.9327 36.2954 51.1135 36.2095 51.2748C36.1236 51.4361 35.9951 51.5722 35.8371 51.6689C33.673 52.8533 31.2186 53.4408 28.7376 53.3682C26.2642 53.4794 23.8057 52.9394 21.6206 51.8048C21.4619 51.7077 21.3318 51.572 21.2432 51.4112C21.1545 51.2503 21.1102 51.0697 21.1148 50.8872L20.8008 6.44926Z"
        stroke="#1D160F"
      />
      <path
        d="M20.8008 24.9639C23.099 26.1924 25.7002 26.7803 28.3189 26.6632C30.9975 26.7654 33.6564 26.1793 36.029 24.9639"
        stroke="#1D160F"
      />
      <path
        d="M20.8008 6.10099C22.9347 4.69294 25.4453 3.92491 28.0224 3.89184C30.7992 3.8766 33.5268 4.60557 35.9069 5.99903"
        stroke="#1D160F"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </Fragment>
  ),
  viewBox: "0 0 78 63",
  fill: "none",
}
