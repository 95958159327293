/** @jsx jsx */
import { jsx } from "theme-ui"

export const moonIcon = {
  path: (
    <path
      d="M20.3778 15.2399C20.2585 15.1247 20.0739 15.1078 19.9374 15.1986C19.3971 15.5521 18.8189 15.8548 18.2194 16.0981C13.1287 18.1638 7.30666 15.7029 5.24088 10.6122C3.87485 7.24588 4.4507 3.40046 6.74386 0.576859C6.84812 0.448286 6.84979 0.264528 6.74783 0.134168C6.64573 0.0041274 6.46628 -0.0343796 6.31745 0.0318185C1.03059 2.51854 -1.388 8.71065 0.810495 14.1285C3.09291 19.7531 9.52564 22.4721 15.1502 20.1897C17.3734 19.2875 19.2009 17.7276 20.4357 15.6783C20.521 15.5368 20.4972 15.3547 20.3778 15.2399ZM14.8834 19.5321C9.62145 21.6674 3.60353 19.1235 1.46836 13.8618C-0.394374 9.27138 1.28561 4.08705 5.29255 1.42168C3.60776 4.26617 3.31956 7.76404 4.58365 10.8792C6.79658 16.3325 13.0334 18.9686 18.4865 16.7557C18.6424 16.6925 18.797 16.6255 18.9498 16.5548C17.8704 17.865 16.4869 18.8815 14.8834 19.5321Z"
      fill="currentColor"
    />
  ),
  viewBox: "0 0 21 21",
}
