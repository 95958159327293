/** @jsx jsx */
import { Fragment } from "react"
import { jsx } from "theme-ui"

export const deliveryIcon = {
  path: (
    <Fragment>
      <g clipPath="url(#clip0_1341_1688)">
        <path
          d="M21.5 0.499023H2.5C1.39543 0.499023 0.5 1.39445 0.5 2.49902V21.499C0.5 22.6036 1.39543 23.499 2.5 23.499H21.5C22.6046 23.499 23.5 22.6036 23.5 21.499V2.49902C23.5 1.39445 22.6046 0.499023 21.5 0.499023Z"
          stroke="currentColor"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M5 7.49902L6.5 8.99902L10.5 4.99902"
          stroke="currentColor"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M5 17.499L6.5 18.999L10.5 14.999"
          stroke="currentColor"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M13.5 8.49902H19.5"
          stroke="currentColor"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M13.5 17.499H19.5"
          stroke="currentColor"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_1341_1688">
          <rect width="24" height="24" fill="white" />
        </clipPath>
      </defs>
    </Fragment>
  ),
  viewBox: "0 0 24 24",
  fill: "none",
}
