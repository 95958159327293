/** @jsx jsx */
import { useLocation } from "@reach/router"
import { Fragment, useEffect } from "react"

import { trackingClient } from "@trueskin-web/apis"
import {
  ContactSupport,
  ResetCache,
  VoucherUrl,
} from "@trueskin-web/components"
import { jsx } from "@trueskin-web/theme"

import Footer from "./footer"
import Header from "./header"
import TopBanner from "./top-banner"

const Layout = ({ children, title, navigationConfig: cmsNavigationConfig }) => {
  const location = useLocation()

  useEffect(() => {
    trackingClient.trackTouchPoint(location)
  }, [location])

  const navigationConfig = cmsNavigationConfig || {
    showTopNavigationBar: true,
    showTopNavigationHeader: true,
    isTopNavigationBarFloating: false,
  }

  return (
    <Fragment>
      <ResetCache />
      <VoucherUrl />
      <TopBanner />

      <Header
        title={title}
        isFloating={navigationConfig.isTopNavigationBarFloating}
        disableNav={!navigationConfig.showTopNavigationBar}
        disableMobileHeader={!navigationConfig.showTopNavigationHeader}
      />

      <main>
        <section>{children}</section>

        <section>
          <ContactSupport />
        </section>
      </main>

      <Footer />
    </Fragment>
  )
}

export default Layout
