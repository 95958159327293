/** @jsx jsx */
import { jsx } from "theme-ui"

export const emailIcon = {
  path: (
    <path
      d="M17.0937 0H2.906C2.1356.0009 1.3969.3074.8521.8523.3073 1.3971.0009 2.1358 0 2.9063V11.5878C.0009 12.3583.3073 13.097.8521 13.6418 1.3969 14.1866 2.1356 14.4931 2.906 14.4941H17.0937C17.8642 14.4932 18.6029 14.1867 19.1477 13.6418 19.6926 13.097 19.9991 12.3583 20 11.5878V2.9063C19.9991 2.1357 19.6926 1.3971 19.1477.8522 18.6029.3074 17.8642.0009 17.0937 0ZM17.0937.9068C17.5829.9085 18.0541 1.0908 18.417 1.4189L11.368 7.8687C10.9995 8.2048 10.517 8.3881 10.0184 8.3814 9.5197 8.3747 9.0422 8.1786 8.6829 7.8328L1.8394 1.2213C2.1576 1.0168 2.5278.9077 2.906.9068H17.0937ZM19.0932 11.5878C19.0926 12.1179 18.8817 12.6262 18.5069 13.001 18.1321 13.3758 17.6238 13.5867 17.0937 13.5873H2.906C2.376 13.5867 1.8678 13.3758 1.4929 13.001 1.1181 12.6261.9073 12.1179.9068 11.5878V2.9063C.9082 2.5396 1.0116 2.1805 1.2052 1.8691L8.0529 8.4845C8.5783 8.9905 9.2765 9.2776 10.0059 9.2874 10.7352 9.2973 11.441 9.0292 11.9799 8.5377L18.9465 2.1629C19.0427 2.3989 19.0925 2.6513 19.0932 2.9063L19.0932 11.5878Z"
      fill="currentColor"
    />
  ),
  viewBox: "0 0 20 14.4941",
}
