/** @jsx jsx */
import { Fragment } from "react"
import { jsx } from "theme-ui"

export const questionnaireIcon = {
  path: (
    <Fragment>
      <g clipPath="url(#clip0_93_210)">
        <path d="M16.1274 17.8217V20H1V1H14.1911" fill="none" />
        <path
          d="M16.1274 17.8217V20H1V1H14.1911"
          stroke="currentColor"
          strokeWidth="0.5"
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path d="M1.5 1L16.1274 1.00003V10.5" fill="none" />
        <path
          d="M1.5 1L16.1274 1.00003V10.5"
          stroke="currentColor"
          strokeWidth="0.5"
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M4.26754 7.41398C5.06958 7.41398 5.71977 6.7638 5.71977 5.96175C5.71977 5.15971 5.06958 4.50952 4.26754 4.50952C3.46549 4.50952 2.81531 5.15971 2.81531 5.96175C2.81531 6.7638 3.46549 7.41398 4.26754 7.41398Z"
          fill="none"
          stroke="currentColor"
          strokeWidth="0.5"
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M4.26754 11.8918C5.06958 11.8918 5.71977 11.2416 5.71977 10.4395C5.71977 9.63749 5.06958 8.9873 4.26754 8.9873C3.46549 8.9873 2.81531 9.63749 2.81531 10.4395C2.81531 11.2416 3.46549 11.8918 4.26754 11.8918Z"
          fill="none"
          stroke="currentColor"
          strokeWidth="0.5"
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M4.26754 16.3693C5.06958 16.3693 5.71977 15.7191 5.71977 14.9171C5.71977 14.115 5.06958 13.4648 4.26754 13.4648C3.46549 13.4648 2.81531 14.115 2.81531 14.9171C2.81531 15.7191 3.46549 16.3693 4.26754 16.3693Z"
          fill="none"
          stroke="currentColor"
          strokeWidth="0.5"
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M14.3121 4.75146H6.92993V7.17185H14.3121V4.75146Z"
          fill="none"
          stroke="currentColor"
          strokeWidth="0.5"
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M12.8599 11.5285H6.92993V9.10815H14.3121V10.0763"
          fill="none"
        />
        <path
          d="M12.8599 11.5285H6.92993V9.10815H14.3121V10.0763"
          stroke="currentColor"
          strokeWidth="0.5"
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path d="M12.9809 16.1274H6.92993V13.707H12.1338" fill="none" />
        <path
          d="M12.9809 16.1274H6.92993V13.707H12.1338"
          stroke="currentColor"
          strokeWidth="0.5"
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M20 13.7071C20 15.8854 18.3057 17.5797 16.1274 17.5797C13.949 17.5797 12.2548 15.8854 12.2548 13.7071C12.2548 12.86 12.4968 12.1338 12.8599 11.5287C13.2229 11.0447 13.707 10.5606 14.1911 10.3185C14.7962 10.0765 15.4013 9.83447 16.0064 9.83447C18.3057 9.83447 20 11.5287 20 13.7071Z"
          fill="none"
          stroke="currentColor"
          strokeWidth="0.5"
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path d="M14.3121 13.949L15.8854 15.7643L18.1847 12.0127" fill="none" />
        <path
          d="M14.3121 13.949L15.8854 15.7643L18.1847 12.0127"
          stroke="currentColor"
          strokeWidth="0.5"
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_93_210">
          <rect width="20.5" height="22.2" fill="none" />
        </clipPath>
      </defs>
    </Fragment>
  ),
  viewBox: "0 0 21 23",
  fill: "none",
}
