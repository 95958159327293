/** @jsx jsx */
import { Fragment } from "react"
import { jsx } from "theme-ui"

export const sunFilledIcon = {
  path: (
    <Fragment>
      <circle cx="22.1395" cy="20.7912" r="9.81821" fill="#F8B518" />
      <path
        d="M18.8574 4.28561V0.857023C18.8574 0.385593 18.4716 -0.00012207 18.0002 -0.00012207C17.5288 -0.00012207 17.1431 0.385593 17.1431 0.857023V4.28561C17.1431 4.75704 17.5288 5.14275 18.0002 5.14275C18.4716 5.14275 18.8574 4.75704 18.8574 4.28561Z"
        fill="currentColor"
      />
      <path
        d="M18.0002 30.8569C17.5288 30.8569 17.1431 31.2426 17.1431 31.7141V35.1427C17.1431 35.6141 17.5288 35.9998 18.0002 35.9998C18.4716 35.9998 18.8574 35.6141 18.8574 35.1427V31.7141C18.8574 31.2426 18.4716 30.8569 18.0002 30.8569Z"
        fill="currentColor"
      />
      <path
        d="M7.69521 8.9054C7.86664 9.07683 8.08092 9.15397 8.30378 9.15397C8.52664 9.15397 8.74093 9.06825 8.91236 8.9054C9.24664 8.57111 9.24664 8.03111 8.91236 7.69682L6.48663 5.2711C6.15235 4.93681 5.61235 4.93681 5.27806 5.2711C4.94377 5.60539 4.94377 6.14539 5.27806 6.47968L7.69521 8.9054Z"
        fill="currentColor"
      />
      <path
        d="M28.3021 27.0941C27.9678 26.7598 27.4278 26.7598 27.0935 27.0941C26.7592 27.4284 26.7592 27.9684 27.0935 28.3027L29.5192 30.7284C29.6906 30.8998 29.9049 30.977 30.1278 30.977C30.3506 30.977 30.5649 30.8913 30.7364 30.7284C31.0707 30.3941 31.0707 29.8541 30.7364 29.5198L28.3021 27.0941Z"
        fill="currentColor"
      />
      <path
        d="M4.28573 17.1429H0.857145C0.385715 17.1429 0 17.5287 0 18.0001C0 18.4715 0.385715 18.8572 0.857145 18.8572H4.28573C4.75716 18.8572 5.14287 18.4715 5.14287 18.0001C5.14287 17.5287 4.75716 17.1429 4.28573 17.1429Z"
        fill="currentColor"
      />
      <path
        d="M35.1431 17.1429H31.7146C31.2431 17.1429 30.8574 17.5287 30.8574 18.0001C30.8574 18.4715 31.2431 18.8572 31.7146 18.8572H35.1431C35.6146 18.8572 36.0003 18.4715 36.0003 18.0001C36.0003 17.5287 35.6146 17.1429 35.1431 17.1429Z"
        fill="currentColor"
      />
      <path
        d="M7.69743 27.0941L5.27171 29.5198C4.93742 29.8541 4.93742 30.3941 5.27171 30.7284C5.44314 30.8998 5.65743 30.977 5.88028 30.977C6.10314 30.977 6.31743 30.8913 6.48886 30.7284L8.91458 28.3027C9.24887 27.9684 9.24887 27.4284 8.91458 27.0941C8.58029 26.7598 8.03172 26.7598 7.69743 27.0941Z"
        fill="currentColor"
      />
      <path
        d="M28.3021 8.9054L30.7278 6.47968C31.0621 6.14539 31.0621 5.60539 30.7278 5.2711C30.3935 4.93681 29.8535 4.93681 29.5192 5.2711L27.0935 7.69682C26.7592 8.03111 26.7592 8.57111 27.0935 8.9054C27.2649 9.07683 27.4792 9.15397 27.7021 9.15397C27.9249 9.15397 28.1392 9.07683 28.3021 8.9054Z"
        fill="currentColor"
      />
      <path
        d="M18.0001 7.71423C12.3258 7.71423 7.71436 12.3257 7.71436 18C7.71436 23.6743 12.3258 28.2857 18.0001 28.2857C23.6744 28.2857 28.2858 23.6743 28.2858 18C28.2858 12.3257 23.6744 7.71423 18.0001 7.71423ZM18.0001 26.5714C13.2772 26.5714 9.42865 22.7229 9.42865 18C9.42865 13.2771 13.2772 9.42852 18.0001 9.42852C22.723 9.42852 26.5716 13.2771 26.5716 18C26.5716 22.7229 22.723 26.5714 18.0001 26.5714Z"
        fill="currentColor"
      />
    </Fragment>
  ),
  viewBox: "0 0 36 36",
  fill: "none",
}
