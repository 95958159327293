/** @jsx jsx */
import { Fragment } from "react"
import { jsx } from "theme-ui"

export const shippingIcon = {
  path: (
    <Fragment>
      <path
        d="M12.125 14.977L23.2499 8.70464"
        stroke="currentColor"
        strokeMiterlimit="10"
      />
      <path
        d="M1 8.70464L12.1249 14.977V21.2001"
        stroke="currentColor"
        strokeMiterlimit="10"
      />
      <path
        d="M1 8.70457L12.1249 2L23.2498 8.70457V14.9399L12.1125 21.2L1 14.9399V8.70457Z"
        stroke="currentColor"
        strokeMiterlimit="10"
      />
      <path
        d="M5.11133 6.22279L16.0757 12.7421V17.2983L19.9157 14.9153V10.4826L8.95133 3.96324"
        stroke="currentColor"
        strokeMiterlimit="10"
      />
    </Fragment>
  ),
  fill: "none",
  viewBox: "0 0 24 24",
}
