/** @jsx jsx */
import { Fragment } from "react"
import { jsx } from "theme-ui"

export const chatIcon = {
  path: (
    <Fragment>
      <g clipPath="url(#clip0_2481_9741)">
        <path
          d="M26.3108 21.5358C27.6829 19.9922 28.5404 17.934 28.5404 15.8759C28.5404 10.902 24.2526 6.78568 18.9357 6.78568C13.6188 6.78568 9.33093 10.7305 9.33093 15.8759C9.33093 21.0213 13.6188 24.9661 18.9357 24.9661C20.4793 24.9661 21.8514 24.6231 23.052 24.1085L27.1683 25.6521L26.3108 21.5358Z"
          stroke="currentColor"
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M10.5316 20.1638C10.3601 20.1638 10.1886 20.1638 10.0171 20.1638C8.64497 20.1638 7.44437 19.8207 6.24378 19.3062L2.642 20.6783L3.32805 17.248C2.29897 15.8759 1.44141 13.9893 1.44141 12.1026C1.44141 7.64328 5.2147 4.0415 9.84556 4.0415C12.4183 4.0415 14.8194 5.2421 16.3631 6.95723"
          stroke="currentColor"
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M18.9357 12.7886V19.1346"
          stroke="currentColor"
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M22.023 16.0474H15.8485"
          stroke="currentColor"
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M18.9357 21.7073C22.0616 21.7073 24.5956 19.1733 24.5956 16.0474C24.5956 12.9215 22.0616 10.3875 18.9357 10.3875C15.8098 10.3875 13.2758 12.9215 13.2758 16.0474C13.2758 19.1733 15.8098 21.7073 18.9357 21.7073Z"
          stroke="currentColor"
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_2481_9741">
          <rect width="29.5496" height="32" fill="white" />
        </clipPath>
      </defs>
    </Fragment>
  ),
  fill: "none",
  viewBox: "0 0 30 32",
}
