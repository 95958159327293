/** @jsx jsx */
import { jsx } from "@trueskin-web/theme"
import i18next from "@trueskin-web/translations"

import { useFormControl } from "./form-control"

const ToggleSwitch = ({
  value,
  onChange,
  isRequired,
  isDisabled,
  isInvalid,
  isReadOnly,
  onButtonText = i18next.t("Generic.on"),
  offButtonText = i18next.t("Generic.off"),
  ...props
}) => {
  const formControl = useFormControl({
    isRequired,
    isDisabled,
    isInvalid,
    isReadOnly,
  })

  return (
    <label
      sx={{
        display: "inline-block",
        position: "relative",
        minWidth: 56,
        width: "fit-content",
        height: 24,
        borderRadius: 24,
        overflow: "hidden",
        cursor: formControl.isDisabled ? "not-allowed" : "pointer",
        opacity: formControl.isDisabled ? 0.4 : undefined,
        "input ~ div:first-of-type": {
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          span: {
            ":first-of-type": {
              opacity: 0,
            },
          },
        },
        "input:checked ~ div": {
          ":first-of-type": {
            span: {
              opacity: 0,
              ":first-of-type": {
                opacity: 1,
              },
            },
            ":before": {
              left: 0,
              right: 0,
            },
            ":after": {
              left: "100%",
              right: "-100%",
            },
          },
          ":last-of-type": {
            right: 0,
          },
        },
      }}
      {...props}
    >
      <input
        checked={value}
        onChange={(event) => onChange(event.target.checked)}
        type="checkbox"
        disabled={formControl.isDisabled}
        aria-invalid={formControl.isInvalid}
        hidden
      />
      <div
        sx={{
          px: 2,
          color: "background",
          fontSize: 8,
          lineHeight: "24px",
          fontFamily: "monospace",
          fontWeight: "bold",
          letterSpacing: "0.1em",
          textTransform: "uppercase",
          boxSizing: "border-box",
          "& > *": {
            minWidth: 22,
            textAlign: "center",
          },
          ":before, :after": {
            position: "absolute",
            top: 0,
            bottom: 0,
            transition: "all 0.2s ease-in 0s",
            zIndex: -1,
          },
          ":before": {
            left: "-100%",
            right: "100%",
            content: `""`,
            backgroundColor: "primary",
          },
          ":after": {
            left: 0,
            right: 0,
            content: `""`,
            backgroundColor: "textLight",
          },
        }}
      >
        <span>{onButtonText}</span>
        <span>{offButtonText}</span>
      </div>

      <div
        sx={{
          position: "absolute",
          top: 0,
          bottom: 0,
          right: "calc(100% - 22px - 2px)",
          width: 22,
          borderRadius: "50%",
          margin: "1px",
          backgroundColor: "background",
          transition: "all 0.2s ease-in 0s",
        }}
      />
    </label>
  )
}

export default ToggleSwitch
