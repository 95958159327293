/** @jsx jsx */
import { Fragment } from "react"
import { jsx } from "theme-ui"

export const bulbIcon = {
  path: (
    <Fragment>
      <path
        d="M12.75 21.9824H12.25V22.9824H12.75V21.9824ZM21.25 22.9824H21.75V21.9824H21.25V22.9824ZM12.75 22.9824H21.25V21.9824H12.75V22.9824Z"
        fill="currentColor"
      />
      <path
        d="M14.1665 26.2305H13.6665V27.2305H14.1665V26.2305ZM19.8332 27.2305H20.3332V26.2305H19.8332V27.2305ZM14.1665 27.2305H19.8332V26.2305H14.1665V27.2305Z"
        fill="currentColor"
      />
      <ellipse
        cx="20.5417"
        cy="9.03215"
        rx="7.79167"
        ry="7.78704"
        fill="#F8B518"
      />
      <path
        d="M20.8857 16.7286L20.7954 17.2204L21.7789 17.4011L21.8693 16.9094L20.8857 16.7286ZM23.375 13.2794L23.0365 12.9115C23.0314 12.9161 23.0264 12.9209 23.0216 12.9258L23.375 13.2794ZM25.5 8.32405H24.9999L25.0001 8.33253L25.5 8.32405ZM17 -0.170898V-0.670898V-0.170898ZM8.5 8.32405H8H8.5ZM10.625 13.2794L10.2716 13.6331C10.2768 13.6384 10.2822 13.6435 10.2877 13.6485L10.625 13.2794ZM12.1322 16.9169L12.2301 17.4072L13.2107 17.2114L13.1128 16.7211L12.1322 16.9169ZM21.8693 16.9094C22.1012 15.6471 22.7027 14.6582 23.7284 13.6331L23.0216 12.9258C21.894 14.0527 21.1638 15.2159 20.8857 16.7286L21.8693 16.9094ZM23.7135 13.6474C24.4495 12.9703 25.0338 12.1451 25.4278 11.2262L24.5088 10.832C24.1704 11.6212 23.6686 12.3299 23.0365 12.9115L23.7135 13.6474ZM25.4278 11.2262C25.8219 10.3072 26.0169 9.31525 25.9999 8.31557L25.0001 8.33253C25.0146 9.19101 24.8472 10.0428 24.5088 10.832L25.4278 11.2262ZM26 8.32405C26 5.93835 25.0517 3.65041 23.3639 1.96356L22.657 2.67087C24.1572 4.17024 25 6.20375 25 8.32405H26ZM23.3639 1.96356C21.676 0.276709 19.3869 -0.670898 17 -0.670898V0.329102C19.1218 0.329102 21.1567 1.1715 22.657 2.67087L23.3639 1.96356ZM17 -0.670898C14.6131 -0.670898 12.324 0.276709 10.6361 1.96356L11.343 2.67087C12.8433 1.1715 14.8782 0.329102 17 0.329102V-0.670898ZM10.6361 1.96356C8.94828 3.65041 8 5.93835 8 8.32405H9C9 6.20375 9.84278 4.17024 11.343 2.67087L10.6361 1.96356ZM8 8.32405C8 9.84068 8.3582 11.7209 10.2716 13.6331L10.9784 12.9258C9.29347 11.2418 9 9.63907 9 8.32405H8ZM10.2877 13.6485C11.2349 14.5141 11.881 15.6591 12.1322 16.9169L13.1128 16.7211C12.82 15.2545 12.0666 13.9195 10.9623 12.9103L10.2877 13.6485Z"
        fill="currentColor"
      />
    </Fragment>
  ),
  viewBox: "0 0 34 34",
  fill: "none",
}
