/** @jsx jsx */
import { Fragment } from "react"
import { jsx } from "theme-ui"

export const pimpleIcon = {
  path: (
    <Fragment>
      <path
        d="M11.9999 21.81C17.4179 21.81 21.8099 17.4179 21.8099 12C21.8099 6.58209 17.4179 2.19 11.9999 2.19C6.58203 2.19 2.18994 6.58209 2.18994 12C2.18994 17.4179 6.58203 21.81 11.9999 21.81Z"
        stroke="currentColor"
        strokeWidth="1.33333"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M21.6139 13.7657C21.6139 13.7657 17.1994 14.06 16.1203 10.5284C15.0412 6.99683 13.6678 6.60443 12.0982 6.60443C10.5286 6.60443 9.15522 6.99683 8.07612 10.5284C6.99702 14.06 2.58252 13.7657 2.58252 13.7657"
        stroke="currentColor"
        strokeWidth="1.33333"
        strokeMiterlimit="10"
        strokeLinecap="round"
      />
      <path
        d="M10.9209 8.07605C10.9209 8.07605 10.038 8.56655 9.8418 9.74375"
        stroke="currentColor"
        strokeWidth="1.33333"
        strokeMiterlimit="10"
        strokeLinecap="round"
      />
      <path
        d="M9.54718 10.7247C9.54718 10.7247 9.25288 12.8829 6.99658 14.0601"
        stroke="currentColor"
        strokeMiterlimit="10"
        strokeLinecap="round"
      />
    </Fragment>
  ),
  viewBox: "0 0 24 24",
  fill: "none",
}
