/** @jsx jsx */
import { jsx } from "@trueskin-web/theme"

const Spacer = () => (
  <div
    sx={{
      flex: 1,
    }}
  />
)

export default Spacer
