/** @jsx jsx */
import { Children, cloneElement } from "react"

import { jsx } from "@trueskin-web/theme"

const AspectRatio = ({ ratio = 4 / 3, children, ...props }) => {
  const child = Children.only(children)

  return (
    <div
      sx={{
        position: "relative",
        "&:before": {
          height: 0,
          content: `""`,
          display: "block",
          pb: `${(1 / ratio) * 100}%`,
        },
      }}
      {...props}
    >
      {cloneElement(child, {
        style: {
          position: "absolute",
          width: "100%",
          height: "100%",
          top: 0,
          left: 0,
        },
      })}
    </div>
  )
}

export default AspectRatio
