/** @jsx jsx */
import { forwardRef } from "react"

import { jsx } from "@trueskin-web/theme"

const CDN_URL = process.env.GATSBY_CDN_FRONT_URL

const prepareUrl = (originalUrl) => {
  if (typeof originalUrl !== "string") {
    return originalUrl
  }

  if (originalUrl.startsWith("https://s3.")) {
    return originalUrl.replace(/https:\/\/([^/]*\/){2}/, CDN_URL)
  }

  if (originalUrl.includes(".s3.")) {
    return originalUrl.replace(/https:\/\/[^/]*\//, CDN_URL)
  }

  return originalUrl
}

const CdnImage = forwardRef(
  (
    {
      src,
      loading = "lazy",
      alt = "",
      className,
      objectFit = "cover",
      ...props
    },
    ref
  ) => (
    <img
      ref={ref}
      src={prepareUrl(src)}
      loading={loading}
      alt={alt}
      className={className}
      sx={{
        objectFit,
      }}
      {...props}
    />
  )
)

export default CdnImage
