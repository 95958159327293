/** @jsx jsx */
import { Fragment } from "react"
import { jsx } from "theme-ui"

export const messageFilledIcon = {
  path: (
    <Fragment>
      <path d="M7 17h14V3H3v18l4-4z" fill="currentColor" />
      <path
        d="M21 17v.6c.3 0 .6-.3.6-.6H21zM7 17v-.6c-.2 0-.3 0-.4.2l.4.4zm-4 4h-.6v1.5l1-1L3 21zM3 3v-.6c-.3 0-.6.3-.6.6H3zm18 0h.6c0-.3-.3-.6-.6-.6V3zm0 13.4H7v1.2h14v-1.2zm-14.4.2l-4 4 .8.8 4-4-.8-.8zm-3 4.4V3H2.4v18h1.2zM3 3.6h18V2.4H3v1.2zM20.4 3v14h1.2V3h-1.2z"
        fill="currentColor"
      />
      <circle cx="8" cy="10" r="1" fill="#fff" />
      <circle cx="12" cy="10" r="1" fill="#fff" />
      <circle cx="16" cy="10" r="1" fill="#fff" />
    </Fragment>
  ),
}
