/** @jsx jsx */
import { Fragment } from "react"
import { jsx } from "theme-ui"

export const sentimentVeryHappyIcon = {
  path: (
    <Fragment>
      <circle
        cx="12.5"
        cy="12"
        r="11.1"
        stroke="currentColor"
        strokeWidth="1.8"
      />
      <path
        d="M17.2998 15C16.4998 16.2 15.3798 18 12.4998 18C9.6198 18 8.4998 16.2 7.6998 15"
        stroke="currentColor"
        strokeWidth="1.8"
      />
      <path
        d="M11.8 10.8004C11.8 9.47491 10.7255 8.40039 9.4 8.40039C8.07452 8.40039 7 9.47491 7 10.8004"
        stroke="currentColor"
        strokeWidth="1.8"
      />
      <path
        d="M14.0008 10.8004C14.0008 9.47491 15.0753 8.40039 16.4008 8.40039C17.7263 8.40039 18.8008 9.47491 18.8008 10.8004"
        stroke="currentColor"
        strokeWidth="1.8"
      />
    </Fragment>
  ),
  viewBox: "0 0 25 24",
  fill: "none",
}
