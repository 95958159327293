/**
 * Seo component that queries for data with
 *  Gatsby's useStaticQuery React hook
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

/** @jsx jsx */
import { graphql, useStaticQuery } from "gatsby"

import { Seo as SeoContainer } from "@trueskin-web/components"
import { jsx } from "@trueskin-web/theme"

function Seo(props) {
  const {
    site: { siteMetadata },
  } = useStaticQuery(
    graphql`
      {
        site {
          siteMetadata {
            title
            description
            author
          }
        }
      }
    `
  )

  return <SeoContainer siteMetadata={siteMetadata} {...props} />
}

export default Seo
