/** @jsx jsx */
import { jsx } from "theme-ui"

export const arrowLongRightIcon = {
  path: (
    <path
      d="M 15.0391 1 L 20.7931 7 L 15.0391 13 M 20.8 7 H 3"
      stroke="currentColor"
    />
  ),
  viewBox: "1 0 22 14",
  fill: "none",
}
