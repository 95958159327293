/** @jsx jsx */
import { Fragment } from "react"
import { jsx } from "theme-ui"

export const cancelIcon = {
  path: (
    <Fragment>
      <path
        d="M11.86 2H20.14L26 7.86V16.14L20.14 22H11.86L6 16.14V7.86L11.86 2Z"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M19 9L13 15"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M13 9L19 15"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </Fragment>
  ),
  viewBox: "0 0 32 24",
  fill: "none",
}
