/** @jsx jsx */
import { Fragment, useEffect } from "react"
import { Helmet } from "react-helmet"

import { jsx } from "@trueskin-web/theme"

import AspectRatio from "./aspect-ratio"

// https://stackoverflow.com/a/9102270/2633602
const extractYouTubeId = (url) => {
  const regExp = /^.*(youtu\.be\/|v\/|u\/\w\/|embed\/|watch\?v=|&v=)([^#&?]*).*/
  const match = url.match(regExp)
  if (match && match[2].length === 11) {
    return match[2]
  } else {
    return null
  }
}

const YoutubeEmbed = ({ url, autoplay }) => {
  const videoId = extractYouTubeId(url)

  const onReadyAutoplay = () => {
    if (autoplay) {
      window.player = new window.YT.Player(videoId, {
        events: {
          onReady: (event) => {
            event.target.playVideo()
          },
        },
      })
    }
  }

  useEffect(() => {
    window.onYouTubeIframeAPIReady = onReadyAutoplay

    if (window.YT) {
      onReadyAutoplay()
    }
  })

  return (
    <Fragment>
      {autoplay && (
        <Helmet>
          <script src={"https://www.youtube.com/iframe_api"} defer />
        </Helmet>
      )}
      <AspectRatio ratio={16 / 9}>
        <iframe
          id={videoId}
          title="youtube"
          src={`https://www.youtube.com/embed/${videoId}?enablejsapi=1&autoplay=${
            autoplay ? 1 : 0
          }`}
          frameBorder="0"
          allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
          allowFullScreen
        />
      </AspectRatio>
    </Fragment>
  )
}

export default YoutubeEmbed
