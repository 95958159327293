/** @jsx jsx */
import { jsx } from "@trueskin-web/theme"

const legitScriptStatusLink =
  "https://www.legitscript.com/websites/?checker_keywords=https%3A%2F%2Fwww.formelskin.de%2F"

const LegitScriptBadge = ({ logo }) => (
  <a
    sx={{ ml: 4 }}
    href={legitScriptStatusLink}
    target="_blank"
    title="Verify LegitScript Approval"
  >
    {logo}
  </a>
)

export default LegitScriptBadge
