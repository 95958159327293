/** @jsx jsx */
import { Fragment } from "react"
import { jsx } from "theme-ui"

export const questionIcon = {
  path: (
    <Fragment>
      <path
        d="M12 22C17.5 22 22 17.5 22 12C22 6.5 17.5 2 12 2C6.5 2 2 6.5 2 12C2 17.5 6.5 22 12 22ZM12 2.625C17.1562 2.625 21.375 6.84375 21.375 12C21.375 17.1562 17.1562 21.375 12 21.375C6.84375 21.375 2.625 17.1562 2.625 12C2.625 6.84375 6.84375 2.625 12 2.625Z"
        fill="currentColor"
      />
      <path
        d="M12.1562 7C13.9688 7 15.4375 8.46875 15.4375 10.2812C15.4375 12.0625 13.875 13.5625 12 13.5625H11.6875V16.6875H12.3125V14.1875C14.375 14.0312 16.0625 12.3125 16.0625 10.2812C16.0625 8.125 14.3125 6.375 12.1562 6.375C10 6.375 8.25 8.125 8.25 10.2812H8.875C8.875 8.46875 10.3438 7 12.1562 7Z"
        fill="currentColor"
      />
      <path
        d="M12.3125 17.3125H11.6875V18.25H12.3125V17.3125Z"
        fill="currentColor"
      />
    </Fragment>
  ),
  viewBox: "0 0 24 24",
  fill: "none",
}
