/** @jsx jsx */
import { Fragment } from "react"
import { jsx } from "theme-ui"

export const formulaIcon = {
  path: (
    <Fragment>
      <path
        d="M7.78467 7.46449C7.78467 7.43696 7.7937 7.40985 7.81096 7.38557C7.82822 7.36129 7.85318 7.3406 7.88362 7.32532C7.91406 7.31005 8.1049 7.19027 8.14131 7.18761C8.17773 7.18496 8.56597 6.85951 9.41528 6.53142C12.3879 5.54712 12.428 5.69557 14.9358 5.54712C17.4441 5.62237 20.4563 5.87522 22.3831 7.1943C22.4176 7.18077 22.4561 7.17429 22.495 7.1755C22.5338 7.17671 22.5716 7.18557 22.6045 7.2012C22.6373 7.21683 22.6642 7.23867 22.6823 7.26456C22.7005 7.29044 22.7093 7.31945 22.7079 7.3487L22.9985 41.0078C23.0082 41.1452 22.9689 41.282 22.8848 41.4042C22.8006 41.5264 22.6746 41.6294 22.5198 41.7026C20.3991 42.5993 18.1467 42.8994 15.5625 42.9892C13.1386 43.0734 10.7294 42.6645 8.58809 41.8055C8.43255 41.7319 8.30508 41.6292 8.21818 41.5074C8.13127 41.3856 8.08791 41.2489 8.09236 41.1107L7.78467 7.46449Z"
        fill="white"
      />
      <path
        d="M18 24V32.1399C16.6599 32.7879 15.1377 33.0799 13.6154 32.9812C12.0205 33.0627 10.4293 32.7727 9 32.1399V24.0455C10.4671 24.4156 11.9877 24.5918 13.5128 24.5684C15.0331 24.5908 16.5476 24.3989 18 24Z"
        fill="#008878"
      />
      <path
        d="M6 6.51493C6.00417 6.47627 6.01692 6.43913 6.03726 6.40641C6.05759 6.37368 6.08496 6.34624 6.11723 6.32623C6.1495 6.30622 6.1858 6.29418 6.22331 6.29104C6.26082 6.2879 6.29853 6.29375 6.33349 6.30813C8.41776 7.69695 10.8876 8.33509 13.3591 8.12333C15.9447 8.23547 18.5048 7.55604 20.7182 6.17027C20.7525 6.14974 20.7916 6.13908 20.8313 6.13941C20.871 6.13974 20.9098 6.15105 20.9439 6.17215C20.9779 6.19325 21.0058 6.22338 21.0248 6.25942C21.0438 6.29545 21.053 6.33607 21.0517 6.37706L21.3185 37.3504C21.3206 37.5405 21.277 37.7282 21.1916 37.8967C21.1063 38.0651 20.9818 38.209 20.8293 38.3154C18.6582 39.5644 16.198 40.1762 13.7148 40.0847C11.2326 40.2062 8.76529 39.6263 6.57806 38.4074C6.41678 38.3031 6.28472 38.1571 6.195 37.9837C6.10529 37.8104 6.06106 37.6158 6.0667 37.4193L6 6.51493Z"
        stroke="currentColor"
      />
      <path
        d="M6 16.1885C8.14423 17.5109 10.6212 18.1429 13.1145 18.0037C15.8027 18.1081 18.4705 17.4822 20.8516 16.1885"
        stroke="currentColor"
      />
      <path
        d="M6 6.12439C8.09232 4.65071 10.5604 3.85111 13.0923 3.82666C15.8211 3.83279 18.4937 4.62877 20.8071 6.12439"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </Fragment>
  ),
  viewBox: "0 0 26 44",
  fill: "none",
}
