/** @jsx jsx */
import { Fragment } from "react"
import { jsx } from "theme-ui"

export const americanExpressIcon = {
  path: (
    <Fragment>
      <g clipPath="url(#clip0_2538_11806)">
        <rect width="58" height="40" rx="6" fill="white" />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M53.9998 21.4236H51.347C50.5464 21.4236 49.9648 21.6147 49.5529 21.912V21.4236H45.629C45.0015 21.4236 44.2649 21.5787 43.9165 21.912V21.4236H36.9094V21.912C36.3517 21.5109 35.4107 21.4236 34.9764 21.4236H30.3544V21.912C29.9133 21.486 28.9321 21.4236 28.3341 21.4236H23.1613L21.9776 22.7008L20.869 21.4236H13.1419V29.7684H20.7236L21.9433 28.471L23.0922 29.7684L27.7656 29.7725V27.8095H28.2251C28.8451 27.819 29.5765 27.7942 30.2217 27.5161V29.7682H34.0764V27.5933H34.2623C34.4996 27.5933 34.523 27.603 34.523 27.8394V29.768H46.2328C46.9762 29.768 47.7533 29.5783 48.1836 29.234V29.768H51.8979C52.6709 29.768 53.4257 29.66 54 29.3833C54 -16.2735 53.9998 40.5399 53.9998 21.4236Z"
          fill="#006FCF"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M5.44937 19.4757L6.00162 18.1477H7.23797L7.78877 19.4757H12.6077V18.4604L13.0379 19.48H15.5395L15.9697 18.4452V19.4757H27.9458L27.9402 17.2957H28.1719C28.3341 17.3013 28.3815 17.3163 28.3815 17.5834V19.4757H34.5756V18.9682C35.0752 19.2351 35.8523 19.4757 36.8748 19.4757H39.4807L40.0383 18.1477H41.2747L41.8201 19.4757H46.8416V18.2142L47.6021 19.4757H51.6261V11.1367H47.6437V12.1216L47.086 11.1367H42.9996V12.1216L42.4875 11.1367H36.9678C36.0438 11.1367 35.2317 11.2653 34.5756 11.6236V11.1367H30.7665V11.6236C30.349 11.2543 29.7801 11.1367 29.1476 11.1367H15.2315L14.2978 13.2905L13.3389 11.1367H8.95566V12.1216L8.47414 11.1367H4.73596L3 15.1015V19.4757H5.44937Z"
          fill="#006FCF"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M28.2885 26.579C29.5415 26.579 30.7847 26.236 30.7847 24.5747C30.7847 22.9184 29.5066 22.6044 28.369 22.6044H23.7886L21.9653 24.5648L20.2016 22.6044H14.4363V28.5849H20.1143L21.9486 26.6051L23.7137 28.5849H26.4999V26.579H28.2885ZM26.4999 25.3644H28.3524C28.9032 25.3644 29.2458 25.0919 29.2458 24.5747C29.2458 24.0517 28.8865 23.8414 28.369 23.8414H26.4999V25.3644ZM19.3886 27.3386H15.8642V26.1487H19.0112V24.9285H15.8642V23.8413H19.458L21.0259 25.5841L19.3886 27.3386ZM25.0722 28.0411L22.8713 25.605L25.0722 23.2463V28.0411Z"
          fill="white"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M36.9151 26.3078C36.7929 26.1336 36.532 25.9231 36.2129 25.8057C36.5903 25.6564 37.2288 25.1693 37.229 24.2147C37.229 23.5325 36.9444 23.1592 36.492 22.8867C36.0216 22.6364 35.493 22.6044 34.7673 22.6044H31.4578V28.5849H32.8982V26.4006H34.4301C34.9531 26.4006 35.2723 26.4519 35.4818 26.6661C35.7216 26.9164 35.7174 27.3731 35.7142 27.7242C35.7139 27.7631 35.7135 27.8008 35.7135 27.8367V28.5849H37.1526V27.3994C37.1468 26.8722 37.1175 26.5997 36.9151 26.3078ZM35.3472 25.0615C35.1555 25.1749 34.9183 25.1846 34.6394 25.1846H32.898V23.8414H34.6631C34.9183 23.8414 35.1737 23.8469 35.3472 23.9495C35.533 24.0463 35.6441 24.2315 35.6441 24.4874C35.6441 24.7433 35.533 24.9495 35.3472 25.0615Z"
          fill="white"
        />
        <path
          d="M48.282 25.4308C48.5611 25.7188 48.7107 26.0823 48.7107 26.6978C48.7107 27.9844 47.9045 28.5849 46.4588 28.5849H43.6669V27.3025H46.4476C46.7195 27.3025 46.9123 27.2666 47.0332 27.1545C47.1317 27.062 47.2025 26.9276 47.2025 26.7644C47.2025 26.5902 47.126 26.4519 47.0276 26.3689C46.9179 26.2776 46.7667 26.2362 46.5171 26.2362C46.4293 26.2332 46.3401 26.2308 46.25 26.2283C44.9639 26.1935 43.4977 26.1537 43.4977 24.3851C43.4977 23.5177 44.0444 22.6046 45.5472 22.6046H48.4209V23.8775H45.7914C45.5307 23.8775 45.3612 23.8872 45.2171 23.9855C45.0601 24.0823 45.0018 24.2261 45.0018 24.4158C45.0018 24.6414 45.1351 24.7949 45.3155 24.8612C45.4667 24.9137 45.6291 24.9291 45.8733 24.9291L46.645 24.9498C47.4232 24.9687 47.9573 25.1029 48.282 25.4308Z"
          fill="white"
        />
        <path
          d="M54 23.8771H51.3874C51.1266 23.8771 50.9533 23.8868 50.8073 23.9851C50.6561 24.082 50.5979 24.2257 50.5979 24.4154C50.5979 24.641 50.7256 24.7945 50.9113 24.8609C51.0626 24.9133 51.225 24.9287 51.4638 24.9287L52.2407 24.9494C53.0246 24.9687 53.5479 25.1031 53.867 25.4307C53.916 25.4693 53.9485 25.5118 53.9815 25.5549L53.9905 25.5665L53.9962 25.5739L54 25.5788V27.8199C53.6518 28.3276 52.9733 28.585 52.0547 28.585H49.2865V27.3025H52.0435C52.317 27.3025 52.5084 27.2667 52.6236 27.1545C52.7235 27.062 52.7931 26.9276 52.7931 26.7644C52.7931 26.5902 52.7235 26.4519 52.618 26.3689C52.514 26.2777 52.3626 26.2362 52.113 26.2362C52.0249 26.2332 51.9355 26.2308 51.8451 26.2283C50.5545 26.1935 49.0878 26.1539 49.0878 24.3852C49.0878 23.5177 49.6403 22.6046 51.1445 22.6046H53.9998L54 23.8771Z"
          fill="white"
        />
        <path
          d="M38.0238 22.6044H42.808V23.8412H39.4513V24.9285H42.7261V26.1487H39.4513V27.3386L42.808 27.344V28.5849H38.0238V22.6044Z"
          fill="white"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M7.59724 15.7125L6.61612 13.3289L5.6406 15.7125H7.59724ZM29.2113 14.7634C29.0143 14.8826 28.7813 14.8866 28.5022 14.8866H26.7608V13.5586H28.5259C28.7757 13.5586 29.0364 13.5697 29.2057 13.6664C29.3916 13.7535 29.5067 13.9389 29.5067 14.195C29.5067 14.4563 29.3972 14.6666 29.2113 14.7634ZM41.6342 15.7125L40.6422 13.3289L39.6556 15.7125H41.6342ZM18.4771 18.2925H17.0076L17.0022 13.6097L14.9236 18.2925H13.665L11.5811 13.6056V18.2925H8.66554L8.11475 16.9589H5.13013L4.57373 18.2925H3.01685L5.58379 12.3134H7.71354L10.1515 17.9744V12.3134H12.4911L14.3671 16.3695L16.0904 12.3134H18.477V18.2925H18.4771ZM24.3341 18.2925H19.5455V12.3134H24.3341V13.5585H20.979V14.6362H24.2536V15.8618H20.979V17.0559H24.3341V18.2925ZM31.0858 13.9237C31.0858 14.877 30.4476 15.3695 30.0757 15.5174C30.3894 15.6364 30.6573 15.8467 30.7848 16.0209C30.9872 16.3183 31.0221 16.5839 31.0221 17.1179V18.2925H29.5763L29.5709 17.5385C29.5709 17.1787 29.6054 16.6613 29.3446 16.3736C29.1351 16.1634 28.8159 16.1177 28.2998 16.1177H26.761V18.2925H25.3277V12.3134H28.6247C29.3572 12.3134 29.897 12.3327 30.3604 12.5998C30.8139 12.8669 31.0858 13.2568 31.0858 13.9237ZM33.3798 18.2925H31.9171V12.3134H33.3798V18.2925ZM50.3483 18.2925H48.317L45.5999 13.8049V18.2925H42.6806L42.1227 16.9589H39.145L38.6038 18.2925H36.9264C36.2296 18.2925 35.3475 18.1388 34.8479 17.6312C34.3441 17.1235 34.082 16.4359 34.082 15.3486C34.082 14.4618 34.2386 13.6512 34.8547 13.0106C35.3182 12.5334 36.0439 12.3134 37.0317 12.3134H38.4194V13.5946H37.0608C36.5377 13.5946 36.2423 13.6721 35.9578 13.9488C35.7134 14.2005 35.5457 14.6764 35.5457 15.3031C35.5457 15.9437 35.6734 16.4056 35.9399 16.7073C36.1605 16.9439 36.5616 17.0157 36.9389 17.0157H37.5827L39.603 12.3136H41.7508L44.1778 17.9689V12.3136H46.3603L48.8801 16.4777V12.3136H50.3483V18.2925Z"
          fill="white"
        />
      </g>
      <rect x="0.5" y="0.5" width="57" height="39" rx="5.5" stroke="#F2F4F7" />
      <defs>
        <clipPath id="clip0_2538_11806">
          <rect width="58" height="40" rx="6" fill="white" />
        </clipPath>
      </defs>
    </Fragment>
  ),
  fill: "none",
  viewBox: "0 0 58 40",
}
