/** @jsx jsx */
import { jsx } from "theme-ui"

export const arrowShortRightIcon = {
  path: (
    <path
      d="M 15.0391 1 L 20.7931 7 L 15.0391 13 M 20.8 7 H 9"
      stroke="currentColor"
    />
  ),
  viewBox: "4 0 21 14",
  fill: "none",
}
