/** @jsx jsx */
import { useId } from "@reach/auto-id"
import { forwardRef } from "react"

import { jsx } from "@trueskin-web/theme"

import FormControl from "./form-control"
import FormErrorMessage from "./form-error-message"
import FormLabel from "./form-label"
import Input from "./input"

const InputNumberControl = forwardRef(
  ({ input, meta, label, required, className, ...props }, ref) => {
    const id = useId()

    return (
      <FormControl
        isInvalid={
          (meta.error || (meta.submitError && !meta.dirtySinceLastSubmit)) &&
          meta.touched
        }
        isRequired={required}
        className={className}
      >
        {label && <FormLabel htmlFor={id}>{label}</FormLabel>}
        <Input
          id={id}
          onKeyPress={(e) => {
            const re = /^[0-9\b]+$/
            if (!re.test(e.key)) {
              e.preventDefault()
            }
          }}
          ref={ref}
          {...input}
          {...props}
        />
        <FormErrorMessage>{meta.error || meta.submitError}</FormErrorMessage>
      </FormControl>
    )
  }
)

export default InputNumberControl
