/** @jsx jsx */
import { Fragment } from "react"

import { Icon, iconSizes } from "@trueskin-web/components"
import { jsx } from "@trueskin-web/theme"
import { americanExpressIcon } from "@trueskin-web/theme/icons/americanExpressIcon"
import { applePayIcon } from "@trueskin-web/theme/icons/applePayIcon"
import { arrowLongRightIcon } from "@trueskin-web/theme/icons/arrowLongRightIcon"
import { arrowRightIcon } from "@trueskin-web/theme/icons/arrowRightIcon"
import { arrowShortRightIcon } from "@trueskin-web/theme/icons/arrowShortRightIcon"
import { bellIcon } from "@trueskin-web/theme/icons/bellIcon"
import { bulbIcon } from "@trueskin-web/theme/icons/bulbIcon"
import { cancelAutorefillIcon } from "@trueskin-web/theme/icons/cancelAutorefillIcon"
import { cancelIcon } from "@trueskin-web/theme/icons/cancelIcon"
import { chatIcon } from "@trueskin-web/theme/icons/chatIcon"
import { checkCircleFilledIcon } from "@trueskin-web/theme/icons/checkCircleFilledIcon"
import { checkCircleIcon } from "@trueskin-web/theme/icons/checkCircleIcon"
import { checkIcon } from "@trueskin-web/theme/icons/checkIcon"
import { checkMarkSealIcon } from "@trueskin-web/theme/icons/checkMarkSealIcon"
import { checkPolygonIcon } from "@trueskin-web/theme/icons/checkPolygonIcon"
import { choiceIcon } from "@trueskin-web/theme/icons/choiceIcon"
import { circleIcon } from "@trueskin-web/theme/icons/circleIcon"
import { cleanserIcon } from "@trueskin-web/theme/icons/cleanserIcon"
import { cleanserMoisturizerIcon } from "@trueskin-web/theme/icons/cleanserMoisturizerIcon"
import { closeIcon } from "@trueskin-web/theme/icons/closeIcon"
import { comedonesIcon } from "@trueskin-web/theme/icons/comedonesIcon"
import { copyIcon } from "@trueskin-web/theme/icons/copyIcon"
import { creamJarIcon } from "@trueskin-web/theme/icons/creamJarIcon"
import { creditCardIcon } from "@trueskin-web/theme/icons/creditCardIcon"
import { crossCircleIcon } from "@trueskin-web/theme/icons/crossCircleIcon"
import { crossedEyeIcon } from "@trueskin-web/theme/icons/crossedEyeIcon"
import { deliveryIcon } from "@trueskin-web/theme/icons/deliveryIcon"
import { dhlIcon } from "@trueskin-web/theme/icons/dhlIcon"
import { dotCircleIcon } from "@trueskin-web/theme/icons/dotCircleIcon"
import { dropperIcon } from "@trueskin-web/theme/icons/dropperIcon"
import { emailIcon } from "@trueskin-web/theme/icons/emailIcon"
import { emptyCircleIcon } from "@trueskin-web/theme/icons/emptyCircleIcon"
import { euroIcon } from "@trueskin-web/theme/icons/euroIcon"
import { exchangeIcon } from "@trueskin-web/theme/icons/exchangeIcon"
import { exitIcon } from "@trueskin-web/theme/icons/exitIcon"
import { eyeIcon } from "@trueskin-web/theme/icons/eyeIcon"
import { faceSideEffectIcon } from "@trueskin-web/theme/icons/faceSideEffectIcon"
import { faceZenIcon } from "@trueskin-web/theme/icons/faceZenIcon"
import { facebookIcon } from "@trueskin-web/theme/icons/facebookIcon"
import { fileFilledIcon } from "@trueskin-web/theme/icons/fileFilledIcon"
import { fileIcon } from "@trueskin-web/theme/icons/fileIcon"
import { flaskIcon } from "@trueskin-web/theme/icons/flaskIcon"
import { formulaIcon } from "@trueskin-web/theme/icons/formulaIcon"
import { giftIcon } from "@trueskin-web/theme/icons/giftIcon"
import { googleIcon } from "@trueskin-web/theme/icons/googleIcon"
import { halfVesselIcon } from "@trueskin-web/theme/icons/halfVesselIcon"
import { heartIcon } from "@trueskin-web/theme/icons/heartIcon"
import { homeFilledIcon } from "@trueskin-web/theme/icons/homeFilledIcon"
import { homeIcon } from "@trueskin-web/theme/icons/homeIcon"
import { infoIcon } from "@trueskin-web/theme/icons/infoIcon"
import { infoOutlineIcon } from "@trueskin-web/theme/icons/infoOutlineIcon"
import { instagramIcon } from "@trueskin-web/theme/icons/instagramIcon"
import { klarnaIcon } from "@trueskin-web/theme/icons/klarnaIcon"
import { klarnaTextIcon } from "@trueskin-web/theme/icons/klarnaTextIcon"
import { lockIcon } from "@trueskin-web/theme/icons/lockIcon"
import { logoutIcon } from "@trueskin-web/theme/icons/logoutIcon"
import { mastercardIcon } from "@trueskin-web/theme/icons/mastercardIcon"
import { medicalConsultationIcon } from "@trueskin-web/theme/icons/medicalConsultationIcon"
import { medicalKitFilledIcon } from "@trueskin-web/theme/icons/medicalKitFilledIcon"
import { medicalKitIcon } from "@trueskin-web/theme/icons/medicalKitIcon"
import { menuIcon } from "@trueskin-web/theme/icons/menuIcon"
import { messageFilledIcon } from "@trueskin-web/theme/icons/messageFilledIcon"
import { messageIcon } from "@trueskin-web/theme/icons/messageIcon"
import { minusIcon } from "@trueskin-web/theme/icons/minusIcon"
import { mixingDropsIcon } from "@trueskin-web/theme/icons/mixingDropsIcon"
import { moisturizerIcon } from "@trueskin-web/theme/icons/moisturizerIcon"
import { moneyBackIcon } from "@trueskin-web/theme/icons/moneyBackIcon"
import { moonFilledIcon } from "@trueskin-web/theme/icons/moonFilledIcon"
import { moonIcon } from "@trueskin-web/theme/icons/moonIcon"
import { packageIcon } from "@trueskin-web/theme/icons/packageIcon"
import { papulesIcon } from "@trueskin-web/theme/icons/papulesIcon"
import { paypalIcon } from "@trueskin-web/theme/icons/paypalIcon"
import { phoneIcon } from "@trueskin-web/theme/icons/phoneIcon"
import { pimpleIcon } from "@trueskin-web/theme/icons/pimpleIcon"
import { pimplesIcon } from "@trueskin-web/theme/icons/pimplesIcon"
import { pinIcon } from "@trueskin-web/theme/icons/pinIcon"
import { playIcon } from "@trueskin-web/theme/icons/playIcon"
import { plusCircleFilledIcon } from "@trueskin-web/theme/icons/plusCircleFilledIcon"
import { plusCircleIcon } from "@trueskin-web/theme/icons/plusCircleIcon"
import { plusIcon } from "@trueskin-web/theme/icons/plusIcon"
import { pregnancyIcon } from "@trueskin-web/theme/icons/pregnancyIcon"
import { prizeFilledIcon } from "@trueskin-web/theme/icons/prizeFilledIcon"
import { prizeIcon } from "@trueskin-web/theme/icons/prizeIcon"
import { productSetIcon } from "@trueskin-web/theme/icons/productSetIcon"
import { productsIcon } from "@trueskin-web/theme/icons/productsIcon"
import { questionIcon } from "@trueskin-web/theme/icons/questionIcon"
import { questionOutlineIcon } from "@trueskin-web/theme/icons/questionOutlineIcon"
import { questionnaireIcon } from "@trueskin-web/theme/icons/questionnaireIcon"
import { refillIcon } from "@trueskin-web/theme/icons/refillIcon"
import { sentimentHappyIcon } from "@trueskin-web/theme/icons/sentimentHappyIcon"
import { sentimentNeutralIcon } from "@trueskin-web/theme/icons/sentimentNeutralIcon"
import { sentimentSadIcon } from "@trueskin-web/theme/icons/sentimentSadIcon"
import { sentimentVeryHappyIcon } from "@trueskin-web/theme/icons/sentimentVeryHappyIcon"
import { sentimentVerySadIcon } from "@trueskin-web/theme/icons/sentimentVerySadIcon"
import { sepaIcon } from "@trueskin-web/theme/icons/sepaIcon"
import { shareIcon } from "@trueskin-web/theme/icons/shareIcon"
import { shineIcon } from "@trueskin-web/theme/icons/shineIcon"
import { shippingIcon } from "@trueskin-web/theme/icons/shippingIcon"
import { simpleEditIcon } from "@trueskin-web/theme/icons/simpleEditIcon"
import { smartphoneIcon } from "@trueskin-web/theme/icons/smartphoneIcon"
import { speakingTubeIcon } from "@trueskin-web/theme/icons/speakingTubeIcon"
import { spfIcon } from "@trueskin-web/theme/icons/spfIcon"
import { starEmptyIcon } from "@trueskin-web/theme/icons/starEmptyIcon"
import { starHalfIcon } from "@trueskin-web/theme/icons/starHalfIcon"
import { starIcon } from "@trueskin-web/theme/icons/starIcon"
import { stethoscopeFilledIcon } from "@trueskin-web/theme/icons/stethoscopeFilledIcon"
import { stethoscopeIcon } from "@trueskin-web/theme/icons/stethoscopeIcon"
import { subscriptionFilledIcon } from "@trueskin-web/theme/icons/subscriptionFilledIcon"
import { subscriptionIcon } from "@trueskin-web/theme/icons/subscriptionIcon"
import { sunFilledIcon } from "@trueskin-web/theme/icons/sunFilledIcon"
import { sunIcon } from "@trueskin-web/theme/icons/sunIcon"
import { timerIcon } from "@trueskin-web/theme/icons/timerIcon"
import { trashIcon } from "@trueskin-web/theme/icons/trashIcon"
import { triangleDownIcon } from "@trueskin-web/theme/icons/triangleDownIcon"
import { triangleUpIcon } from "@trueskin-web/theme/icons/triangleUpIcon"
import { truckIcon } from "@trueskin-web/theme/icons/truckIcon"
import { uploadFilledIcon } from "@trueskin-web/theme/icons/uploadFilledIcon"
import { uploadIcon } from "@trueskin-web/theme/icons/uploadIcon"
import { userDrawingIcon } from "@trueskin-web/theme/icons/userDrawingIcon"
import { visaIcon } from "@trueskin-web/theme/icons/visaIcon"
import { waterDropIcon } from "@trueskin-web/theme/icons/waterDropIcon"
import { whatsappIcon } from "@trueskin-web/theme/icons/whatsappIcon"
import { zendeskIcon } from "@trueskin-web/theme/icons/zendeskIcon"

const sizes = Object.keys(iconSizes)

const Icons = () => {
  const icons = [
    ["americanExpressIcon", americanExpressIcon],
    ["applePayIcon", applePayIcon],
    ["arrowLongRightIcon", arrowLongRightIcon],
    ["arrowShortRightIcon", arrowShortRightIcon],
    ["arrowRightIcon", arrowRightIcon],
    ["bellIcon", bellIcon],
    ["bulbIcon", bulbIcon],
    ["cancelIcon", cancelIcon],
    ["cancelAutorefillIcon", cancelAutorefillIcon],
    ["chatIcon", chatIcon],
    ["checkCircleFilledIcon", checkCircleFilledIcon],
    ["checkCircleIcon", checkCircleIcon],
    ["checkIcon", checkIcon],
    ["checkMarkSealIcon", checkMarkSealIcon],
    ["checkPolygonIcon", checkPolygonIcon],
    ["choiceIcon", choiceIcon],
    ["circleIcon", circleIcon],
    ["cleanserIcon", cleanserIcon],
    ["cleanserMoisturizerIcon", cleanserMoisturizerIcon],
    ["closeIcon", closeIcon],
    ["comedonesIcon", comedonesIcon],
    ["copyIcon", copyIcon],
    ["creamJarIcon", creamJarIcon],
    ["creditCardIcon", creditCardIcon],
    ["crossCircleIcon", crossCircleIcon],
    ["crossedEyeIcon", crossedEyeIcon],
    ["deliveryIcon", deliveryIcon],
    ["dhlIcon", dhlIcon],
    ["dotCircleIcon", dotCircleIcon],
    ["dropperIcon", dropperIcon],
    ["emailIcon", emailIcon],
    ["emptyCircleIcon", emptyCircleIcon],
    ["euroIcon", euroIcon],
    ["exchangeIcon", exchangeIcon],
    ["exitIcon", exitIcon],
    ["eyeIcon", eyeIcon],
    ["faceSideEffectIcon", faceSideEffectIcon],
    ["faceZenIcon", faceZenIcon],
    ["facebookIcon", facebookIcon],
    ["fileFilledIcon", fileFilledIcon],
    ["fileIcon", fileIcon],
    ["flaskIcon", flaskIcon],
    ["formulaIcon", formulaIcon],
    ["giftIcon", giftIcon],
    ["googleIcon", googleIcon],
    ["halfVesselIcon", halfVesselIcon],
    ["heartIcon", heartIcon],
    ["homeFilledIcon", homeFilledIcon],
    ["homeIcon", homeIcon],
    ["infoIcon", infoIcon],
    ["infoOutlineIcon", infoOutlineIcon],
    ["instagramIcon", instagramIcon],
    ["klarnaIcon", klarnaIcon],
    ["klarnaTextIcon", klarnaTextIcon],
    ["lockIcon", lockIcon],
    ["logoutIcon", logoutIcon],
    ["mastercardIcon", mastercardIcon],
    ["medicalConsultationIcon", medicalConsultationIcon],
    ["medicalKitFilledIcon", medicalKitFilledIcon],
    ["medicalKitIcon", medicalKitIcon],
    ["menuIcon", menuIcon],
    ["messageFilledIcon", messageFilledIcon],
    ["messageIcon", messageIcon],
    ["minusIcon", minusIcon],
    ["mixingDropsIcon", mixingDropsIcon],
    ["moisturizerIcon", moisturizerIcon],
    ["moneyBackIcon", moneyBackIcon],
    ["moonFilledIcon", moonFilledIcon],
    ["moonIcon", moonIcon],
    ["packageIcon", packageIcon],
    ["papulesIcon", papulesIcon],
    ["paypalIcon", paypalIcon],
    ["phoneIcon", phoneIcon],
    ["pimpleIcon", pimpleIcon],
    ["pimplesIcon", pimplesIcon],
    ["pinIcon", pinIcon],
    ["playIcon", playIcon],
    ["plusCircleIcon", plusCircleIcon],
    ["plusCircleFilledIcon", plusCircleFilledIcon],
    ["plusIcon", plusIcon],
    ["pregnancyIcon", pregnancyIcon],
    ["prizeIcon", prizeIcon],
    ["prizeFilledIcon", prizeFilledIcon],
    ["productsIcon", productsIcon],
    ["productSetIcon", productSetIcon],
    ["questionIcon", questionIcon],
    ["questionOutlineIcon", questionOutlineIcon],
    ["questionnaireIcon", questionnaireIcon],
    ["refillIcon", refillIcon],
    ["sentimentHappyIcon", sentimentHappyIcon],
    ["sentimentNeutralIcon", sentimentNeutralIcon],
    ["sentimentSadIcon", sentimentSadIcon],
    ["sentimentVeryHappyIcon", sentimentVeryHappyIcon],
    ["sentimentVerySadIcon", sentimentVerySadIcon],
    ["sepaIcon", sepaIcon],
    ["shareIcon", shareIcon],
    ["shineIcon", shineIcon],
    ["shippingIcon", shippingIcon],
    ["simpleEditIcon", simpleEditIcon],
    ["smartphoneIcon", smartphoneIcon],
    ["speakingTubeIcon", speakingTubeIcon],
    ["spfIcon", spfIcon],
    ["starEmptyIcon", starEmptyIcon],
    ["starHalfIcon", starHalfIcon],
    ["starIcon", starIcon],
    ["stethoscopeFilledIcon", stethoscopeFilledIcon],
    ["stethoscopeIcon", stethoscopeIcon],
    ["subscriptionFilledIcon", subscriptionFilledIcon],
    ["subscriptionIcon", subscriptionIcon],
    ["sunFilledIcon", sunFilledIcon],
    ["sunIcon", sunIcon],
    ["timerIcon", timerIcon],
    ["trashIcon", trashIcon],
    ["triangleDownIcon", triangleDownIcon],
    ["triangleUpIcon", triangleUpIcon],
    ["truckIcon", truckIcon],
    ["uploadFilledIcon", uploadFilledIcon],
    ["uploadIcon", uploadIcon],
    ["userDrawingIcon", userDrawingIcon],
    ["visaIcon", visaIcon],
    ["waterDropIcon", waterDropIcon],
    ["whatsappIcon", whatsappIcon],
    ["zendeskIcon", zendeskIcon],
  ]

  return (
    <div
      sx={{
        display: "inline-grid",
        gridTemplateColumns: `repeat(${sizes.length + 1}, auto)`,
        alignItems: "center",
        gridGap: 2,
        p: 2,
      }}
    >
      <div
        sx={{
          ml: "auto",
        }}
      >
        icon
      </div>
      {sizes.map((size) => (
        <span
          key={size}
          sx={{
            textAlign: "center",
          }}
        >
          {size} {iconSizes[size]["height"]}px
        </span>
      ))}

      {icons.map(([iconName, icon]) => (
        <Fragment key={iconName}>
          <div
            sx={{
              ml: "auto",
            }}
          >
            {iconName}
          </div>
          {sizes.map((size) => (
            <div
              key={`${iconName}-${size}`}
              sx={{
                textAlign: "center",
              }}
            >
              <Icon icon={icon} size={size} />
            </div>
          ))}
        </Fragment>
      ))}
    </div>
  )
}

export default Icons
