/** @jsx jsx */
import { forwardRef } from "react"

import { jsx } from "@trueskin-web/theme"

import Checkbox from "./checkbox"
import FormControl from "./form-control"
import FormErrorMessage from "./form-error-message"

const CheckboxControl = forwardRef(
  (
    {
      input,
      meta,
      className,
      mobileErrorMessagePosition,
      desktopErrorMessagePosition,
      errorMessagePlaceholder,
      mobileErrorMessagePlaceholder,
      desktopErrorMessagePlaceholder,
      dataTestId,
      ...props
    },
    ref
  ) => (
    <FormControl
      data-testid={dataTestId}
      isInvalid={meta.touched && meta.invalid}
      className={className}
      sx={{ display: "grid" }}
    >
      <Checkbox {...input} {...props} ref={ref} />
      <FormErrorMessage
        mobilePlaceholder={
          errorMessagePlaceholder || mobileErrorMessagePlaceholder
        }
        desktopPlaceholder={
          errorMessagePlaceholder || desktopErrorMessagePlaceholder
        }
        sx={{
          gridRow: [
            mobileErrorMessagePosition ? "1/2" : "inherit",
            desktopErrorMessagePosition ? "1/2" : "inherit",
          ],
        }}
      >
        {meta.error}
      </FormErrorMessage>
    </FormControl>
  )
)

export default CheckboxControl
