/** @jsx jsx */
import { jsx } from "@trueskin-web/theme"
import { arrowRightIcon } from "@trueskin-web/theme/icons/arrowRightIcon"

import Icon from "./icon"
import Input from "./input"

const SelectInput = ({ placeholder, children, ...otherProps }) => (
  <Input as="select" sx={{ pr: 6 }} {...otherProps}>
    {placeholder && (
      <option value="" hidden>
        {placeholder}
      </option>
    )}
    {children}
  </Input>
)

const Select = ({ size, color, iconProps, ...otherProps }) => (
  <div sx={{ position: "relative", color }}>
    <SelectInput
      size={size}
      sx={{
        bg: "background",
      }}
      {...otherProps}
    />
    <div
      sx={{
        position: "absolute",
        right: 2,
        top: "50%",
        transform: "translateY(-50%)",
        pointerEvents: "none",
      }}
    >
      <Icon
        icon={arrowRightIcon}
        size={size}
        sx={{ transform: "rotate(90deg)" }}
        {...iconProps}
      />
    </div>
  </div>
)

export default Select
