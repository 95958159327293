const ClientOnly = ({ children }) => {
  const isSSR = typeof window === "undefined"

  if (isSSR) {
    return null
  }

  return children
}

export default ClientOnly
