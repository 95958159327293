/** @jsx jsx */
import { Fragment, useState } from "react"

import { jsx } from "@trueskin-web/theme"
import { starEmptyIcon } from "@trueskin-web/theme/icons/starEmptyIcon"
import { starHalfIcon } from "@trueskin-web/theme/icons/starHalfIcon"
import { starIcon } from "@trueskin-web/theme/icons/starIcon"

import HStack from "./h-stack"
import Icon from "./icon"

const ratingArr = [...Array(5)]

const getPercent = (rating, position) =>
  Math.round(Math.min(Math.max(rating - position, 0), 1) * 100)

const StarRatingIcon = ({ percent, ...otherProps }) => {
  let icon = starEmptyIcon

  if (percent >= 25 && percent < 75) {
    icon = starHalfIcon
  }

  if (percent > 50) {
    icon = starIcon
  }

  return <Icon icon={icon} {...otherProps} />
}

const StarRating = ({
  className,
  text = null,
  rating = 0,
  feedback = "",
  size,
  onClick = (score) => {},
}) => {
  const [rate, setRate] = useState(0)

  return (
    <div className={className}>
      {text && (
        <Fragment>
          <span>{text}</span>
          <br />
        </Fragment>
      )}

      <HStack
        spacing="0"
        sx={{
          display: "inline-flex",
          whiteSpace: "nowrap",
        }}
      >
        {ratingArr.map((_, position) => (
          <StarRatingIcon
            key={position}
            percent={
              rating ? getPercent(rating, position) : getPercent(rate, position)
            }
            size={size}
            onClick={() => onClick(position + 1)}
            onMouseEnter={() => setRate(position + 1)}
            onMouseLeave={() => setRate(0)}
            sx={{
              cursor: !rating && "pointer",
            }}
          />
        ))}{" "}
        {feedback}
      </HStack>
    </div>
  )
}

export default StarRating
export { StarRatingIcon }
