import { keyframes } from "@emotion/react"

export const fadeIn = keyframes({
  from: {
    opacity: 0,
  },
  to: {
    opacity: 1,
  },
})

export const fadeOut = keyframes({
  from: {
    opacity: 1,
  },
  to: {
    opacity: 0,
  },
})

export const slideUp = keyframes({
  from: {
    transform: "translateY(100vh)",
  },
  to: {
    transform: "translateY(0)",
  },
})

export const slideDown = keyframes({
  from: {
    transform: "translateY(0)",
  },
  to: {
    transform: "translateY(100vh)",
  },
})

export const slideInLeftOutRight = keyframes({
  "0%": {
    transform: "translateX(-100%)",
  },
  "100%": {
    transform: "translateX(100%) ",
  },
})

export const spin = keyframes({
  from: {
    transform: "rotate(0deg)",
  },
  to: {
    transform: "rotate(360deg)",
  },
})

export const blink = keyframes({
  "50%": {
    borderColor: "transparent",
  },
})

export const type = keyframes({
  from: { opacity: 0 },
  to: { width: "100%", opacity: 1 },
})

export const indetermination = keyframes({
  "0%": {
    transform: "translateX(0) scaleX(0)",
  },
  "40%": {
    transform: "translateX(0) scaleX(0.4)",
  },
  "100%": {
    transform: "translateX(100%) scaleX(0.5)",
  },
})
