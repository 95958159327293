/** @jsx jsx */
import { jsx } from "@trueskin-web/theme"

import SectionContent from "./section-content"
import SectionFooter from "./section-footer"
import SectionHeader from "./section-header"

const Section = ({
  className,
  title,
  description,
  onBack,
  backLabel,
  onDismiss,
  content,
  primaryAction,
  primaryActionLabel,
  isPrimaryActionLoading,
  isPrimaryActionDisabled,
  secondaryAction,
  secondaryActionLabel,
  isSecondaryActionLoading,
  isSecondaryActionDisabled,
  isSecondaryActionStylePrimary,
  actionError,
  footnote,
  dataTestId,
}) => (
  <section
    data-testid={dataTestId}
    className={className}
    sx={{
      "section:not(:first-of-type)": {
        mt: 5,
      },
    }}
  >
    <SectionHeader
      className={"section-header"}
      title={title}
      description={description}
      onBack={onBack}
      backLabel={backLabel}
      onDismiss={onDismiss}
    />

    <SectionContent className={"section-content"} content={content} />

    <SectionFooter
      className={"section-footer"}
      primaryAction={primaryAction}
      primaryActionLabel={primaryActionLabel}
      isPrimaryActionLoading={isPrimaryActionLoading}
      isPrimaryActionDisabled={isPrimaryActionDisabled}
      secondaryAction={secondaryAction}
      secondaryActionLabel={secondaryActionLabel}
      isSecondaryActionLoading={isSecondaryActionLoading}
      isSecondaryActionDisabled={isSecondaryActionDisabled}
      isSecondaryActionStylePrimary={isSecondaryActionStylePrimary}
      actionError={actionError}
      footnote={footnote}
    />
  </section>
)

export default Section
