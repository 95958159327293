/** @jsx jsx */
import { Fragment } from "react"
import { jsx } from "theme-ui"

export const productsIcon = {
  path: (
    <Fragment>
      <path
        d="M7.77456 13.5719L5.16092 1.1439C5.14975 1.09077 5.08717 1.06741 5.04435 1.10078C4.52526 1.50538 4.16156 1.8891 2.89647 2.15515C1.52655 2.44325 1.12208 2.2218 0.500356 2.05745C0.447746 2.04354 0.399616 2.09013 0.410815 2.14339L3.40034 16.3587C3.42524 16.4771 3.5058 16.5774 3.62002 16.6174C4.26598 16.8433 5.04135 16.8611 5.94613 16.6708C6.61588 16.53 7.17481 16.3133 7.6229 16.0208"
        stroke="currentColor"
        strokeWidth="0.5"
      />
      <path
        d="M1.6582 8.07739C2.30395 8.31256 3.12203 8.326 4.11244 8.11772C5.10285 7.90943 5.86818 7.56299 6.40842 7.07841"
        stroke="currentColor"
        strokeWidth="0.5"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5.77149 9.97511L6.42803 13.097C6.20534 13.3782 5.8263 13.559 5.31369 13.6668C4.79023 13.7769 4.34921 13.7615 3.99607 13.5972L3.3418 10.4861C3.73442 10.5501 4.15413 10.5364 4.60118 10.4424C5.04822 10.3483 5.43791 10.1918 5.77149 9.97511Z"
        stroke="currentColor"
        strokeWidth="0.5"
      />
      <path
        d="M0.371559 2.04188C1.00751 1.48575 1.50638 1.08327 2.48357 0.877762C3.46076 0.672257 4.29561 0.794253 5.08169 1.05133"
        stroke="currentColor"
        strokeWidth="0.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M3.16602 15.2468C3.99696 15.5565 4.82646 15.6242 5.65451 15.4501"
        stroke="currentColor"
        strokeWidth="0.5"
        strokeLinecap="round"
      />
      <path
        d="M1.67969 2.36096L2.61204 6.79436"
        stroke="currentColor"
        strokeWidth="0.5"
        strokeLinecap="round"
      />
      <path
        d="M15.0521 4.98993L15.0418 3.47035C15.0414 3.41594 15.0979 3.37986 15.1467 3.40397C15.7232 3.68886 16.0755 3.98642 17.4753 3.97694C18.7681 3.96818 19.2009 3.66459 19.7902 3.37149C19.8388 3.34731 19.8954 3.38267 19.8958 3.43695L19.9942 17.9634C19.995 18.0844 19.9376 18.1995 19.8345 18.2628C19.2513 18.6208 18.4974 18.8029 17.5728 18.8092C16.649 18.8154 15.8932 18.6438 15.3053 18.2943C15.2006 18.2321 15.1415 18.1166 15.1415 17.9947L15.1415 6.32372"
        stroke="currentColor"
        strokeWidth="0.5"
      />
      <path
        d="M15.082 9.53406C15.668 9.89313 16.467 10.0692 17.4791 10.0624C18.4911 10.0555 19.3101 9.86845 19.936 9.50118"
        stroke="currentColor"
        strokeWidth="0.5"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M18.7367 12.213L18.7583 15.4031C18.4841 15.6343 18.0766 15.7361 17.5528 15.7396C17.0179 15.7432 16.5888 15.6403 16.2754 15.4089L16.2539 12.2299C16.6259 12.3708 17.04 12.4409 17.4968 12.4378C17.9536 12.4347 18.3666 12.359 18.7367 12.213Z"
        stroke="currentColor"
        strokeWidth="0.5"
      />
      <path
        d="M15.0243 3.3631C15.7583 2.94477 16.3273 2.64971 17.3259 2.64295C18.3244 2.63618 19.1182 2.92201 19.8374 3.3305"
        stroke="currentColor"
        strokeWidth="0.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M15.1328 16.8601C15.8854 17.329 16.6848 17.5606 17.531 17.5549"
        stroke="currentColor"
        strokeWidth="0.5"
        strokeLinecap="round"
      />
      <path
        d="M16.2422 3.93616L16.2729 8.46643"
        stroke="currentColor"
        strokeWidth="0.5"
        strokeLinecap="round"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9.00417 4.01029L15.112 5.06135C15.2567 5.08624 15.3522 5.2335 15.3252 5.39024L15.135 6.49542L11.8958 19.0205C11.8585 19.1647 11.726 19.2574 11.5904 19.234L7.63405 18.5532C7.49885 18.53 7.40503 18.3991 7.41749 18.2511L8.50317 5.3542L8.69335 4.24903C8.72032 4.09228 8.85948 3.98539 9.00417 4.01029Z"
        stroke="currentColor"
        strokeWidth="0.5"
      />
      <path
        d="M8.41211 5.33826L15.1364 6.49538"
        stroke="currentColor"
        strokeWidth="0.5"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8.12305 18.6373L8.19387 20.0123C8.20066 20.1443 8.29038 20.2518 8.41112 20.2726L10.2444 20.588C10.3688 20.6094 10.4923 20.5331 10.54 20.4054L11.0148 19.1349L8.12305 18.6373Z"
        stroke="currentColor"
        strokeWidth="0.5"
      />
      <path
        d="M9.45898 10.225L9.04465 16.2903"
        stroke="currentColor"
        strokeWidth="0.5"
        strokeLinecap="round"
      />
      <path
        d="M9.67383 7.31714L9.58376 8.66944"
        stroke="currentColor"
        strokeWidth="0.5"
        strokeLinecap="round"
      />
    </Fragment>
  ),
  viewBox: "0 0 21 21",
  fill: "none",
}
