/** @jsx jsx */
import { jsx } from "@trueskin-web/theme"

const UserGeneratedHtml = ({ html, ...otherProps }) => (
  <div
    sx={{
      wordBreak: "break-word",
      h2: {
        fontSize: [5, 6],
        fontWeight: "normal",
        // mt: [7, "60px"],
        mt: 5,
        mb: 4,
      },
      h3: {
        fontSize: [4, 5],
        fontWeight: "normal",
        mt: [6, "40px"],
        mb: 2,
      },
      h4: {
        fontSize: [3, 4],
        fontWeight: "normal",
        mt: [5, "30px"],
        mb: 1,
      },
      p: {
        mt: 0,
        mb: 4,
      },
      img: {
        maxWidth: "100%",
      },
      hr: {
        my: 7,
        borderTop: 0,
        borderBottom: "1px",
      },
      a: {
        color: "primary",
      },
      "a:hover, &:focus": {
        color: "primaryLight",
      },
      ol: {
        position: "relative",
        mt: 0,
        mb: 4,
        pl: 6,
        counterReset: "count",
        listStyleType: "none",
      },
      "ol > li": {
        counterIncrement: "count",
        mb: 4,
      },
      "ol > li:before": {
        position: "absolute",
        left: 0,
        mt: "1px",
        content: "counter(count, decimal-leading-zero)",
        fontSize: 1,
        fontFamily: "monospace",
      },
      "ol > li:last-of-type": {
        mb: 0,
      },
      ul: {
        position: "relative",
        mt: 0,
        mb: 4,
        pl: 6,
        listStyleType: "none",
      },
      "ul > li": {
        mb: 4,
      },
      "ul > li:before": {
        position: "absolute",
        left: 0,
        mt: 2,
        content: "' '",
        width: 8,
        height: 8,
        bg: "primary",
      },
      "ul > li:last-of-type": {
        mb: 0,
      },
      blockquote: {
        position: "relative",
        mt: 0,
        mb: 4,
        mx: 0,
        px: 4,
        py: 6,
        fontSize: 3,
        borderTop: "1px",
        borderBottom: "1px",
      },
      "blockquote:before": {
        content: "' '",
        position: "absolute",
        top: "-1px",
        left: "50%",
        height: "1px",
        width: "64px",
        transform: "translateX(-50%)",
        backgroundColor: "background",
      },
      "blockquote:after": {
        content: "'“'",
        position: "absolute",
        top: "-100px",
        left: "50%",
        fontSize: "100px",
        transform: "rotate(-180deg) translateX(-50%)",
        transformOrigin: "left",
        pointerEvents: "none",
      },
      "blockquote > :first-of-type": {
        mt: 0,
      },
      "blockquote > :last-of-type": {
        mb: 0,
      },
      ".table": {
        width: "100%",
        mt: 0,
        ml: 0,
        mb: 4,
        overflowX: "auto",
      },
      table: {
        width: "100%",
        borderCollapse: "collapse",
        border: "1px",
        fontSize: 1,
      },
      "td, th": {
        p: 3,
        verticalAlign: "top",
        border: "1px",
      },
      th: {
        bg: "lightYellow",
      },
      "& > *:first-of-type": {
        mt: 0,
      },
      "& > *:last-of-type": {
        mb: 0,
      },
    }}
    dangerouslySetInnerHTML={{ __html: html }}
    {...otherProps}
  />
)

export default UserGeneratedHtml
