/** @jsx jsx */
import { useId } from "@reach/auto-id"
import { forwardRef } from "react"

import { jsx } from "@trueskin-web/theme"

import FormControl from "./form-control"
import FormErrorMessage from "./form-error-message"
import FormLabel from "./form-label"
import FormMessage from "./form-message"
import Input from "./input"

const InputControl = forwardRef(
  (
    {
      className,
      input,
      meta,
      label,
      message,
      required,
      messagePlaceholder,
      mobileMessagePlaceholder,
      desktopMessagePlaceholder,
      errorMessagePlaceholder,
      mobileErrorMessagePlaceholder,
      desktopErrorMessagePlaceholder,
      ...props
    },
    ref
  ) => {
    const id = useId()

    return (
      <FormControl
        isInvalid={
          (meta.error || (meta.submitError && !meta.dirtySinceLastSubmit)) &&
          meta.touched
        }
        isRequired={required}
        className={className}
      >
        {label && <FormLabel htmlFor={id}>{label}</FormLabel>}
        <Input id={id} ref={ref} {...input} {...props} />
        <FormErrorMessage
          mobilePlaceholder={
            errorMessagePlaceholder || mobileErrorMessagePlaceholder
          }
          desktopPlaceholder={
            errorMessagePlaceholder || desktopErrorMessagePlaceholder
          }
        >
          {meta.error || meta.submitError}
        </FormErrorMessage>
        <FormMessage
          mobilePlaceholder={messagePlaceholder || mobileMessagePlaceholder}
          desktopPlaceholder={messagePlaceholder || desktopMessagePlaceholder}
        >
          {message}
        </FormMessage>
      </FormControl>
    )
  }
)

export default InputControl
