/** @jsx jsx */
import { Fragment } from "react"
import { jsx } from "theme-ui"

export const heartIcon = {
  path: (
    <Fragment>
      <g clipPath="url(#clip0_813:8768)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M12.4196 21.3483C8.62175 19.2843 2.75992 13.7527 3.0076 8.96415C3.25528 3.92793 9.69504 1.78134 12.5021 7.06525C15.3092 1.8639 21.7489 3.92793 21.9966 8.96415C22.1618 13.7527 16.2174 19.2843 12.4196 21.3483Z"
          stroke="currentColor"
          strokeWidth="0.5"
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M17.5383 5.24902C17.5383 5.24902 20.0152 5.90951 20.6756 8.96427"
          stroke="currentColor"
          strokeWidth="0.5"
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M20.6757 10.0376C20.6757 10.0376 20.7582 10.8632 20.428 11.6888"
          stroke="currentColor"
          strokeWidth="0.5"
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M12.4195 19.8622C12.4195 19.8622 10.1078 18.6238 7.96118 16.2295"
          stroke="currentColor"
          strokeWidth="0.5"
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M7.30079 15.4864C7.30079 15.4864 6.6403 14.8259 6.31006 14.4131"
          stroke="currentColor"
          strokeWidth="0.5"
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_813:8768">
          <rect
            width="20.5"
            height="22.2"
            fill="white"
            transform="translate(2 1)"
          />
        </clipPath>
      </defs>
    </Fragment>
  ),
  viewBox: "0 0 24 24",
  fill: "none",
}
