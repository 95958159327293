/** @jsx jsx */
import { Fragment } from "react"
import { jsx } from "theme-ui"

export const speakingTubeIcon = {
  path: (
    <Fragment>
      <path
        d="M0.788868 12.0502C0.450657 12.1655 0.225183 12.6266 0.33792 12.9724C0.676131 14.0099 1.12708 15.0474 1.46529 16.0849C1.57803 16.4307 2.02898 16.6613 2.25445 16.546M10.9352 15.0474C11.4989 15.1627 19.165 16.4307 19.8414 16.546C17.9249 11.3586 16.0084 6.17111 13.9791 0.868386C13.8664 0.983663 13.8664 0.983663 13.8664 1.09894C12.0626 3.40447 10.0333 5.82528 8.34226 8.13081L10.9352 15.0474ZM8.34226 8.01553C8.22952 8.01553 8.11678 8.01553 8.11678 8.13081C6.87667 8.59192 5.63657 9.05302 4.39646 9.51413C3.38182 9.97523 2.25445 10.3211 1.23982 10.7822C1.0719 10.8494 0.814261 11.1972 1.12708 12.0502C1.8035 13.8946 2.47993 15.7391 3.15635 17.5835C3.26909 17.9293 3.38182 17.9293 3.72003 17.814C4.05825 17.6988 4.39646 17.5835 4.62193 17.4682C6.31299 16.7766 8.00405 16.2002 9.6951 15.5085C10.0333 15.3932 10.4843 15.1627 10.8225 15.0474C10.0333 12.6266 9.24415 10.3211 8.34226 8.01553ZM4.84741 17.3529C5.7493 19.0821 6.08751 20.0043 6.87667 21.7334C7.5531 21.5029 8.11678 21.2723 8.79321 21.0418L8.11678 18.621C8.34226 18.3904 8.45499 18.1599 8.56773 17.9293C8.68047 17.814 8.68047 17.5835 8.68047 17.4682C8.56773 17.1224 8.34226 16.546 8.22952 16.0849L4.84741 17.3529Z"
        stroke="currentColor"
        strokeWidth="0.5"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M23.8995 6.74747L20.0664 8.01552"
        stroke="currentColor"
        strokeWidth="0.5"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M25.0278 12.9724L21.082 12.6266"
        stroke="currentColor"
        strokeWidth="0.5"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M20.6309 1.21431L17.8125 4.09623"
        stroke="currentColor"
        strokeWidth="0.5"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M4.3642 11.2259L2.53566 11.9373"
        stroke="currentColor"
        strokeWidth="0.5"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </Fragment>
  ),
  viewBox: "0 0 26 22",
  fill: "none",
}
