/** @jsx jsx */
import { Fragment } from "react"

import { jsx } from "@trueskin-web/theme"
import { arrowRightIcon } from "@trueskin-web/theme/icons/arrowRightIcon"
import { closeIcon } from "@trueskin-web/theme/icons/closeIcon"
import i18next from "@trueskin-web/translations"

import Button from "../button"
import Heading from "../heading"
import Icon from "../icon"
import IconButton from "../icon-button"

const SectionHeader = ({
  className,
  title,
  description,
  onBack,
  backLabel = i18next.t("Button.goBack"),
  onDismiss,
}) =>
  title || description || onBack || onDismiss ? (
    <section
      className={className}
      sx={{
        display: "grid",
        gridTemplateColumns: "1fr auto",
        gap: 3,
        whiteSpace: "pre-line",
      }}
    >
      {onBack || onDismiss || title ? (
        <Fragment>
          {onBack ? (
            <Button
              data-testid="web-portal-back-dialog-btn"
              variant="link"
              onClick={onBack}
              sx={{
                mr: "auto",
                p: 0,
                fontSize: 1,
                textDecoration: "none",
                zIndex: 1,
              }}
            >
              <Icon
                icon={arrowRightIcon}
                sx={{
                  transform: "rotate(180deg)",
                  ml: -2,
                }}
              />
              {backLabel}
            </Button>
          ) : null}

          {onDismiss ? (
            <IconButton
              data-testid="web-portal-close-dialog-btn"
              icon={closeIcon}
              onClick={onDismiss}
              sx={{
                gridColumn: onBack || title ? null : "span 2",
                ml: "auto",
                height: "min-content",
                zIndex: 1,
              }}
            />
          ) : null}

          {title ? (
            <Heading
              sx={{
                gridColumn: onBack ? "span 2" : null,
                order: onBack ? null : -1,
              }}
            >
              {title}
            </Heading>
          ) : null}
        </Fragment>
      ) : null}

      {description ? (
        typeof description === "string" ? (
          <div
            dangerouslySetInnerHTML={{
              __html: description,
            }}
            sx={{
              gridColumn: "span 2",
              whiteSpace: "pre-line",
            }}
          />
        ) : (
          <div
            sx={{
              gridColumn: "span 2",
              whiteSpace: "pre-line",
            }}
          >
            {description}
          </div>
        )
      ) : null}
    </section>
  ) : null

export default SectionHeader
