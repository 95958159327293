/** @jsx jsx */
import { Fragment } from "react"
import { jsx } from "theme-ui"

export const waterDropIcon = {
  path: (
    <Fragment>
      <path
        d="M22.5569 17.4277L22.3772 17.2472C22.2073 17.0837 22.0308 16.925 21.8866 16.8009C15.5892 10.6646 13.6292 0.754048 13.6098 0.654153C13.5373 0.276323 13.2078 0.00241682 12.8227 0C12.8211 0 12.8195 0 12.8179 0C12.4352 0 12.1049 0.269073 12.0284 0.645292C10.2593 9.34101 5.90016 14.7684 3.55745 17.6839C3.01206 18.363 2.58186 18.8987 2.30715 19.3177L2.22337 19.4433C2.21934 19.4498 2.18309 19.5046 2.17906 19.511L2.05983 19.7068C0.993208 21.3873 0.33261 23.2273 0.096567 25.1752C0.0595091 25.4797 0.03373 25.7875 0.0176179 26.0984C-0.356184 33.3795 5.26373 39.6093 12.5456 39.9831C12.7768 39.9944 13.0056 40 13.2336 40C20.2714 40 26.0685 34.4897 26.4286 27.4543C26.6228 23.73 25.209 20.0741 22.5569 17.4277ZM12.6302 38.3743C6.23529 38.0456 1.30014 32.5763 1.62803 26.1814C1.64253 25.9075 1.66508 25.6368 1.69731 25.3694C1.90435 23.6615 2.48439 22.0462 3.42775 20.5591L3.64446 20.2167C3.88695 19.8461 4.2962 19.337 4.81339 18.6925C6.95067 16.0316 10.708 11.3534 12.8372 4.07798C13.9425 7.67019 16.3151 13.6212 20.7974 17.9868C20.9553 18.1237 21.1108 18.2639 21.2478 18.3944L21.4145 18.5644C23.7476 20.8926 24.9898 24.1022 24.8223 27.3721C24.4952 33.7509 19.0751 38.7279 12.6302 38.3743Z"
        fill="currentColor"
      />
      <path
        d="M17.8063 19.6995C17.3865 19.5545 16.9265 19.7785 16.7823 20.1998C16.6373 20.6195 16.8621 21.0787 17.2826 21.2237C19.9975 22.1574 21.4468 25.1261 20.5139 27.8402C20.3697 28.2599 20.5945 28.7191 21.0142 28.8641C21.1004 28.8939 21.189 28.9084 21.276 28.9084C21.6103 28.9084 21.9237 28.6982 22.0381 28.3646C23.2594 24.8095 21.3614 20.9233 17.8063 19.6995Z"
        fill="currentColor"
      />
    </Fragment>
  ),
  viewBox: "0 0 27 40",
}
