/** @jsx jsx */
import { jsx } from "@trueskin-web/theme"

const style = {
  whiteSpace: "pre-line",
  "> p:first-child": {
    mt: 0,
  },
  "> p:last-child": {
    mb: 0,
  },
  "> h2, > h3": {
    fontWeight: "heading",
  },
  "> h3": {
    fontSize: 4,
    mb: "0.5em",
    "+ p": {
      mt: 0,
    },
  },
  "> ul": {
    my: 0,
    pl: "1.5em",
    "> li:not(:first-child)": {
      mt: 3,
    },
  },
}

const SectionContent = ({ className, content }) =>
  content ? (
    typeof content === "string" ? (
      <section
        className={className}
        dangerouslySetInnerHTML={{
          __html: content,
        }}
        sx={style}
      />
    ) : (
      <section className={className} sx={style}>
        {content}
      </section>
    )
  ) : null

export default SectionContent
