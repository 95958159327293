/** @jsx jsx */
import { jsx } from "@trueskin-web/theme"

const Container = ({ noMobilePadding = false, ...props }) => (
  <div
    sx={{
      maxWidth: "container",
      mx: "auto",
      px: [noMobilePadding || 4, 7],
    }}
    {...props}
  />
)

export default Container
