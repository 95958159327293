/** @jsx jsx */
import { forwardRef } from "react"

import { colors } from "@trueskin-web/functions"
import { jsx, useThemeUI } from "@trueskin-web/theme"
import { slideInLeftOutRight } from "@trueskin-web/theme/animations"

const sizes = {
  xlg: {
    fontSize: 4,
    px: 5,
    // TODO: update theme UI space to include 20px
    // Hint: Strapi space component needs to be updated in Strapi structure
    py: "20px",
  },
  lg: {
    fontSize: 3,
    px: 4,
    py: 4,
  },
  md: {
    fontSize: 2,
    px: 4,
    py: 3,
  },
  sm: {
    fontSize: 1,
    px: 4,
    py: 2,
  },
  xsm: {
    fontSize: 0,
    px: 4,
    py: 1,
  },
}

const variants = {
  solid: {
    borderRadius: 0,
    bg: "primary",
    color: "background",
    border: "1px",
    borderColor: "primary",
    fontSize: 1,
    fontWeight: "bold",
    fontFamily: "monospace",
    "&:hover": {
      bg: "primaryLight",
      borderColor: "primaryLight",
    },
    "&:focus, &.focus": {
      borderColor: "primaryDark",
      bg: "primary",
    },
    "&:active, &.active": {
      bg: "primaryDark",
      borderColor: "primaryDark",
    },
    "&:disabled, &[aria-disabled=true]": {
      bg: "hsla(0, 0%, 0%, .05)",
      borderColor: "disabled",
    },
    "&:after": {
      bg: "primaryDark",
    },
  },
  outline: {
    borderRadius: 0,
    bg: "transparent",
    border: "1px",
    fontSize: 1,
    fontWeight: "bold",
    fontFamily: "monospace",
    "&:disabled, &[aria-disabled=true]": {
      borderColor: "disabled",
    },
  },
  link: {
    borderRadius: 0,
    bg: "transparent",
    color: "inherit",
    border: "1px",
    borderColor: "transparent",
    textDecoration: "underline",
    lineHeight: 1.4,
    "&:hover": {
      color: "primaryLight",
    },
    "&:focus, &.focus": {
      color: "lightDark",
    },
    "&:active, &.active": {
      color: "primary",
    },
    "&:after": {
      bg: "primary",
    },
  },
}

const Button = forwardRef(
  (
    {
      as: Comp = "button",
      variant = "solid",
      size = "md",
      noPadding,
      type = "button",
      color = "primary",
      isFullWidth,
      isDisabled,
      isLoading,
      ...props
    },
    ref
  ) => {
    const {
      theme: { rawColors },
    } = useThemeUI()

    return (
      <Comp
        ref={ref}
        disabled={isDisabled}
        aria-disabled={isDisabled}
        type={type}
        sx={{
          position: "relative",
          overflow: "hidden",
          display: "inline-flex",
          alignItems: "center",
          justifyContent: "center",
          verticalAlign: "middle",
          textAlign: "center",
          width: isFullWidth ? "100%" : undefined,
          mx: 0,
          appearance: "none",
          userSelect: "none",
          textDecoration: "none",
          lineHeight: 1,
          letterSpacing: "0.05em",
          fontFamily: "inherit",
          border: 0,
          transition: "all .1ms ease-in",
          cursor: "pointer",
          pointerEvents: isLoading ? "none" : undefined,
          ":focus": {
            outline: "none",
          },
          ":not(:focus)": {
            transition: "background .1s ease-in",
          },
          ":disabled, &[aria-disabled=true]": {
            pointerEvents: "none",
            color: "disabled",
          },
          ":after": {
            content: isLoading ? `""` : undefined,
            position: "absolute",
            top: 0,
            right: 0,
            bottom: 0,
            left: 0,
            animation: `${slideInLeftOutRight} 1000ms ease-in-out infinite`,
            opacity: 0.6,
          },
          ...sizes[size],
          ...(noPadding
            ? {
                px: [0, 0],
                py: [0, 0],
              }
            : undefined),
          ...variants[variant],
          ...(variant !== "outline"
            ? undefined
            : { ...colors(rawColors[color]) }),
        }}
        {...props}
      />
    )
  }
)

export default Button
export { sizes, variants }
