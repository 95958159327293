/** @jsx jsx */
import { Fragment } from "react"
import { jsx } from "theme-ui"

export const checkMarkSealIcon = {
  path: (
    <Fragment>
      <path
        d="M10 0.713715L12.0844 2.83693L12.267 3.02289L12.524 2.97976L15.4583 2.48724L15.8967 5.43016L15.9351 5.68791L16.1684 5.80407L18.8318 7.13038L17.4566 9.76891L17.3362 10L17.4566 10.2311L18.8318 12.8696L16.1684 14.1959L15.9351 14.3121L15.8967 14.5698L15.4583 17.5128L12.524 17.0202L12.267 16.9771L12.0844 17.1631L10 19.2863L7.91556 17.1631L7.733 16.9771L7.476 17.0202L4.54166 17.5128L4.10331 14.5698L4.06492 14.3121L3.83165 14.1959L1.16822 12.8696L2.54339 10.2311L2.66384 10L2.54339 9.76891L1.16822 7.13038L3.83165 5.80407L4.06492 5.68791L4.10331 5.43016L4.54166 2.48724L7.476 2.97976L7.733 3.02289L7.91556 2.83693L10 0.713715Z"
        fill="currentColor"
        stroke="white"
      />
      <line
        y1="-1"
        x2="4.92485"
        y2="-1"
        transform="matrix(0.70805 0.706162 -0.70805 0.706162 5.37109 10.8438)"
        stroke="white"
        strokeWidth="2"
      />
      <line
        y1="-1"
        x2="8.95427"
        y2="-1"
        transform="matrix(0.70805 -0.706162 0.70805 0.706162 8.85742 14.3232)"
        stroke="white"
        strokeWidth="2"
      />
    </Fragment>
  ),
  viewBox: "0 0 20 20",
  fill: "none",
}
