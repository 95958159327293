/** @jsx jsx */
import { DialogContent, DialogOverlay } from "@reach/dialog"
import "@reach/dialog/styles.css"
import {
  forwardRef,
  useCallback,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
} from "react"

import { jsx, useBreakpointIndex } from "@trueskin-web/theme"
import { slideDown, slideUp } from "@trueskin-web/theme/animations"

import "../styles/reach-dialog.css"

import Section from "./section"

const ANIMATIONS_STATES = {
  PRE: 0,
  IDLE: 1,
  END: 2,
}

const formatAnimation = ({ animation, animationState }) => {
  if (animationState) {
    switch (animationState) {
      case ANIMATIONS_STATES.PRE:
        return formatAnimation({
          animation: {
            frames: slideDown,
            duration: 0,
            timing: "linear",
          },
        })

      case ANIMATIONS_STATES.IDLE:
        return formatAnimation({
          animation: {
            frames: slideUp,
            duration: 500,
            timing: "cubic-bezier(0, 0, 0, 1)",
          },
        })

      case ANIMATIONS_STATES.END:
        return formatAnimation({
          animation: {
            frames: slideDown,
            duration: 500,
            timing: "cubic-bezier(0, 0, 0, 1)",
          },
        })

      default:
        return null
    }
  }

  if (animation instanceof Array) {
    return animation.map(formatAnimation).join(",")
  }

  if (animation) {
    return `${animation.frames} ${animation.duration}ms ${animation.timing}`
  }

  return null
}

const Animated = forwardRef(
  ({ component: Component, onDismiss, ...props }, ref) => {
    const componentRef = useRef()

    useImperativeHandle(ref, () => ({
      onDismiss: handleDismiss,
      scrollToTop: componentRef.current.scrollToTop,
    }))

    const [animationState, setAnimationState] = useState(ANIMATIONS_STATES.PRE)

    const handleDismiss = useCallback(() => {
      if (animationState === ANIMATIONS_STATES.IDLE) {
        setAnimationState(ANIMATIONS_STATES.END)

        return
      }

      onDismiss?.()
    }, [onDismiss, animationState])

    const animation = formatAnimation({ animationState })

    useEffect(() => {
      if (animationState === ANIMATIONS_STATES.PRE) {
        setTimeout(() => setAnimationState(ANIMATIONS_STATES.IDLE), 0)
      }

      if (animationState === ANIMATIONS_STATES.END) {
        setTimeout(onDismiss, 500)
      }
    }, [animationState])

    return (
      <Component
        sx={{
          animation,
          opacity: !animation ? 0 : 1,
        }}
        ref={componentRef}
        onDismiss={handleDismiss}
        {...props}
      />
    )
  }
)

const Dialog = forwardRef(
  (
    {
      className,
      children,
      title,
      description,
      onBack,
      backLabel,
      onDismiss,
      primaryAction,
      primaryActionLabel,
      isPrimaryActionLoading,
      isPrimaryActionDisabled,
      secondaryAction,
      secondaryActionLabel,
      isSecondaryActionLoading,
      isSecondaryActionDisabled,
      isSecondaryActionStylePrimary,
      actionError,
      footnote,
      noDismissIcon,
      noBackdropDismiss,
      noMobileFullscreen,
      animate,
      ...props
    },
    ref
  ) => {
    const dialogRef = useRef()

    useImperativeHandle(ref, () => ({
      onDismiss,
      scrollToTop: () =>
        dialogRef.current.firstElementChild.scrollTo({ top: 0 }),
    }))

    const isMobileFullscreen = useBreakpointIndex() === 0 && !noMobileFullscreen

    return (
      <DialogOverlay
        ref={dialogRef}
        dangerouslyBypassFocusLock
        onDismiss={noBackdropDismiss ? undefined : onDismiss}
        sx={{
          background: isMobileFullscreen ? "none" : null,
        }}
        {...props}
      >
        <DialogContent
          className={className}
          sx={{
            height: isMobileFullscreen ? "100vh" : null,
            my: isMobileFullscreen ? 0 : null,
          }}
        >
          <Section
            title={title}
            description={description}
            onBack={onBack}
            backLabel={backLabel}
            onDismiss={noDismissIcon ? null : onDismiss}
            content={children}
            primaryAction={primaryAction}
            primaryActionLabel={primaryActionLabel}
            isPrimaryActionLoading={isPrimaryActionLoading}
            isPrimaryActionDisabled={isPrimaryActionDisabled}
            secondaryAction={secondaryAction}
            secondaryActionLabel={secondaryActionLabel}
            isSecondaryActionLoading={isSecondaryActionLoading}
            isSecondaryActionDisabled={isSecondaryActionDisabled}
            isSecondaryActionStylePrimary={isSecondaryActionStylePrimary}
            actionError={actionError}
            footnote={footnote}
          />
        </DialogContent>
      </DialogOverlay>
    )
  }
)

const withBehavior = (Component) =>
  forwardRef(({ isOpen, animate, ...props }, ref) => {
    const isMobileFullscreen =
      useBreakpointIndex() === 0 && !props.noMobileFullscreen

    if (isOpen === false) {
      return null
    }

    if (animate && isMobileFullscreen) {
      return <Animated ref={ref} {...props} component={Component} />
    }

    return <Component ref={ref} {...props} />
  })

export default withBehavior(Dialog)
