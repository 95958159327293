/** @jsx jsx */
import { FORM_ERROR } from "final-form"
import { Fragment } from "react"
import { Field, Form } from "react-final-form"

import {
  Button,
  Checkbox,
  CheckboxControl,
  FieldError,
  InputControl,
  InputNumberControl,
  Radio,
  RadioGroupControl,
  SelectControl,
  TextareaControl,
  checkboxSizes,
  radioSizes,
} from "@trueskin-web/components"
import {
  composeValidators,
  getErrorMessage,
  maxLength,
  mustBeTrue,
  mustNotContainNumbers,
  required,
  sleep,
} from "@trueskin-web/functions"
import { jsx } from "@trueskin-web/theme"

import Styleguide from "./styleguide"

const formCheckboxSizes = Object.keys(checkboxSizes)
const formRadioSizes = Object.keys(radioSizes)

const InputField = () => (
  <Field
    label="Name"
    name="name"
    component={InputControl}
    validate={composeValidators(required, mustNotContainNumbers)}
  />
)

const NumberField = () => (
  <Field
    label="Phone number"
    name="phone"
    component={InputNumberControl}
    validate={required}
  />
)

const TextareaField = () => (
  <Field
    label="Free text area"
    name="text"
    placeholder="input your thoughts"
    component={TextareaControl}
    validate={composeValidators(required, maxLength(100))}
    maxLength="101"
    type="text"
  />
)

const SelectField = () => (
  <Field
    label="Current month"
    name="month"
    component={SelectControl}
    validate={required}
    placeholder="select current month"
  >
    {[
      "January",
      "February",
      "March",
      "April",
      "May",
      "June",
      "July",
      "August",
      "September",
      "October",
      "November",
      "December",
    ].map((month, index) => (
      <option key={index} value={index}>
        {month}
      </option>
    ))}
  </Field>
)

const RadioField = () => (
  <Field
    label="Agreement to server processing form"
    name="isProcessingAgreement"
    format={(value) =>
      value === true ? "yes" : value === false ? "no" : undefined
    }
    parse={(value) =>
      value === "yes" ? true : value === "no" ? false : undefined
    }
    component={RadioGroupControl}
    type="input"
    validate={required}
  >
    <Radio value="yes">I agree</Radio>
    <Radio value="no">I do not agree</Radio>
  </Field>
)

const CheckboxField = () => (
  <Field
    name="isTermsAgreement"
    component={CheckboxControl}
    type="checkbox"
    validate={mustBeTrue}
  >
    I agree to terms of usage
  </Field>
)

const FormDemo = () => {
  const serverValidation = ({ month }) => {
    if (parseInt(month) !== new Date().getMonth()) {
      throw new Error("Current month is not correct")
    }
  }

  const onSubmit = async (values) => {
    try {
      await sleep(1500)
      serverValidation(values)
    } catch (error) {
      return { [FORM_ERROR]: getErrorMessage(error) }
    }
  }

  return (
    <div
      sx={{
        p: 2,
      }}
    >
      <Form
        initialValues={{
          month: undefined,
          isProcessingAgreement: undefined,
          isTermsAgreement: undefined,
        }}
        onSubmit={onSubmit}
        validate={({ isProcessingAgreement }) => {
          if (isProcessingAgreement !== true) {
            return {
              isProcessingAgreement: "You must agree to server processing",
            }
          }
        }}
        render={({
          handleSubmit,
          submitting,
          submitSucceeded,
          submitError,
          dirty,
          dirtySinceLastSubmit,
        }) =>
          submitSucceeded ? (
            <div>Submit Succeeded!</div>
          ) : (
            <form
              onSubmit={handleSubmit}
              sx={{
                "> *": {
                  mt: 2,
                  pb: 2,
                },
              }}
            >
              <InputField />
              <NumberField />
              <TextareaField />
              <SelectField />
              <RadioField />
              <CheckboxField />
              {submitError && <FieldError>{submitError}</FieldError>}
              <Button
                type="submit"
                isLoading={submitting}
                isDisabled={!dirty && !dirtySinceLastSubmit}
              >
                SAVE
              </Button>
            </form>
          )
        }
      />
    </div>
  )
}

const Checkboxes = () => (
  <div
    sx={{
      display: "inline-grid",
      gridTemplateColumns: "repeat(2, auto)",
      gridGap: 2,
      p: 2,
    }}
  >
    {formCheckboxSizes.map((size, index) => (
      <Fragment key={index}>
        <div
          sx={{
            alignSelf: "center",
            ml: "auto",
          }}
        >
          {size}
        </div>
        <Checkbox size={size}>I agree to terms of usage</Checkbox>
      </Fragment>
    ))}
  </div>
)

const Radios = () => (
  <div
    sx={{
      display: "inline-grid",
      gridTemplateColumns: "repeat(2, auto)",
      gridGap: 2,
      p: 2,
    }}
  >
    <Form
      onSubmit={() => {}}
      render={() => (
        <Fragment>
          {formRadioSizes.map((size, index) => (
            <Fragment key={index}>
              <div
                sx={{
                  alignSelf: "center",
                  ml: "auto",
                }}
              >
                {size}
              </div>
              <Radio name="radio" size={size}>
                I agree to terms of usage
              </Radio>
            </Fragment>
          ))}
        </Fragment>
      )}
    ></Form>
  </div>
)

const sections = [
  { name: "form", component: FormDemo },
  { name: "checkbox", component: Checkboxes },
  { name: "radio", component: Radios },
].map((section) => ({
  ...section,
  type: "section",
}))

const Forms = () => <Styleguide items={sections} />

export default Forms
