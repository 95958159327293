/** @jsx jsx */
import { jsx } from "theme-ui"

export const exitIcon = {
  path: (
    <path
      d="M3.18898 2.00102C3.13513 2.0113 3.08664 2.04027 3.05207 2.08282C3.0175 2.12536 2.99907 2.17876 3.00004 2.23357V21.7674C3.00004 21.8291 3.02454 21.8883 3.06815 21.9319C3.11176 21.9755 3.17091 22 3.23258 22H8.81369C8.8445 22.0004 8.87509 21.9947 8.90369 21.9832C8.93229 21.9717 8.95831 21.9547 8.98026 21.933C9.0022 21.9114 9.01963 21.8856 9.03152 21.8572C9.04342 21.8288 9.04954 21.7983 9.04954 21.7674C9.04954 21.7366 9.04342 21.7061 9.03152 21.6777C9.01963 21.6492 9.0022 21.6235 8.98026 21.6018C8.95831 21.5802 8.93229 21.5631 8.90369 21.5516C8.87509 21.5401 8.8445 21.5344 8.81369 21.5349H3.46513V2.46612H8.81369C8.8445 2.46655 8.87509 2.46086 8.90369 2.44937C8.93229 2.43788 8.95831 2.42082 8.98026 2.39918C9.0022 2.37754 9.01963 2.35176 9.03152 2.32333C9.04342 2.2949 9.04954 2.26439 9.04954 2.23357C9.04954 2.20275 9.04342 2.17224 9.03152 2.14381C9.01963 2.11538 9.0022 2.0896 8.98026 2.06796C8.95831 2.04632 8.93229 2.02926 8.90369 2.01777C8.87509 2.00628 8.8445 2.00059 8.81369 2.00102H3.23258C3.21808 1.99966 3.20348 1.99966 3.18898 2.00102ZM14.8453 6.64468C14.7988 6.64635 14.7539 6.66194 14.7163 6.68943C14.6788 6.71692 14.6503 6.75504 14.6347 6.79887C14.619 6.8427 14.6169 6.89022 14.6285 6.93528C14.6402 6.98034 14.6651 7.02087 14.7 7.05163L19.8378 11.768H7.8835C7.86901 11.7656 7.85439 11.7656 7.8399 11.768C7.71814 11.7793 7.61774 11.9006 7.62915 12.0223C7.64057 12.1441 7.76174 12.2445 7.8835 12.233H19.8378L14.7 16.9421C14.6551 16.9846 14.6289 17.0431 14.6271 17.1049C14.6252 17.1667 14.6479 17.2267 14.6901 17.2718C14.7324 17.3169 14.7907 17.3435 14.8525 17.3457C14.9143 17.3479 14.9744 17.3256 15.0198 17.2837L20.6009 12.1676C20.6244 12.1459 20.6432 12.1195 20.6561 12.0901C20.6689 12.0607 20.6756 12.0289 20.6756 11.9969C20.6756 11.9648 20.6689 11.9331 20.6561 11.9037C20.6432 11.8743 20.6244 11.8479 20.6009 11.8261L15.0198 6.71008C14.9968 6.68775 14.9694 6.67041 14.9394 6.65915C14.9094 6.6479 14.8774 6.64297 14.8453 6.64468Z"
      fill="currentColor"
    />
  ),
  viewBox: "0 0 24 24",
  fill: "none",
}
