/** @jsx jsx */
import { jsx } from "@trueskin-web/theme"
import { arrowRightIcon } from "@trueskin-web/theme/icons/arrowRightIcon"
import i18next from "@trueskin-web/translations"

import Button from "./button"
import Icon from "./icon"
import Link from "./link"

const BackButton = ({
  href,
  onClick,
  label = i18next.t("Button.goBack"),
  className,
  ...props
}) => (
  <Button
    data-testid="web-portal-back-button"
    variant="link"
    as={href ? Link : undefined}
    href={href}
    onClick={onClick ? onClick : undefined}
    className={className}
    sx={{
      px: 0,
      fontSize: 0,
      textTransform: "uppercase",
      textDecoration: "none",
    }}
    {...props}
  >
    <Icon
      icon={arrowRightIcon}
      size="sm"
      sx={{ transform: "rotate(180deg)" }}
    />
    {label}
  </Button>
)

export default BackButton
