/** @jsx jsx */
import { jsx } from "@trueskin-web/theme"

const CapsBar = ({ as: Comp = "div", children, ...otherProps }) => (
  <Comp
    sx={{
      variant: "text.caps",
      fontSize: 1,
      py: 2,
      borderBottom: "1px",
      borderTop: "1px",
    }}
    {...otherProps}
  >
    {children}
  </Comp>
)

export default CapsBar
