/** @jsx jsx */
import { Fragment } from "react"
import { jsx } from "theme-ui"

export const dotCircleIcon = {
  path: (
    <Fragment>
      <rect
        x="0.5"
        y="0.5"
        width="15"
        height="15"
        rx="7.5"
        fill="none"
        stroke="currentColor"
      />
      <circle cx="7.99995" cy="7.99922" r="4.8" fill="currentColor" />
    </Fragment>
  ),
  viewBox: "0 0 16 16",
}
