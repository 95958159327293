/** @jsx jsx */
import { jsx } from "theme-ui"

export const closeIcon = {
  path: (
    <path
      d="M24 8L8 24M8 8l16 16"
      stroke="currentColor"
      strokeWidth="2"
      strokeLinecap="square"
    />
  ),
  viewBox: "0 0 32 32",
}
