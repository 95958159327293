/** @jsx jsx */
import { Fragment } from "react"
import { jsx } from "theme-ui"

export const prizeFilledIcon = {
  path: (
    <Fragment>
      <path
        d="M19.7624 1H7.8457L7.87678 3.08188C7.93882 7.23867 10.1595 11.0637 13.7386 13.1786C17.4608 11.0747 19.7624 7.13004 19.7624 2.85436V1Z"
        fill="#EEB744"
      />
      <path
        d="M8.60742 20.2917H15.2027V22.5H8.60742V20.2917Z"
        stroke="currentColor"
      />
      <path d="M11.9043 20.3723V14.3572" stroke="currentColor" />
      <path
        d="M17.9284 2.44067H6.01172L6.04279 4.52255C6.10483 8.67934 8.32549 12.5043 11.9046 14.6192C15.6268 12.5154 17.9284 8.57071 17.9284 4.29504V2.44067Z"
        stroke="currentColor"
      />
      <path
        d="M6.0119 3.48828H3.39286L3.33048 4.27835C3.1424 6.66076 4.77738 8.80299 7.125 9.25012V9.25012"
        stroke="currentColor"
      />
      <path
        d="M18.1905 3.48828H20.8096L20.8163 3.98572C20.8512 6.57512 19.2067 8.88954 16.75 9.70846V9.70846"
        stroke="currentColor"
      />
    </Fragment>
  ),
  viewBox: "0 0 24 24",
  fill: "none",
}
