/** @jsx jsx */
import { Fragment } from "react"
import { jsx } from "theme-ui"

export const dropperIcon = {
  path: (
    <Fragment>
      <path
        d="M6.76139 19.5389L5.04325 16.0486L3.64727 19.5389C3.53988 19.7442 3.53988 19.9496 3.53988 20.1549C3.4325 21.0788 4.29157 21.6947 5.15063 21.6947C6.11708 21.6947 6.86877 20.9761 6.86877 20.1549C6.86877 19.9496 6.76139 19.7442 6.76139 19.5389Z"
        stroke="currentColor"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M14.4932 3.21657L15.7818 1.77939C16.1039 1.36877 16.7482 1.36877 17.0704 1.67674C17.4999 1.9847 17.4999 2.60064 17.1777 2.9086L15.8891 4.34578"
        stroke="currentColor"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M11.1642 6.60425L5.58023 12.7636C5.25808 13.0716 5.25808 13.4822 5.36546 13.8928L4.72116 14.6114C4.5064 14.8167 4.61378 15.022 4.72116 15.2273H4.82855C5.04331 15.4326 5.25808 15.33 5.47285 15.2273L6.11715 14.5087C6.54668 14.6114 6.97621 14.5087 7.29837 14.2008L12.9897 7.93877"
        stroke="currentColor"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M11.2715 4.14057L12.4527 2.80605C12.6675 2.49808 13.2044 2.49808 13.4192 2.70339L16.4259 5.16713C16.748 5.37244 16.748 5.88572 16.5333 6.09103L15.3521 7.42555"
        stroke="currentColor"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M14.2788 8.76006L10.1982 5.47508C9.98342 5.26977 9.98342 4.85914 10.1982 4.55118C10.413 4.34587 10.8425 4.24321 11.1646 4.44852L15.1378 7.7335C15.46 7.93881 15.46 8.34943 15.2452 8.6574C14.9231 8.96537 14.4935 8.96537 14.2788 8.76006Z"
        stroke="currentColor"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </Fragment>
  ),
  viewBox: "0 0 24 24",
  fill: "none",
}
