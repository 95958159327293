/** @jsx jsx */
import { jsx } from "@trueskin-web/theme"

import FormControl from "./form-control"
import FormErrorMessage from "./form-error-message"
import FormLabel from "./form-label"
import RadioGroup from "./radio-group"

const RadioGroupControl = ({
  input,
  meta,
  required,
  label,
  className,
  ...props
}) => (
  <FormControl
    isInvalid={meta.touched && meta.invalid}
    isRequired={required}
    className={className}
  >
    {label && <FormLabel htmlFor={input.name}>{label}</FormLabel>}
    <RadioGroup {...input} {...props} />
    <FormErrorMessage sx={{ justifyContent: "flex-start" }}>
      {meta.error}
    </FormErrorMessage>
  </FormControl>
)

export default RadioGroupControl
