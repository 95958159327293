/** @jsx jsx */
import { jsx } from "@trueskin-web/theme"

import FieldMessage from "./field-message"

const FieldError = (props) => (
  <FieldMessage
    sx={{
      color: "attention",
    }}
    {...props}
  />
)

export default FieldError
