/** @jsx jsx */
import { Fragment } from "react"
import { jsx } from "theme-ui"

export const moonFilledIcon = {
  path: (
    <Fragment>
      <path
        d="M35.8958 28.4828C35.7058 28.2957 35.4118 28.2683 35.1945 28.4157C34.3341 28.9899 33.4136 29.4814 32.4589 29.8766C24.353 33.2317 15.0826 29.2349 11.7933 20.9668C9.61816 15.4993 10.5351 9.25382 14.1865 4.66786C14.3525 4.45904 14.3551 4.16059 14.1928 3.94887C14.0302 3.73766 13.7445 3.67512 13.5075 3.78263C5.08924 7.82145 1.23813 17.8784 4.73879 26.6777C8.37308 35.8129 18.6159 40.229 27.5719 36.522C31.1119 35.0568 34.0218 32.5233 35.9879 29.1948C36.1238 28.9651 36.0859 28.6693 35.8958 28.4828Z"
        fill="#89ABF2"
      />
      <path
        d="M32.9299 25.2346C32.74 25.0476 32.446 25.0201 32.2287 25.1675C31.3683 25.7417 30.4478 26.2333 29.4931 26.6284C21.3871 29.9835 12.1168 25.9867 8.82747 17.7186C6.65234 12.2512 7.56926 6.00565 11.2206 1.41969C11.3867 1.21087 11.3893 0.912421 11.227 0.700698C11.0644 0.489492 10.7787 0.426951 10.5417 0.534466C2.12342 4.57328 -1.72769 14.6302 1.77297 23.4295C5.40726 32.5647 15.6501 36.9808 24.6061 33.2738C28.146 31.8086 31.056 29.2751 33.0221 25.9467C33.158 25.7169 33.1201 25.4211 32.9299 25.2346ZM24.1812 32.2059C15.8026 35.6738 6.22031 31.5422 2.82049 22.9964C-0.145539 15.5409 2.5295 7.12076 8.90973 2.79181C6.22704 7.41169 5.76814 13.0927 7.78096 18.1522C11.3046 27.0093 21.2355 31.2906 29.9185 27.6966C30.1666 27.5939 30.4128 27.4852 30.6561 27.3703C28.9373 29.4982 26.7344 31.1491 24.1812 32.2059Z"
        fill="currentColor"
      />
    </Fragment>
  ),
  viewBox: "0 0 37 38",
  fill: "none",
}
