/** @jsx jsx */
import { jsx } from "@trueskin-web/theme"
import { Trans } from "@trueskin-web/translations"

import Button from "../button"
import FaqLink from "../faq-link"
import FieldError from "../field-error"

const SectionFooter = ({
  className,
  primaryAction,
  primaryActionLabel,
  isPrimaryActionLoading,
  isPrimaryActionDisabled,
  secondaryAction,
  secondaryActionLabel,
  isSecondaryActionLoading,
  isSecondaryActionDisabled,
  isSecondaryActionStylePrimary,
  actionError,
  footnote,
}) =>
  primaryAction || secondaryAction || footnote ? (
    <section
      className={className}
      sx={{
        display: "grid",
        gap: 3,
      }}
    >
      {primaryAction ? (
        <Button
          data-testid="web-portal-section-primary-btn"
          onClick={primaryAction}
          variant="solid"
          isLoading={isPrimaryActionLoading}
          isDisabled={isPrimaryActionDisabled}
        >
          {primaryActionLabel || <Trans i18nKey="Button.continue" />}
        </Button>
      ) : null}

      {secondaryAction ? (
        <Button
          data-testid="web-portal-section-secondary-btn"
          onClick={secondaryAction}
          variant={isSecondaryActionStylePrimary ? "solid" : "outline"}
          isLoading={isSecondaryActionLoading}
          isDisabled={isSecondaryActionDisabled}
        >
          {secondaryActionLabel || <Trans i18nKey="Button.cancel" />}
        </Button>
      ) : null}

      {actionError ? <FieldError>{actionError}</FieldError> : null}

      {footnote ? (
        footnote === true ? (
          <FaqLink sx={{ mt: 4, textAlign: "center" }} />
        ) : typeof footnote === "string" ? (
          <div
            dangerouslySetInnerHTML={{
              __html: footnote,
            }}
            sx={{
              whiteSpace: "pre-line",
            }}
          />
        ) : (
          <div
            sx={{
              whiteSpace: "pre-line",
            }}
          >
            {footnote}
          </div>
        )
      ) : null}
    </section>
  ) : null

export default SectionFooter
