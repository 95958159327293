/** @jsx jsx */
import { jsx } from "theme-ui"

export const infoOutlineIcon = {
  path: (
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M7.99998 1.83331C4.59422 1.83331 1.83331 4.59422 1.83331 7.99998C1.83331 11.4057 4.59422 14.1666 7.99998 14.1666C11.4057 14.1666 14.1666 11.4057 14.1666 7.99998C14.1666 4.59422 11.4057 1.83331 7.99998 1.83331ZM0.833313 7.99998C0.833313 4.04194 4.04194 0.833313 7.99998 0.833313C11.958 0.833313 15.1666 4.04194 15.1666 7.99998C15.1666 11.958 11.958 15.1666 7.99998 15.1666C4.04194 15.1666 0.833313 11.958 0.833313 7.99998ZM7.49998 5.33331C7.49998 5.05717 7.72384 4.83331 7.99998 4.83331H8.00665C8.28279 4.83331 8.50665 5.05717 8.50665 5.33331C8.50665 5.60946 8.28279 5.83331 8.00665 5.83331H7.99998C7.72384 5.83331 7.49998 5.60946 7.49998 5.33331ZM7.99998 7.49998C8.27612 7.49998 8.49998 7.72384 8.49998 7.99998V10.6666C8.49998 10.9428 8.27612 11.1666 7.99998 11.1666C7.72384 11.1666 7.49998 10.9428 7.49998 10.6666V7.99998C7.49998 7.72384 7.72384 7.49998 7.99998 7.49998Z"
      fill="currentColor"
    />
  ),
  viewBox: "0 0 16 16",
}
