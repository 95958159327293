/** @jsx jsx */
import { FORM_ERROR } from "final-form"
import { Fragment } from "react"
import { Field, Form } from "react-final-form"

import { marketingClient, newsletterClient } from "@trueskin-web/apis"
import { useAuth } from "@trueskin-web/context"
import { getErrorMessage, getHomepageUrl } from "@trueskin-web/functions"
import { jsx } from "@trueskin-web/theme"
import { arrowRightIcon } from "@trueskin-web/theme/icons/arrowRightIcon"
import { checkIcon } from "@trueskin-web/theme/icons/checkIcon"
import { dhlIcon } from "@trueskin-web/theme/icons/dhlIcon"
import { facebookIcon } from "@trueskin-web/theme/icons/facebookIcon"
import { instagramIcon } from "@trueskin-web/theme/icons/instagramIcon"
import { Slugs, Trans, useTranslation } from "@trueskin-web/translations"

import BrandLogo from "./brand-logo"
import Button from "./button"
import Container from "./container"
import FieldError from "./field-error"
import HStack from "./h-stack"
import Heading from "./heading"
import HealthECBadge from "./health-ec-badge"
import Icon from "./icon"
import IconButton from "./icon-button"
import InputControl from "./input-control"
import LegitScriptBadge from "./legit-script-badge"
import Link from "./link"
import Logo from "./logo"
import PaymentLogos from "./payment-logos"
import Spacer from "./spacer"
import Spinner from "./spinner"
import TrustedShopsBadge from "./trusted-shops-badge"
import VStack from "./v-stack"

export const FooterLink = ({
  doNotTrack,
  href,
  rel,
  target = "_self",
  ...props
}) =>
  doNotTrack ? (
    <Button
      variant="link"
      size="sm"
      onClick={() => {
        Object.assign(document.createElement("a"), {
          target,
          href,
        }).click()
      }}
      sx={{
        p: 0,
        justifyContent: "start",
        fontSize: 2,
        textAlign: "start",
        textDecoration: "none",
      }}
      {...props}
    />
  ) : (
    <Link
      href={href}
      target={target}
      rel={rel}
      sx={{
        color: "inherit",
        textDecoration: "none",
        "&:hover, &:focus, &.focus": {
          color: "primary",
        },
        "&:active, &.active": {
          color: "primary",
        },
        "&:disabled": {
          color: "disabled",
          cursor: "not-allowed",
        },
      }}
      {...props}
    />
  )

export const BottomFooterLink = ({
  doNotTrack,
  href,
  rel,
  target = "_self",
  ...props
}) =>
  doNotTrack ? (
    <Button
      variant="link"
      size="sm"
      onClick={() => {
        Object.assign(document.createElement("a"), {
          target,
          href,
        }).click()
      }}
      sx={{
        p: 0,
        justifyContent: "start",
        textAlign: "start",
        textDecoration: "none",
        fontSize: 1,
        opacity: 0.5,
      }}
      {...props}
    />
  ) : (
    <Link
      href={href}
      target={target}
      rel={rel}
      sx={{
        fontSize: 1,
        color: "inherit",
        opacity: 0.5,
        textDecoration: "none",
      }}
      {...props}
    />
  )

const Logos = () => (
  <PaymentLogos
    size="lg"
    sx={{
      borderTop: "1px",
      borderColor: "rgba(0, 0, 0, 0.25)",
      pt: 4,
    }}
  >
    <BrandLogo icon={dhlIcon} size="lg" />
  </PaymentLogos>
)

const Footer = ({
  heading,
  instagramUrl,
  facebookUrl,
  primaryLinks,
  secondaryLinks,
  trustShopsLogo,
  trustScore,
  trustScoreDescription,
  healthEcLogo,
  legitScriptLogo,
}) => {
  const { t } = useTranslation()
  const { user } = useAuth()

  const handleSubmit = async (values) => {
    try {
      await newsletterClient.subscribe(values.email)
    } catch (error) {
      return { [FORM_ERROR]: getErrorMessage(error) }
    }
  }

  const excludedLinks = ["skin-guide", "table-of-contents"].map(
    (link) => Slugs[link].path
  )

  return (
    <footer
      sx={{
        bg: "grey",
        color: "text",
        fontFamily: "monospace",
        py: [5, 9],
      }}
    >
      <Container
        sx={{
          display: "grid",
          gridTemplateColumns: ["auto", "repeat(12, 1fr)"],
          gap: [6, 5],
        }}
      >
        <div
          sx={{
            display: "flex",
            flexDirection: "column",
            gridColumn: [null, "1 / span 4"],
            maxWidth: [null, 360],
          }}
        >
          <Heading as="h3" size="md" mb={[4, 5]}>
            {heading}
          </Heading>

          <Form
            onSubmit={handleSubmit}
            render={({
              handleSubmit,
              submitting,
              submitError,
              submitSucceeded,
            }) =>
              submitSucceeded ? (
                <div sx={{ display: "flex", alignItems: "center" }}>
                  <div
                    sx={{
                      display: "grid",
                      placeItems: "center",
                      width: 24,
                      height: 24,
                      mr: 4,
                      bg: "primary",
                      color: "background",
                      borderRadius: "50%",
                    }}
                  >
                    <Icon icon={checkIcon} size="sm" />
                  </div>
                  <div>
                    <Trans i18nKey="Footer.ReactFinalForm.submitSucceeded" />{" "}
                    <span role="img" aria-label="smile">
                      🙂
                    </span>
                  </div>
                </div>
              ) : (
                <form onSubmit={handleSubmit} sx={{ position: "relative" }}>
                  <div sx={{ display: "flex" }}>
                    <div sx={{ flex: 1 }}>
                      <Field
                        name="email"
                        component={InputControl}
                        required
                        type="email"
                        size="lg"
                        placeholder={t(
                          "Footer.ReactFinalForm.email.placeholder"
                        )}
                        hoverBorderColor="white"
                      />
                    </div>
                    <IconButton
                      icon={arrowRightIcon}
                      size="lg"
                      type="submit"
                      title="einreichen"
                      sx={{
                        width: "48px",
                        bg: "white",
                        "&:hover, &:active, &:focus": {
                          color: "inherit",
                        },
                      }}
                    />
                  </div>

                  {submitting && (
                    <div
                      sx={{
                        display: "grid",
                        placeItems: "center",
                        position: "absolute",
                        top: 0,
                        bottom: 0,
                        left: 0,
                        right: 0,
                        backgroundColor: "rgba(255, 255, 255, 0.85)",
                      }}
                    >
                      <Spinner />
                    </div>
                  )}

                  {submitError && (
                    <FieldError sx={{ mt: 4 }}>{submitError}</FieldError>
                  )}
                </form>
              )
            }
          />

          <div sx={{ display: ["none", "block"], mt: "auto", mb: 1 }}>
            <VStack>
              <div>
                <div
                  sx={{
                    fontSize: 1,
                    my: [1, 2],
                  }}
                >
                  <Trans i18nKey="Footer.TrustedShopsBadge.title" />
                </div>
                <div sx={{ display: "flex", alignItems: "center" }}>
                  <TrustedShopsBadge
                    logo={trustShopsLogo}
                    score={trustScore}
                    description={trustScoreDescription}
                  />
                  <LegitScriptBadge logo={legitScriptLogo} />
                </div>
                <HealthECBadge logo={healthEcLogo} />
              </div>
              <Logos />
            </VStack>
          </div>
        </div>

        <div sx={{ gridColumn: [null, "6 / -1"] }}>
          <div
            sx={{
              display: "flex",
              alignItems: "center",
              py: 4,
              mb: [4, 40],
              borderTop: "1px",
              borderBottom: "1px",
              borderColor: "rgba(0, 0, 0, 0.25)",
            }}
          >
            <Link
              href={getHomepageUrl(user)}
              aria-current="page"
              aria-label="Return Home"
              sx={{ color: "inherit" }}
            >
              <Logo size="sm" color="text" />
            </Link>

            <Spacer />

            <IconButton
              icon={instagramIcon}
              size="sm"
              onClick={() => {
                Object.assign(document.createElement("a"), {
                  target: "_blank",
                  href: instagramUrl,
                }).click()
              }}
              sx={{ mr: [1, 3], color: "primary" }}
            />
            <IconButton
              icon={facebookIcon}
              size="sm"
              onClick={() => {
                Object.assign(document.createElement("a"), {
                  target: "_blank",
                  href: facebookUrl,
                }).click()
              }}
              sx={{ color: "primary" }}
            />
          </div>

          <div
            sx={{
              display: "grid",
              gridTemplateColumns: ["repeat(2, 1fr)", "repeat(3, 1fr)"],
              columnGap: [4, 7],
              rowGap: 4,
              mb: 3,
            }}
          >
            {primaryLinks.map(({ label, targetUrl }) => (
              <FooterLink
                doNotTrack={
                  excludedLinks.filter((term) => targetUrl.includes(term))
                    .length === 0
                }
                key={label}
                href={targetUrl}
                target={label === "FAQ" ? "_blank" : undefined}
              >
                {label}
              </FooterLink>
            ))}

            <FooterLink href={Slugs["table-of-contents"].url}>
              <Trans i18nKey="Footer.tableOfContent" />
            </FooterLink>
          </div>

          <div
            sx={{
              display: [null, "none"],
              mb: 4,
            }}
          >
            <VStack>
              <div>
                <div
                  sx={{
                    fontSize: 1,
                    pt: 4,
                    my: [1, 2],
                    borderTop: "1px",
                    borderColor: "rgba(0, 0, 0, 0.25)",
                  }}
                >
                  <Trans i18nKey="Footer.TrustedShopsBadge.title" />
                </div>
                <div sx={{ display: "flex", alignItems: "center" }}>
                  <TrustedShopsBadge
                    logo={trustShopsLogo}
                    score={trustScore}
                    description={trustScoreDescription}
                  />
                  <LegitScriptBadge logo={legitScriptLogo} />
                </div>
                <HealthECBadge logo={healthEcLogo} />
              </div>
              <Logos />
            </VStack>
          </div>

          <HStack
            sx={{
              pt: 3,
              borderTop: "1px",
              borderColor: "rgba(0, 0, 0, 0.25)",
              flexWrap: "wrap",
            }}
          >
            {secondaryLinks.map((link) => (
              <BottomFooterLink
                doNotTrack
                key={link.label}
                href={link.targetUrl}
              >
                {link.label}
              </BottomFooterLink>
            ))}
            <BottomFooterLink
              doNotTrack
              onClick={() => marketingClient.consentSettings()}
            >
              <Trans i18nKey="Footer.dataSettings" />
            </BottomFooterLink>
          </HStack>
        </div>
      </Container>
    </footer>
  )
}

export default Footer
