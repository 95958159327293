/** @jsx jsx */
import { Fragment } from "react"
import { jsx } from "theme-ui"

export const stethoscopeFilledIcon = {
  path: (
    <Fragment>
      <circle cx="29.6074" cy="30.9413" r="6.42181" fill="#F8B518" />
      <path
        d="M11.6699 20.8096V37.3227L18.9797 37.3635V26.7958H23.9683"
        stroke="#1D160F"
        strokeMiterlimit="10"
      />
      <path d="M5.15234 0V4.90712" stroke="#1D160F" strokeMiterlimit="10" />
      <path d="M18.4297 0V4.90712" stroke="#1D160F" strokeMiterlimit="10" />
      <path
        d="M18.2665 2.56543H20.6692L22.3592 4.43869L18.2665 20.7482H11.6491H5.0723L1 4.43869L2.69 2.56543H5.0723"
        stroke="#1D160F"
        strokeMiterlimit="10"
      />
      <path
        d="M23.9082 26.6736C23.9082 28.5061 25.3946 29.9721 27.2068 29.9721C29.0189 29.9721 30.5053 28.4857 30.5053 26.6736C30.5053 24.8614 29.0189 23.375 27.2068 23.375C25.3946 23.375 23.9082 24.8614 23.9082 26.6736V26.6736Z"
        stroke="#1D160F"
        strokeMiterlimit="10"
      />
    </Fragment>
  ),
  viewBox: "0 0 37 38",
  fill: "none",
}
