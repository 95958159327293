/** @jsx jsx */
import { forwardRef } from "react"

import { jsx } from "@trueskin-web/theme"

const sizes = {
  xl: {
    fontSize: 7,
  },
  lg: {
    fontSize: 6,
  },
  md: {
    fontSize: 5,
  },
  sm: {
    fontSize: 4,
  },
  xsm: {
    fontSize: 3,
  },
}

const Heading = forwardRef(
  ({ as: Comp = "h2", size = "md", mt = 0, mb = 0, ...otherProps }, ref) => (
    <Comp
      ref={ref}
      sx={{
        fontWeight: "normal",
        lineHeight: "heading",
        mt,
        mb,
        ...sizes[size],
      }}
      {...otherProps}
    />
  )
)

export default Heading
export { sizes }
