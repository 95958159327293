/** @jsx jsx */
import { jsx } from "@trueskin-web/theme"
import { googleIcon } from "@trueskin-web/theme/icons/googleIcon"

import Icon from "./icon"
import StarRating from "./star-rating"

const GoogleRating = ({ className, rating, compact = false }) => (
  <div
    className={className}
    sx={{
      display: "inline-grid",
      gridTemplateColumns: compact ? "auto" : "repeat(2, auto)",
      gridGap: 2,
      alignItems: "center",
      justifyItems: "center",
      fontSize: 12,
      fontWeight: "bold",
    }}
  >
    <StarRating
      rating={rating}
      size="sm"
      sx={{
        color: "goldDark",
        backgroundColor: "white",
        py: 1,
        px: 2,
        borderRadius: 99,
      }}
    />
    <div
      sx={{
        display: "flex",
        alignItems: "center",
      }}
    >
      <Icon icon={googleIcon} size="sm" />
      <span
        sx={{
          ml: 1,
        }}
      >
        Google
      </span>
    </div>
  </div>
)

export default GoogleRating
