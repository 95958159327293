/** @jsx jsx */
import { Fragment } from "react"
import { jsx } from "theme-ui"

export const logoutIcon = {
  path: (
    <Fragment>
      <path
        d="M12 28H4V4h8M21.3 22.7L28 16l-6.7-6.7"
        stroke="currentColor"
        strokeWidth="1.8"
        strokeLinecap="square"
        strokeLinejoin="round"
        fill="none"
      />
      <path
        d="M28 16.9a.9.9 0 0 0 0-1.8V17zM12 15h-.9V17h.9V15zm16 0H12V17h16V15z"
        fill="currentColor"
      />
    </Fragment>
  ),
  viewBox: "0 0 32 32",
}
