/** @jsx jsx */
import { jsx } from "@trueskin-web/theme"

const FieldMessage = (props) =>
  typeof props.children === "string" ? (
    <div
      className={props.className}
      dangerouslySetInnerHTML={{
        __html: props.children,
      }}
      sx={{
        whiteSpace: "break-spaces",
        mt: 1,
        fontSize: 0,
      }}
      data-testid={props["data-testid"]}
    />
  ) : (
    <div
      sx={{
        whiteSpace: "break-spaces",
        mt: 1,
        fontSize: 0,
      }}
      {...props}
    />
  )

export default FieldMessage
