/** @jsx jsx */
import { Fragment } from "react"
import { jsx } from "theme-ui"

export const refillIcon = {
  path: (
    <Fragment>
      <path
        d="M25 2V8H19"
        stroke="black"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M7 11.9996C7.00158 10.2629 7.5056 8.5638 8.45125 7.10715C9.39691 5.65051 10.7438 4.49858 12.3295 3.79031C13.9152 3.08203 15.6719 2.84766 17.3879 3.11547C19.1038 3.38328 20.7056 4.14183 22 5.29962L25 7.99962"
        stroke="black"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M7 22V16H13"
        stroke="black"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M25 12C24.9984 13.7367 24.4944 15.4358 23.5487 16.8925C22.6031 18.3491 21.2562 19.501 19.6705 20.2093C18.0848 20.9176 16.3281 21.152 14.6121 20.8841C12.8962 20.6163 11.2944 19.8578 10 18.7L7 16"
        stroke="black"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </Fragment>
  ),
  viewBox: "0 0 32 24",
  fill: "none",
}
