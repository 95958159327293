/** @jsx jsx */
import { useId } from "@reach/auto-id"
import {
  cloneElement,
  forwardRef,
  useImperativeHandle,
  useRef,
  useState,
} from "react"

import { jsx } from "@trueskin-web/theme"

const RadioGroup = forwardRef(
  (
    {
      onChange,
      name,
      variantColor,
      size,
      defaultValue,
      isInline,
      value: valueProp,
      spacing = 2,
      children,
      ...rest
    },
    ref
  ) => {
    const { current: isControlled } = useRef(valueProp != null)
    const [value, setValue] = useState(defaultValue || null)
    const _value = isControlled ? valueProp : value

    const rootRef = useRef()

    const _onChange = (event) => {
      if (!isControlled) {
        setValue(event.target.value)
      }

      if (onChange) {
        onChange(event, event.target.value)
      }
    }

    // If no name is passed, we'll generate a random, unique name
    const fallbackName = `radio-${useId()}`
    const _name = name || fallbackName

    const clones = children.map((child, index) => {
      const isLastRadio = children.length === index + 1
      const spacingProps = isInline ? { mr: spacing } : { mb: spacing }

      return (
        <div
          key={index}
          sx={{
            display: isInline ? "inline-block" : "block",
            ...(!isLastRadio && spacingProps),
          }}
        >
          {cloneElement(child, {
            name: _name,
            onChange: _onChange,
            checked: child.props.value === _value,
          })}
        </div>
      )
    })

    // Calling focus() on the radiogroup should focus on the selected option or first enabled option
    useImperativeHandle(
      ref,
      () => ({
        focus: () => {
          let input = rootRef.current.querySelector(
            "input:not(:disabled):checked"
          )

          if (!input) {
            input = rootRef.current.querySelector("input:not(:disabled)")
          }

          if (input) {
            input.focus()
          }
        },
      }),
      []
    )

    return (
      <div ref={rootRef} role="radiogroup" {...rest}>
        {clones}
      </div>
    )
  }
)

export default RadioGroup
