/** @jsx jsx */
import { jsx } from "theme-ui"

export const arrowRightIcon = {
  path: (
    <path
      d="M10.5 21l7-7-7-7"
      stroke="currentColor"
      strokeWidth="1.5"
      strokeLinecap="square"
      fill="none"
    />
  ),
  viewBox: "0 0 28 28",
}
