/** @jsx jsx */
import { Fragment } from "react"
import { jsx } from "theme-ui"

export const productSetIcon = {
  path: (
    <Fragment>
      <g clipPath="url(#clip0_891_3151)">
        <path
          d="M10.4561 15.552L7.51194 1.13842C7.50988 1.12454 7.50419 1.11151 7.4955 1.10071C7.4868 1.08992 7.47542 1.08176 7.46258 1.07713C7.44973 1.07249 7.4359 1.07155 7.42258 1.07439C7.40925 1.07724 7.39693 1.08377 7.38692 1.09329C6.71197 1.73237 5.8716 2.15561 4.96787 2.31161C4.08104 2.56844 3.13824 2.53009 2.27378 2.20203C2.26054 2.19853 2.24666 2.19854 2.23342 2.20204C2.22018 2.20555 2.20802 2.21244 2.19805 2.22207C2.18808 2.23171 2.18062 2.24379 2.17638 2.25719C2.17214 2.27059 2.17124 2.28487 2.17377 2.29872L5.53669 18.7815C5.54792 18.8496 5.57637 18.9135 5.61917 18.9667C5.66196 19.0199 5.7176 19.0605 5.78047 19.0845C6.63202 19.3498 7.53774 19.3699 8.39955 19.1425C8.97459 19.0419 9.53118 18.8505 10.0498 18.5752"
          stroke="currentColor"
        />
        <path
          d="M3.57373 9.18313C4.47341 9.45806 5.42891 9.47366 6.33658 9.22825C7.28391 9.06413 8.17357 8.64872 8.91815 8.02283"
          stroke="currentColor"
        />
        <path
          d="M8.19946 11.3813L8.94331 15.004C8.60898 15.3581 8.26475 15.4869 7.69315 15.6486C7.12155 15.8104 6.68477 15.7599 6.21171 15.5713L5.47412 11.9614C5.9462 12.0335 6.42706 12.016 6.89305 11.9099C7.3562 11.8176 7.799 11.6384 8.19946 11.3813Z"
          stroke="currentColor"
        />
        <path
          d="M2.11719 2.18267C2.76348 1.50278 3.58961 1.03421 4.49249 0.835422C5.47186 0.634382 6.48596 0.714656 7.42411 1.06748"
          stroke="currentColor"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M16.8877 7.90556V6.0282C16.8872 6.01398 16.8905 5.99987 16.8971 5.98728C16.9037 5.97469 16.9135 5.96406 16.9256 5.95646C16.9376 5.94886 16.9514 5.94455 16.9656 5.94396C16.9798 5.94337 16.9939 5.94653 17.0065 5.95311C17.7903 6.43658 18.7082 6.6559 19.6256 6.57889C20.5428 6.61549 21.4502 6.37805 22.2322 5.89678C22.2448 5.89021 22.2589 5.88705 22.2731 5.88764C22.2873 5.88823 22.3011 5.89254 22.3131 5.90014C22.3251 5.90774 22.335 5.91837 22.3416 5.93096C22.3482 5.94354 22.3515 5.95765 22.3509 5.97188L22.4572 22.3237C22.4589 22.3902 22.4437 22.4561 22.4131 22.5152C22.3825 22.5743 22.3375 22.6247 22.2822 22.6616C21.5079 23.1008 20.6275 23.3173 19.7381 23.2874C18.8513 23.3344 17.9687 23.1352 17.1878 22.7117C17.1298 22.6767 17.0819 22.6271 17.049 22.5678C17.0161 22.5085 16.9993 22.4416 17.0003 22.3738V9.23223"
          stroke="currentColor"
        />
        <path
          d="M16.938 12.8431C17.7635 13.2877 18.6963 13.4936 19.6321 13.4376C20.5918 13.4744 21.5444 13.2586 22.3949 12.8118"
          stroke="currentColor"
        />
        <path
          d="M21.0447 15.8593V19.4513C20.6496 19.7298 20.3157 19.8268 19.6883 19.8268C19.0069 19.8268 18.6781 19.7312 18.2506 19.4575L18.2256 15.7028C19.1293 16.024 20.1347 16.024 21.0384 15.7028L21.0447 15.8593Z"
          stroke="#1D160F"
        />
        <path
          d="M16.8691 5.89673C17.6351 5.38086 18.5339 5.09829 19.457 5.08321C20.4516 5.08047 21.4283 5.34871 22.2823 5.85919"
          stroke="#1D160F"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M14.744 13.9596L14.2001 17.0344C13.7548 17.2012 13.2694 17.1567 12.8125 17.0989C12.3154 17.036 11.8455 16.8305 11.4561 16.5058L11.9999 13.4375C12.4197 13.6642 12.8709 13.8229 13.3375 13.908C13.8008 14.0013 14.2754 14.0187 14.744 13.9596Z"
          stroke="#1D160F"
        />
        <path
          d="M11.9188 7.36518C11.9211 7.35071 11.927 7.33712 11.936 7.32577C11.945 7.31441 11.9568 7.30569 11.97 7.30047C11.9833 7.29525 11.9977 7.29372 12.0118 7.29603C12.0258 7.29833 12.039 7.30439 12.0501 7.31361C12.6905 7.94601 13.518 8.33867 14.4004 8.42879C15.2947 8.63977 16.2294 8.57476 17.0882 8.24186C17.1014 8.23836 17.1153 8.23836 17.1286 8.24187C17.1418 8.24537 17.154 8.25226 17.1639 8.2619C17.1739 8.27154 17.1813 8.28362 17.1856 8.29702C17.1898 8.31041 17.1907 8.3247 17.1882 8.33855L15.313 19.2132C15.301 19.2818 15.2724 19.3462 15.2297 19.4003C15.1871 19.4544 15.1319 19.4965 15.0692 19.5226C14.2256 19.8086 13.3226 19.8531 12.4564 19.6515C11.5786 19.5226 10.7512 19.1506 10.0623 18.575C10.0134 18.5282 9.97687 18.4693 9.95609 18.4039C9.93532 18.3385 9.931 18.2687 9.94354 18.2011L11.9188 7.36518Z"
          stroke="#1D160F"
        />
        <path
          d="M11.2939 10.7365C11.9658 11.3441 12.7963 11.7342 13.6817 11.8582C14.6109 12.0816 15.5807 12.0505 16.4946 11.7679"
          stroke="#1D160F"
        />
        <path
          d="M11.9438 7.22341C12.7685 6.83422 13.6878 6.71065 14.5817 6.86887C15.5357 7.04399 16.4234 7.49051 17.1445 8.1581"
          stroke="currentColor"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_891_3151">
          <rect
            width="22"
            height="24"
            fill="white"
            transform="translate(1.3335)"
          />
        </clipPath>
      </defs>
    </Fragment>
  ),
  fill: "none",
  viewBox: "0 0 24 24",
}
