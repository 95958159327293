/** @jsx jsx */
import { jsx } from "@trueskin-web/theme"

import Icon from "./icon"

const sizes = {
  xlg: {
    height: 40,
    width: 58,
  },
  lg: {
    height: 32,
    width: 46,
  },
  md: {
    height: 24,
    width: 34,
  },
}

const BrandLogo = ({ className, icon, size = "md" }) => (
  <Icon className={className} icon={icon} sx={{ ...sizes[size] }} />
)

export default BrandLogo
export { sizes }
