/** @jsx jsx */
import { Fragment } from "react"
import { jsx } from "theme-ui"

export const uploadIcon = {
  path: (
    <Fragment>
      <path
        d="M20.7318 23.2858H3.42267C2.59278 23.2858 2 22.7181 2 21.9234V5.46126C2 4.66654 2.59278 4.09888 3.42267 4.09888H20.6133C21.4431 4.09888 22.0359 4.66654 22.0359 5.46126V21.9234C22.1545 22.7181 21.4431 23.2858 20.7318 23.2858Z"
        stroke="currentColor"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M4.30469 4.0989L4.42324 2.85005C4.5418 2.16886 5.25313 1.6012 5.96447 1.71473L23.1551 3.07712C23.9849 3.19065 24.4592 3.75831 24.4592 4.55303L23.0365 21.0151C22.9179 21.8099 22.3252 22.264 21.4953 22.264"
        stroke="currentColor"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M5 23V21C5 19.9391 5.39333 18.9217 6.09347 18.1716C6.7936 17.4214 7.74319 17 8.73333 17H15.2667C16.2568 17 17.2064 17.4214 17.9065 18.1716C18.6067 18.9217 19 19.9391 19 21V23C17.6 23 5.74667 23 5 23ZM5 23C16.2 23 5.31111 23 5 23Z"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M12 14C13.6569 14 15 12.6569 15 11C15 9.34315 13.6569 8 12 8C10.3431 8 9 9.34315 9 11C9 12.6569 10.3431 14 12 14Z"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <circle
        cx="19.7857"
        cy="21.1492"
        r="6.28571"
        fill="#008874"
        stroke="white"
      />
      <path d="M16.625 21.1495H22.947" stroke="white" />
      <path d="M19.7861 17.9888V24.3108" stroke="white" />
    </Fragment>
  ),
  viewBox: "0 0 30 30",
  fill: "none",
}
