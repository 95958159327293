/** @jsx jsx */
import { Fragment } from "react"
import { jsx } from "theme-ui"

export const spfIcon = {
  path: (
    <Fragment>
      <path
        d="M3.00061 16.4011C2.99919 16.3573 3.00892 16.3139 3.02881 16.2754C3.04869 16.2369 3.07801 16.2048 3.11371 16.1822C3.14941 16.1597 3.19019 16.1476 3.23182 16.1473C3.27345 16.1469 3.31441 16.1583 3.35045 16.1802C5.5461 17.662 8.14378 18.3432 10.7435 18.119C13.4246 18.2222 16.0758 17.4971 18.3697 16.033C18.4058 16.011 18.4467 15.9997 18.4884 16C18.53 16.0004 18.5708 16.0124 18.6065 16.035C18.6422 16.0575 18.6715 16.0897 18.6914 16.1282C18.7113 16.1667 18.721 16.21 18.7196 16.2538L18.9994 37.0401C19.0056 37.2473 18.9617 37.4527 18.872 37.6372C18.7823 37.8218 18.6496 37.9794 18.4864 38.0954C16.2046 39.4178 13.627 40.0705 11.0234 39.985C8.4174 40.1073 5.82909 39.4794 3.53702 38.169C3.36803 38.0635 3.22909 37.9122 3.13472 37.7309C3.04036 37.5496 2.99404 37.3449 3.00061 37.1383V16.4011Z"
        fill="white"
      />
      <path
        d="M14 22V30.1196C12.6625 30.7755 11.1402 31.0756 9.61539 30.9838C8.02049 31.0653 6.42932 30.7752 5 30.1423V22.0227C6.46541 22.4023 7.98634 22.5863 9.51282 22.5686C11.0322 22.5797 12.5449 22.388 14 22Z"
        fill="#F8B518"
      />
      <path
        d="M2.00058 14.4011C1.99924 14.3573 2.00836 14.3139 2.02701 14.2754C2.04565 14.2369 2.07313 14.2048 2.1066 14.1822C2.14007 14.1597 2.1783 14.1476 2.21733 14.1473C2.25636 14.1469 2.29476 14.1583 2.32854 14.1802C4.38697 15.662 6.82229 16.3432 9.25951 16.119C11.7731 16.2222 14.2586 15.4971 16.4091 14.033C16.4429 14.011 16.4813 13.9997 16.5204 14C16.5594 14.0004 16.5976 14.0124 16.6311 14.035C16.6645 14.0575 16.692 14.0897 16.7107 14.1282C16.7293 14.1667 16.7384 14.21 16.7371 14.2538L16.9995 35.0401C17.0052 35.2473 16.9641 35.4527 16.88 35.6372C16.7959 35.8218 16.6715 35.9794 16.5185 36.0954C14.3794 37.4178 11.9628 38.0705 9.52189 37.985C7.07881 38.1073 4.65228 37.4794 2.50345 36.169C2.34503 36.0635 2.21477 35.9122 2.1263 35.7309C2.03783 35.5496 1.99441 35.3449 2.00058 35.1383V14.4011Z"
        stroke="currentColor"
      />
      <path
        d="M6.00249 2.2031C5.99783 2.17745 5.99974 2.15064 6.00796 2.12639C6.01617 2.10213 6.03029 2.08162 6.04837 2.06769C6.06644 2.05376 6.08757 2.04709 6.10884 2.04861C6.13012 2.05012 6.15049 2.05975 6.16714 2.07616C7.10889 2.84233 8.23361 3.19141 9.35718 3.06626C10.5296 3.12578 11.69 2.75468 12.6913 2C12.6913 2 12.8354 2 12.8354 2.12693L13 11.4948C12.9992 11.5995 12.9783 11.7024 12.9389 11.7951C12.8996 11.8878 12.8429 11.9676 12.7736 12.028C11.7823 12.7124 10.6571 13.0463 9.52183 12.9927C8.38853 13.054 7.26343 12.729 6.27004 12.0534C6.19974 12.0003 6.14189 11.9256 6.10218 11.8366C6.06248 11.7475 6.04231 11.6472 6.04366 11.5456L6.00249 2.2031Z"
        stroke="currentColor"
      />
      <path
        d="M6 2C6.98857 1.36692 8.15345 1.01926 9.35061 1C10.6374 1.00448 11.8987 1.33628 13 1.96"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </Fragment>
  ),
  viewBox: "0 0 23 42",
  fill: "none",
}
