/** @jsx jsx */
import NukaCarousel from "nuka-carousel"
import { useEffect, useRef, useState } from "react"

import { conditionalFunction } from "@trueskin-web/functions"
import { jsx } from "@trueskin-web/theme"
import { arrowLongRightIcon } from "@trueskin-web/theme/icons/arrowLongRightIcon"

import Icon from "./icon"
import Tappable from "./tappable"

const CarouselControl = ({
  text,
  textColor,
  indicator,
  indicatorColor,
  controls,
  ctrl,
  ...props
}) => {
  const previousPage = ({
    className,
    currentSlide,
    slideCount,
    slidesToShow,
    previousSlide,
    wrapAround,
  }) => {
    if (!controls || slideCount <= slidesToShow) {
      return null
    }
    return (
      <Tappable
        onClick={previousSlide}
        className={className}
        disabled={currentSlide === 0 && !wrapAround}
      >
        <Icon icon={arrowLongRightIcon} sx={{ transform: "rotate(180deg)" }} />
      </Tappable>
    )
  }

  const nextPage = ({
    className,
    currentSlide,
    slideCount,
    slidesToShow,
    nextSlide,
    wrapAround,
  }) => {
    if (!controls || slideCount <= slidesToShow) {
      return null
    }

    return (
      <Tappable
        onClick={nextSlide}
        className={className}
        disabled={currentSlide >= slideCount - slidesToShow && !wrapAround}
      >
        <Icon icon={arrowLongRightIcon} />
      </Tappable>
    )
  }

  const pageIndicator = ({
    className,
    currentSlide,
    slidesToShow,
    slideCount,
    goToSlide,
  }) => {
    if (!indicator || slideCount <= slidesToShow) {
      return null
    }

    return (
      <div
        data-testid="web-portal-carousel-page-indicator"
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
        className={className}
      >
        {[...Array(Math.ceil(slideCount / slidesToShow))].map((_, index) => (
          <Tappable
            key={index}
            onClick={() => goToSlide(index)}
            sx={{
              height: 6,
              width: 6,
              mx: 1,
              bg:
                Math.ceil(currentSlide / slidesToShow) === index
                  ? indicatorColor
                  : "#a8a8a8",
              borderRadius: "50%",
            }}
          />
        ))}
      </div>
    )
  }

  const beforeAfterText = ({
    className,
    currentSlide,
    slidesToShow,
    slideCount,
  }) => {
    if (!text || slideCount <= slidesToShow) {
      return null
    }

    return (
      <div
        sx={{
          color: textColor,
          fontSize: 3,
        }}
        className={className}
      >
        {currentSlide === 0 ? "Nachher" : "Vorher"}
      </div>
    )
  }

  const [carouselCtrls] = useState({
    previousPage,
    nextPage,
    pageIndicator,
    beforeAfterText,
  })

  const Ctrl = carouselCtrls[ctrl]

  if (!Ctrl) {
    return null
  }

  return <Ctrl {...props} />
}

const CarouselControls = ({ renderControls, ...props }) => (
  <div
    sx={{
      display: "flex",
      alignItems: "center",
      height: 32,
    }}
  >
    {renderControls.map((ctrl, index) => (
      <CarouselControl
        key={index}
        ctrl={ctrl}
        sx={
          index > 0 && {
            ml: 3,
          }
        }
        {...props}
      />
    ))}
  </div>
)

const Carousel = ({
  text = true,
  textColor = "primary",
  indicator = true,
  indicatorColor = "primary",
  controls = true,
  layout = {
    // legacy layout
    renderCenterLeftControls: "previousPage",
    renderCenterRightControls: "nextPage",
    renderBottomCenterControls: "pageIndicator",
    renderBottomRightControls: "beforeAfterText",
  },
  layoutOutline = true,
  ...props
}) => {
  const [isRendered, setIsRendered] = useState(false)
  const ref = useRef()

  const hasOutlineCtrl = (carouselLayout, ctrlPosition) => {
    if (!layoutOutline) {
      return false
    }

    return Object.keys(carouselLayout).some(
      (item) =>
        item.indexOf(ctrlPosition) === 0 &&
        typeof carouselLayout[item] === "function"
    )
  }

  const carouselLayout = Object.keys(layout).reduce(
    (acc, el) => ({
      ...acc,
      [el]: (props) => (
        <CarouselControls
          text={text}
          textColor={textColor}
          indicator={indicator}
          indicatorColor={indicatorColor}
          controls={controls}
          renderControls={layout[el].split(" ")}
          {...props}
        />
      ),
    }),
    {
      renderTopLeftControls: null,
      renderTopCenterControls: null,
      renderTopRightControls: null,
      renderCenterLeftControls: null,
      renderCenterCenterControls: null,
      renderCenterRightControls: null,
      renderBottomLeftControls: null,
      renderBottomCenterControls: null,
      renderBottomRightControls: null,
    }
  )

  useEffect(() => {
    const ctrl = new AbortController()

    const adjustSlidesHeight = () =>
      conditionalFunction({
        condition: () => ref.current,
        success: () => {
          setTimeout(() => {
            if (ctrl.signal.aborted) {
              return
            }

            setIsRendered(true)
          }, 0)
        },
        signal: ctrl.signal,
      })

    adjustSlidesHeight()

    return () => {
      ctrl.abort()
    }
  }, [])

  return (
    <NukaCarousel
      sx={{
        ul: { cursor: "auto !important" },
        pt: hasOutlineCtrl(carouselLayout, "renderTop") ? 40 : null,
        pb: hasOutlineCtrl(carouselLayout, "renderBottom") ? 40 : null,
        outline: "none",
        ".slider-slide > *": {
          height: isRendered ? "100%" : undefined,
        },
      }}
      ref={ref}
      disableEdgeSwiping
      {...carouselLayout}
      {...props}
    />
  )
}

export default Carousel
