/** @jsx jsx */
import { Fragment } from "react"
import { jsx } from "theme-ui"

export const cleanserIcon = {
  path: (
    <Fragment>
      <path
        d="M5 7.92195C5 7.87998 5.01063 7.83867 5.03096 7.80167C5.05128 7.76467 5.08067 7.73313 5.11651 7.70986C5.15235 7.68658 5.37705 7.50405 5.41992 7.5C5.4628 7.49595 5.91992 7 6.91992 6.5C10.4199 5 10.4672 5.22623 13.4199 5C16.3733 5.11467 19.9199 5.5 22.1886 7.51018C22.2292 7.48957 22.2746 7.4797 22.3203 7.48154C22.3661 7.48339 22.4105 7.49689 22.4492 7.52071C22.4879 7.54452 22.5195 7.57781 22.5409 7.61726C22.5623 7.6567 22.5726 7.70091 22.571 7.74548L22.9131 59.0396C22.9246 59.249 22.8784 59.4576 22.7793 59.6437C22.6802 59.8299 22.5319 59.9869 22.3496 60.0984C19.8526 61.465 17.2005 61.9224 14.1578 62.0592C11.3039 62.1875 8.4672 61.5644 5.94597 60.2553C5.76284 60.1432 5.61275 59.9867 5.51043 59.8011C5.4081 59.6154 5.35705 59.4071 5.36228 59.1965L5 7.92195Z"
        fill="white"
      />
      <path
        d="M17 36V47.7541C15.5812 48.6564 13.8691 49.0897 12.1454 48.9827C10.3333 49.0934 8.53012 48.67 7 47.7745V36.0614C10.2267 37.1127 13.7509 37.1127 16.9776 36.0614L17 36Z"
        fill="#89ABF2"
      />
      <path
        d="M3 5.44063C3 5.39866 3.01063 5.35735 3.03096 5.32035C3.05128 5.28335 3.08067 5.25181 3.11651 5.22853C3.15235 5.20526 3.19354 5.19096 3.23641 5.18691C3.27929 5.18286 3.32252 5.18919 3.36228 5.20533C5.89193 6.70626 8.84278 7.39235 11.7955 7.16612C14.7489 7.28079 17.6705 6.53681 20.1886 5.02886C20.2292 5.00825 20.2746 4.99837 20.3203 5.00022C20.3661 5.00207 20.4105 5.01557 20.4492 5.03938C20.4879 5.0632 20.5195 5.09649 20.5409 5.13593C20.5623 5.17537 20.5726 5.21959 20.571 5.26415L20.9131 56.5583C20.9246 56.7677 20.8784 56.9762 20.7793 57.1624C20.6802 57.3486 20.5319 57.5055 20.3496 57.6171C17.8526 58.9837 15.0206 59.6616 12.1578 59.5779C9.3039 59.7062 6.4672 59.0831 3.94597 57.774C3.76284 57.6619 3.61275 57.5054 3.51043 57.3197C3.4081 57.1341 3.35705 56.9258 3.36228 56.7151L3 5.44063Z"
        stroke="currentColor"
      />
      <path
        d="M3 26.804C5.65181 28.2214 8.6532 28.8999 11.6748 28.7647C14.7654 28.8826 17.8335 28.2064 20.571 26.804"
        stroke="currentColor"
      />
      <path
        d="M3 5.03916C5.46218 3.41449 8.35908 2.52831 11.3326 2.49014C14.5366 2.47256 17.6838 3.31368 20.4301 4.92151"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </Fragment>
  ),
  viewBox: "0 0 25 64",
  fill: "none",
}
