/** @jsx jsx */
import { Fragment } from "react"
import { jsx } from "theme-ui"

export const smartphoneIcon = {
  path: (
    <Fragment>
      <path
        d="M13 22H13.01"
        stroke="currentColor"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <rect x="6" y="4" width="14" height="22" rx="1" stroke="currentColor" />
      <circle cx="19" cy="6" r="5.5" fill="#F05545" stroke="white" />
    </Fragment>
  ),
  viewBox: "0 0 26 27",
  fill: "none",
}
