/** @jsx jsx */
import { Fragment } from "react"
import { jsx } from "theme-ui"

export const lockIcon = {
  path: (
    <Fragment>
      <path
        d="M11.875 7.375H3.125C2.43464 7.375 1.875 7.93464 1.875 8.625V13C1.875 13.6904 2.43464 14.25 3.125 14.25H11.875C12.5654 14.25 13.125 13.6904 13.125 13V8.625C13.125 7.93464 12.5654 7.375 11.875 7.375Z"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M4.375 7.375V4.875C4.375 4.0462 4.70424 3.25134 5.29029 2.66529C5.87634 2.07924 6.6712 1.75 7.5 1.75C8.3288 1.75 9.12366 2.07924 9.70971 2.66529C10.2958 3.25134 10.625 4.0462 10.625 4.875V7.375"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </Fragment>
  ),
  viewBox: "0 0 15 16",
  fill: "none",
}
