/** @jsx jsx */
import { Heading, headingSizes } from "@trueskin-web/components"
import { jsx, useThemeUI } from "@trueskin-web/theme"

const sizes = Object.keys(headingSizes)

const Headings = () => {
  const {
    theme: { fontSizes },
  } = useThemeUI()

  return (
    <div
      sx={{
        p: 2,
      }}
    >
      {sizes.map((size, index) => (
        <div
          key={size}
          sx={{
            mt: index !== 0 && 2,
          }}
        >
          <Heading size={size}>
            {size} {fontSizes[headingSizes[size]["fontSize"]]}px
          </Heading>
        </div>
      ))}
    </div>
  )
}

export default Headings
