/**
 * Seo component that queries for data with
 *  Gatsby's useStaticQuery React hook
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

/** @jsx jsx */
import Helmet from "react-helmet"

import { i18nLanguage } from "@trueskin-web/locales"
import { jsx } from "@trueskin-web/theme"

const Seo = ({
  lang,
  meta,
  title,
  description,
  siteMetadata = {
    title: "FORMEL Skin",
    description: "FORMEL Skin",
  },
  ...props
}) => (
  <Helmet
    htmlAttributes={{
      lang,
    }}
    title={title}
    titleTemplate={`%s | ${siteMetadata.title}`}
    meta={[
      {
        name: `description`,
        content: description || siteMetadata.description,
      },
      {
        property: `og:title`,
        content: title,
      },
      {
        property: `og:description`,
        content: description || siteMetadata.description,
      },
      {
        property: `og:type`,
        content: `website`,
      },
      {
        name: `twitter:card`,
        content: `summary`,
      },
      {
        name: `twitter:creator`,
        content: siteMetadata.author,
      },
      {
        name: `twitter:title`,
        content: title,
      },
      {
        name: `twitter:description`,
        content: description || siteMetadata.description,
      },
      {
        name: `google-site-verification`,
        content: process.env.GATSBY_GOOGLE_SITE_VERIFICATION_KEY,
      },
    ].concat(meta)}
    {...props}
  />
)

Seo.defaultProps = {
  lang: i18nLanguage(),
  meta: [],
  description: ``,
}

export default Seo
