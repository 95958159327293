/** @jsx jsx */
import { Fragment } from "react"
import { jsx } from "theme-ui"

export const medicalKitFilledIcon = {
  path: (
    <Fragment>
      <path
        d="M21.75 4.5H2.75C1.64543 4.5 0.75 5.50736 0.75 6.75V20.25C0.75 21.4926 1.64543 22.5 2.75 22.5H21.75C22.8546 22.5 23.75 21.4926 23.75 20.25V6.75C23.75 5.50736 22.8546 4.5 21.75 4.5Z"
        fill="currentColor"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M16.8301 12.5C16.8301 12.3674 16.7774 12.2402 16.6836 12.1464C16.5899 12.0527 16.4627 12 16.3301 12H13.8301V9.5C13.8301 9.36739 13.7774 9.24021 13.6836 9.14645C13.5899 9.05268 13.4627 9 13.3301 9H11.3301C11.1975 9 11.0703 9.05268 10.9765 9.14645C10.8828 9.24021 10.8301 9.36739 10.8301 9.5V12H8.33008C8.19747 12 8.07029 12.0527 7.97652 12.1464C7.88276 12.2402 7.83008 12.3674 7.83008 12.5V14.5C7.83008 14.6326 7.88276 14.7598 7.97652 14.8536C8.07029 14.9473 8.19747 15 8.33008 15H10.8301V17.5C10.8301 17.6326 10.8828 17.7598 10.9765 17.8536C11.0703 17.9473 11.1975 18 11.3301 18H13.3301C13.4627 18 13.5899 17.9473 13.6836 17.8536C13.7774 17.7598 13.8301 17.6326 13.8301 17.5V15H16.3301C16.4627 15 16.5899 14.9473 16.6836 14.8536C16.7774 14.7598 16.8301 14.6326 16.8301 14.5V12.5Z"
        fill="white"
      />
      <path
        d="M8.75 4.5V3C8.75 2.60218 8.90804 2.22064 9.18934 1.93934C9.47064 1.65804 9.85218 1.5 10.25 1.5H14.25C14.6478 1.5 15.0294 1.65804 15.3107 1.93934C15.592 2.22064 15.75 2.60218 15.75 3V4.5"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </Fragment>
  ),
  viewBox: "0 0 25 24",
  fill: "none",
}
