/** @jsx jsx */
import { forwardRef } from "react"

import { jsx } from "@trueskin-web/theme"

import Button from "./button"
import Icon from "./icon"

const IconButton = forwardRef(
  ({ icon, iconSize, variant = "link", ...props }, ref) => (
    <Button
      ref={ref}
      variant={variant}
      sx={{
        p: 0,
        touchAction: "manipulation",
      }}
      {...props}
    >
      <Icon
        icon={icon}
        focusable="false"
        color="currentColor"
        size={iconSize}
        aria-hidden
      />
    </Button>
  )
)

export default IconButton
