/** @jsx jsx */
import { Fragment } from "react"
import { jsx } from "theme-ui"

export const checkCircleFilledIcon = {
  path: (
    <Fragment>
      <path
        d="M16 8C16 12.5 12.5 16 8 16C3.5 16 0 12.5 0 8C0 6.25 0.5 4.75 1.25 3.5C2 2.5 3 1.5 4 1C5.25 0.5 6.5 0 7.75 0C12.5 0 16 3.5 16 8Z"
        fill="currentColor"
      />
      <path d="M4 8.5899L6.64536 11.0403L11.68 4.66699" fill="currentColor" />
      <path
        d="M4 8.5899L6.64536 11.0403L11.68 4.66699"
        stroke="white"
        strokeWidth="2"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </Fragment>
  ),
  viewBox: "0 0 16 16",
  fill: "none",
}
