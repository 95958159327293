/** @jsx jsx */
import { jsx } from "@trueskin-web/theme"
import { Trans } from "@trueskin-web/translations"

import Heading from "./heading"

const FaqContainer = ({ image, children }) => (
  <div
    sx={{
      my: 8,
    }}
  >
    <Heading
      as="h3"
      sx={{
        fontSize: 4,
        mt: 5,
      }}
    >
      <Trans i18nKey="FaqContainer.title" />
    </Heading>

    <div
      sx={{
        display: "flex",
        flexDirection: ["column", "row"],
        gap: [6, 8],
      }}
    >
      <div
        sx={{
          flex: 1,
        }}
      >
        <Trans i18nKey="FaqContainer.description" />
        <div
          sx={{
            borderTopWidth: 1,
            borderTopStyle: "solid",
            borderColor: "primaryDark",
            pt: 5,
            display: "flex",
            justifyContent: "space-around",
          }}
        >
          {image}
        </div>
      </div>

      <div
        sx={{
          flex: 1,
          position: "relative",
        }}
      >
        {children}
      </div>
    </div>
  </div>
)

export default FaqContainer
