/** @jsx jsx */
import { Fragment } from "react"
import { jsx } from "theme-ui"

export const euroIcon = {
  path: (
    <Fragment>
      <g clipPath="url(#clip0_1334_1995)">
        <path
          d="M0.503906 12C0.503906 15.05 1.71551 17.9751 3.87218 20.1317C6.02885 22.2884 8.95392 23.5 12.0039 23.5C15.0539 23.5 17.979 22.2884 20.1356 20.1317C22.2923 17.9751 23.5039 15.05 23.5039 12C23.5039 8.95001 22.2923 6.02494 20.1356 3.86827C17.979 1.7116 15.0539 0.5 12.0039 0.5C8.95392 0.5 6.02885 1.7116 3.87218 3.86827C1.71551 6.02494 0.503906 8.95001 0.503906 12Z"
          stroke="currentColor"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M14.5 17.5C10.91 17.5 8 16.5 8 12C8 7.5 10.91 6.5 14.5 6.5"
          stroke="currentColor"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M6.50391 13.5H12.0039"
          stroke="currentColor"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M6.50391 11.5H13.0039"
          stroke="currentColor"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_1334_1995">
          <rect width="24" height="24" fill="white" />
        </clipPath>
      </defs>
    </Fragment>
  ),
  viewBox: "0 0 24 24",
  fill: "none",
}
