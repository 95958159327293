/** @jsx jsx */
import { jsx } from "theme-ui"

export const subscriptionIcon = {
  path: (
    <path
      d="M21.9627 5.08264C21.9559 5.0684 21.9461 5.05823 21.9373 5.04534C21.9159 5.01313 21.8912 4.98465 21.8603 4.9599C21.8525 4.95346 21.8495 4.94431 21.8413 4.93888C21.8322 4.93312 21.8213 4.93346 21.8118 4.92803C21.7986 4.92024 21.7884 4.90871 21.7739 4.90261L11.1736 0.622568C11.1224 0.602225 11.0702 0.595784 11.019 0.598157C10.9678 0.595784 10.9159 0.601547 10.8654 0.62189L0.229532 4.90193C0.214953 4.90769 0.204782 4.91956 0.19122 4.92736C0.181388 4.93278 0.1702 4.9321 0.161046 4.9382C0.152908 4.94363 0.149519 4.95312 0.14172 4.95956C0.111545 4.98398 0.0867953 5.01212 0.0657744 5.04365C0.0569595 5.05687 0.0464485 5.06806 0.0396681 5.08264C0.0159348 5.13112 0.00135596 5.18334 0.000678086 5.23758C0.000678086 5.2386 0.000339043 5.23894 0.000339043 5.23996L0 14.8664C0 15.0152 0.090527 15.1488 0.228514 15.2041L10.8644 19.4848C10.8688 19.4868 10.8735 19.4858 10.8776 19.4872C10.9176 19.5018 10.9583 19.5116 11 19.5116C11.0458 19.5116 11.0905 19.5001 11.1332 19.4824C11.139 19.4801 11.1455 19.4811 11.1512 19.4787L21.7871 14.6283C21.9169 14.569 22 14.4392 22 14.2964V5.24109C22 5.24008 21.9997 5.23974 21.9997 5.23872C22.0007 5.1838 21.9861 5.13124 21.9624 5.08276L21.9627 5.08264ZM11.0014 9.12807L6.65718 7.38028L16.301 3.47962L20.6624 5.2406L11.0014 9.12807ZM11.019 1.34637L15.3279 3.08635L5.6824 6.98736L1.3416 5.2406L11.019 1.34637ZM0.729437 5.78073L10.6362 9.76723V18.6073L0.729437 14.6201V5.78073ZM21.2721 14.0618L11.3653 18.5796L11.3656 9.76731L21.2724 5.78082L21.2721 14.0618Z"
      fill="currentColor"
    />
  ),
  viewBox: "0 0 22 20",
}
