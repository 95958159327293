/** @jsx jsx */
import { jsx } from "theme-ui"

export const homeIcon = {
  path: (
    <path
      d="M12 1L2 8.15909V22H8.19048V13.8864H15.8095V22H22V8.15909L12 1Z"
      fill="none"
      stroke="currentColor"
    />
  ),
  viewBox: "0 0 24 24",
}
