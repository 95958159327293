/** @jsx jsx */
import { useEffect, useState } from "react"

import { jsx, useBreakpointIndex } from "@trueskin-web/theme"

import MobileCta from "./mobile-cta"

const MobileStickyCta = ({
  show,
  title,
  description,
  targetUrl,
  onClick,
  dataTestId,
}) => {
  const isMobile = useBreakpointIndex() === 0
  const [isLoading, setIsLoading] = useState(true)
  const [isVisible, setIsVisible] = useState(false)

  useEffect(() => {
    setIsLoading(false)

    return () => {
      const zenWidget = window.$zopim?.livechat?.button

      if (zenWidget) {
        zenWidget.setOffsetVerticalMobile(10)
      } else {
        window.zESettings = {
          webWidget: {
            offset: {
              mobile: {
                vertical: "10px",
              },
            },
          },
        }
      }
    }
  }, [])

  useEffect(() => {
    setIsVisible(show)
    const zenWidget = window.$zopim?.livechat?.button

    if (zenWidget) {
      zenWidget.setOffsetVerticalMobile(show ? 100 : 10)
    } else {
      window.zESettings = {
        webWidget: {
          offset: {
            mobile: {
              vertical: show ? "100px" : "10px",
            },
          },
        },
      }
    }
  }, [show])

  if (isLoading || !isMobile) {
    return null
  }

  return (
    <div
      sx={{
        position: "fixed",
        left: 0,
        bottom: isVisible ? 0 : "-100%",
        zIndex: 1,
        transitionProperty: "opacity, top, bottom",
        transitionDuration: "250ms",
        transitionTimingFunction: "cubic-bezier(0.645, 0.045, 0.355, 1)",
        boxShadow: "0 12px 24px 0 #000",
      }}
    >
      <MobileCta
        dataTestId={dataTestId}
        title={title}
        description={description}
        targetUrl={targetUrl}
        onClick={onClick}
      />
    </div>
  )
}

export default MobileStickyCta
