/** @jsx jsx */
import { jsx } from "@trueskin-web/theme"
import { arrowRightIcon } from "@trueskin-web/theme/icons/arrowRightIcon"

import Icon from "./icon"
import Link from "./link"

const TextLink = ({
  isTextColor,
  isUnderlined,
  isUppercase,
  isBold,
  hasArrowLeft,
  hasArrowRight,
  arrowSize,
  inheritColor,
  children,
  ...otherProps
}) => (
  <Link
    sx={{
      display: "inline-flex",
      alignItems: "center",
      color: inheritColor ? "inherit" : isTextColor ? "text" : "primary",
      textDecoration: isUnderlined ? "underline" : "none",
      textTransform: isUppercase ? "uppercase" : undefined,
      fontWeight: isBold ? "bold" : "normal",
      "&:hover, &:focus": {
        color: inheritColor ? "inherit" : "primaryLight",
      },
    }}
    {...otherProps}
  >
    {hasArrowLeft && (
      <Icon
        icon={arrowRightIcon}
        size={arrowSize}
        sx={{ transform: "rotate(180deg)" }}
      />
    )}
    {children}
    {hasArrowRight && <Icon icon={arrowRightIcon} />}
  </Link>
)

export default TextLink
