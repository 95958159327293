/** @jsx jsx */
import { Fragment } from "react"
import { jsx } from "theme-ui"

export const crossCircleIcon = {
  path: (
    <Fragment>
      <path
        d="M15.3845 7.99985C15.3845 12.1537 12.1537 15.3845 7.99985 15.3845C3.846 15.3845 0.615234 12.1537 0.615234 7.99985C0.615234 6.38447 1.07677 4.99985 1.76908 3.846C2.46139 2.92293 3.38447 1.99985 4.30754 1.53831C5.46139 1.07677 6.61524 0.615234 7.76908 0.615234C12.1537 0.615234 15.3845 3.846 15.3845 7.99985Z"
        stroke="#F05545"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path d="M5 5L11 11" stroke="#F05545" strokeLinecap="round" />
      <path d="M11 5L5 11" stroke="#F05545" strokeLinecap="round" />
    </Fragment>
  ),
  viewBox: "0 0 16 16",
  fill: "none",
}
