/** @jsx jsx */
import { useLocation } from "@reach/router"
import { useEffect, useState } from "react"
import "url-search-params-polyfill"

import { Button, Container, IconButton } from "@trueskin-web/components"
import { useAuth } from "@trueskin-web/context"
import { bannerService } from "@trueskin-web/services"
import { jsx } from "@trueskin-web/theme"
import { closeIcon } from "@trueskin-web/theme/icons/closeIcon"
import { Routes } from "@trueskin-web/translations"

const Banner = ({ content, cta, fontColor, backgroundColor, onDismiss }) => (
  <div
    sx={{
      bg: backgroundColor ? backgroundColor : "notification",
      color: fontColor ? fontColor : "white",
      py: [2, 3],
    }}
  >
    <Container>
      <div
        sx={{
          display: "flex",
          alignItems: [null, "center"],
          justifyContent: "space-between",
        }}
      >
        <div>
          <span
            sx={{
              fontSize: 1,
            }}
          >
            {content}
          </span>
          {cta && (
            <Button
              variant="link"
              size="xsm"
              onClick={() => {
                Object.assign(document.createElement("a"), {
                  href: cta.targetUrl,
                }).click()
              }}
              sx={{
                p: 0,
                ml: 2,
                border: "none",
                "&:hover, &:focus": {
                  color: "white",
                  fontWeight: "bold",
                },
              }}
            >
              {cta.label}
            </Button>
          )}
        </div>

        {onDismiss && (
          <div sx={{ mt: [-1, 0], mr: [-3, 0] }}>
            <IconButton
              icon={closeIcon}
              onClick={onDismiss}
              sx={{
                color: "white",
                "&:hover, &:focus, &:active": {
                  color: "inherit",
                },
              }}
            />
          </div>
        )}
      </div>
    </Container>
  </div>
)

const TopBanner = ({
  active,
  bannedSlugs,
  linkCta,
  description,
  fontColor,
  backgroundColor,
}) => {
  const { user, status: userStatus } = useAuth()
  const { pathname } = useLocation()
  const [isVisible, setIsVisible] = useState(false)

  const dismissBanner = () => {
    bannerService.dismissBanner()

    setIsVisible(false)
  }

  useEffect(() => {
    if (
      user ||
      !active ||
      bannerService.isBannerDismissed() ||
      pathname.indexOf(Routes.App.url) === 0 ||
      bannedSlugs.filter(
        ({ description: slug }) => pathname.indexOf(slug) === 0
      ).length
    ) {
      setIsVisible(false)
      return
    }

    setIsVisible(true)
  }, [user, active, bannedSlugs, pathname])

  if (!isVisible || userStatus !== "success") {
    return null
  }

  return (
    <Banner
      content={description}
      cta={linkCta}
      fontColor={fontColor}
      backgroundColor={backgroundColor}
      onDismiss={dismissBanner}
    />
  )
}

export default TopBanner
