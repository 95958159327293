/** @jsx jsx */
import { Fragment } from "react"
import { jsx } from "theme-ui"

export const flaskIcon = {
  path: (
    <Fragment>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10.6826 16.6172C12.4516 17.3324 14.4283 17.6901 16.6127 17.6901C18.7971 17.6901 20.7929 17.3324 22.5999 16.6172C27.6911 14.0139 5.38986 13.74 10.6826 16.6172Z"
        stroke="currentColor"
      />
      <path
        d="M29.0183 26.7048L21.6168 12.3875C20.7361 10.684 20.2766 8.79431 20.2766 6.8767V4.17402C21.0602 4.17402 21.6974 3.53675 21.6974 2.75368C21.6974 1.97061 21.0602 1.33334 20.2771 1.33334H12.8466C12.0635 1.33334 11.4262 1.97061 11.4262 2.75368C11.4262 3.53675 12.0635 4.17402 12.8466 4.17402V6.87685C12.8466 8.79437 12.387 10.684 11.5065 12.3874C9.95151 15.3954 8.39653 18.4035 6.84154 21.4116C6.23349 22.5879 5.32141 24.3523 4.1053 26.7048C3.90652 27.0882 3.90704 27.513 4.10635 27.8403C4.29465 28.1503 9.07452 30.6667 17.0661 30.6667C25.0577 30.6667 28.8285 28.1503 29.0168 27.8409C29.2161 27.5136 29.2166 27.0887 29.0183 26.7048Z"
        stroke="currentColor"
      />
      <path d="M12.8467 4.17401H20.2772" stroke="currentColor" />
      <path
        d="M9.28282 22.2109C8.31086 24.0138 7.50353 25.4941 6.86084 26.6517M10.8955 19.2141C10.6043 19.7559 10.3288 20.2682 10.069 20.7512L10.8955 19.2141Z"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </Fragment>
  ),
  fill: "none",
  viewBox: "0 0 32 32",
}
