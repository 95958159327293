/** @jsx jsx */
import { Fragment } from "react"
import { jsx } from "theme-ui"

export const trashIcon = {
  path: (
    <Fragment>
      <path
        d="M16.1007 5.1611h.9328V15.992c0 1.2075-.7424 2.2311-1.9353 2.6112-.2792.0919-.5901.1348-.882.1348-2.8109.0123-5.6155.0123-8.4264.0061-1.561 0-2.8172-1.2013-2.8172-2.7092-.0064-3.5858 0-7.1715 0-10.7573V5.161h.9327v10.7267c0 .4965.1206.9439.4759 1.324.3934.429.8947.619 1.4784.619 2.7665 0 5.533.0062 8.2995 0 1.0089 0 1.7513-.6068 1.9162-1.5323.0254-.1471.0318-.2943.0318-.4414V5.3757c-.0064-.0552-.0064-.1288-.0064-.2146zM5.3014 2.8947c.0254-.5088.1713-.9685.4822-1.3607C6.2405.9638 6.8433.6451 7.5793.639 9.1846.6207 10.79.633 12.3953.633c1.0977 0 2.0939.8397 2.2589 1.8878.019.1165.0317.233.0444.3739h4.1497c.2665 0 .4125.092.495.3004.1015.2574-.0698.5455-.349.5884-.0698.0122-.146.0122-.2221.0122H1.2214c-.2284 0-.4378-.0245-.5457-.2513-.1522-.3003.0508-.6313.3998-.6497H5.3014zm8.4518-.0061c-.0127-.1042-.0191-.2023-.0445-.2881-.1776-.6804-.6916-1.0665-1.4403-1.0665a561.185 561.185 0 0 0-4.5432 0c-.4885 0-.9073.1654-1.2055.5577-.1777.233-.2729.4965-.2602.7969h7.4937z"
        fill="currentColor"
      />
      <path
        d="M10.4728 11.5113v3.549c0 .3004-.1713.4965-.4442.5149-.2284.0123-.4251-.1226-.4759-.3433a.7355.7355 0 0 1-.019-.1655V7.9501c0-.1962.0508-.3617.2475-.4597.2791-.1471.6091-.0123.6789.2942.019.0674.019.141.019.2145-.0063 1.1646-.0063 2.3354-.0063 3.5122zM13.7531 11.5048v2.1576c0 .2207-.0572.4168-.2919.5149-.2919.1226-.6219-.0674-.6472-.3739v-.0429-4.5114c0-.3003.2347-.4903.5457-.4535.184.0245.3426.1654.3743.3432.0127.0797.0191.1594.0191.2452v2.1208zM7.1862 11.5104V13.76c0 .2023-.1016.3494-.2983.4229-.1967.0797-.3743.0307-.5012-.1164-.0762-.092-.1333-.2391-.1333-.3617-.0063-1.4711-.0063-2.9422-.0063-4.4194 0-.282.146-.4597.3807-.4903.2411-.0368.4505.0735.5266.288.0254.0675.0254.1472.0318.2269v2.2004z"
        fill="currentColor"
      />
    </Fragment>
  ),
  viewBox: "0 0 20 19",
}
