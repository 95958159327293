/** @jsx jsx */
import { jsx } from "theme-ui"

export const bellIcon = {
  path: (
    <path
      d="M17.1705 10.118H16.6639C16.6639 8.97607 16.2103 7.88089 15.4028 7.07341C14.5953 6.26593 13.5001 5.81229 12.3582 5.81229V5.30573C13.6345 5.30573 14.8585 5.81274 15.761 6.71522C16.6635 7.6177 17.1705 8.84172 17.1705 10.118ZM20.7164 18.4332V19.0258C20.7157 19.2829 20.6133 19.5292 20.4315 19.711C20.2497 19.8928 20.0034 19.9952 19.7463 19.9959H15.6508C15.5787 20.816 15.2019 21.5794 14.5947 22.1355C13.9876 22.6916 13.1942 23 12.3709 23C11.5475 23 10.7541 22.6916 10.147 22.1355C9.53982 21.5794 9.16304 20.816 9.0909 19.9959H4.97006C4.71299 19.9952 4.46664 19.8928 4.28486 19.711C4.10309 19.5292 4.00067 19.2829 4 19.0258V18.4332C4.00067 18.1761 4.10309 17.9297 4.28486 17.748C4.46664 17.5662 4.71299 17.4638 4.97006 17.4631H5.51967V10.118C5.51846 8.34738 6.20407 6.64528 7.43226 5.36985C8.66046 4.09442 10.3355 3.34508 12.1049 3.27951V1H12.6115V3.27951C14.3809 3.34508 16.0559 4.09442 17.2841 5.36985C18.5123 6.64528 19.1979 8.34738 19.1967 10.118V17.4631H19.7463C20.0034 17.4638 20.2497 17.5662 20.4315 17.748C20.6133 17.9297 20.7157 18.1761 20.7164 18.4332ZM6.02623 17.4631H18.6902V10.118C18.6902 8.43868 18.023 6.82812 16.8356 5.64065C15.6481 4.45318 14.0375 3.78606 12.3582 3.78606C10.6788 3.78606 9.06829 4.45318 7.88082 5.64065C6.69334 6.82812 6.02623 8.43868 6.02623 10.118V17.4631ZM15.1443 19.9959H9.58479C9.65079 20.6857 9.97149 21.3262 10.4843 21.7923C10.9971 22.2584 11.6652 22.5166 12.3582 22.5166C13.0512 22.5166 13.7193 22.2584 14.2321 21.7923C14.7449 21.3262 15.0656 20.6857 15.1316 19.9959H15.1443ZM20.2098 18.4332C20.2098 18.3102 20.161 18.1923 20.0741 18.1054C19.9871 18.0185 19.8692 17.9697 19.7463 17.9697H4.97006C4.84713 17.9697 4.72924 18.0185 4.64231 18.1054C4.55539 18.1923 4.50656 18.3102 4.50656 18.4332V19.0258C4.50656 19.1488 4.55539 19.2666 4.64231 19.3536C4.72924 19.4405 4.84713 19.4893 4.97006 19.4893H19.7463C19.8692 19.4893 19.9871 19.4405 20.0741 19.3536C20.161 19.2666 20.2098 19.1488 20.2098 19.0258V18.4332Z"
      fill="currentColor"
    />
  ),
  viewBox: "0 0 24 24",
  fill: "none",
}
