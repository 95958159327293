/** @jsx jsx */
import { Fragment } from "react"
import { jsx } from "theme-ui"

export const subscriptionFilledIcon = {
  path: (
    <Fragment>
      <path
        d="M21.9627 4.48498C21.9559 4.47074 21.9461 4.46057 21.9373 4.44769C21.9159 4.41548 21.8912 4.387 21.8603 4.36225C21.8525 4.35581 21.8495 4.34665 21.8413 4.34123C21.8322 4.33546 21.8213 4.3358 21.8118 4.33038C21.7986 4.32258 21.7884 4.31105 21.7739 4.30495L11.1736 0.0249119C11.1224 0.00456884 11.0702 -0.00187222 11.019 0.000500754C10.9678 -0.00187257 10.9159 0.00389092 10.8654 0.0242338L0.229532 4.30427C0.214953 4.31003 0.204782 4.3219 0.19122 4.3297C0.181388 4.33512 0.1702 4.33445 0.161046 4.34055C0.152908 4.34597 0.149519 4.35547 0.14172 4.36191C0.111545 4.38632 0.0867953 4.41446 0.0657744 4.44599C0.0569595 4.45921 0.0464485 4.4704 0.0396681 4.48498C0.0159348 4.53346 0.00135596 4.58568 0.000678086 4.63993C0.000678086 4.64094 0.000339043 4.64128 0.000339043 4.6423L0 14.2687C0 14.4176 0.090527 14.5512 0.228514 14.6064L10.8644 18.8872C10.8688 18.8892 10.8735 18.8882 10.8776 18.8895C10.9176 18.9041 10.9583 18.9139 11 18.9139C11.0458 18.9139 11.0905 18.9024 11.1332 18.8848C11.139 18.8824 11.1455 18.8834 11.1512 18.8811L21.7871 14.0307C21.9169 13.9714 22 13.8415 22 13.6988V4.64344C22 4.64242 21.9997 4.64208 21.9997 4.64106C22.0007 4.58614 21.9861 4.53359 21.9624 4.4851L21.9627 4.48498ZM11.0014 8.53041L6.65718 6.78262L16.301 2.88197L20.6624 4.64295L11.0014 8.53041ZM11.019 0.748716L15.3279 2.4887L5.6824 6.3897L1.3416 4.64295L11.019 0.748716ZM0.729437 5.18307L10.6362 9.16957V18.0096L0.729437 14.0225V5.18307ZM21.2721 13.4642L11.3653 17.982L11.3656 9.16966L21.2724 5.18316L21.2721 13.4642Z"
        fill="currentColor"
      />
      <path
        d="M0.5 14.4931V4.51472C0.5 4.50766 0.507123 4.50283 0.513687 4.50543L11 8.6652V18.3549C11 18.4249 10.9299 18.4733 10.8644 18.4483L0.50644 14.5025C0.502562 14.501 0.5 14.4973 0.5 14.4931Z"
        fill="currentColor"
        stroke="white"
      />
      <path
        d="M0.775328 4.21806L10.9967 0.501191C10.9988 0.50042 11.0012 0.500388 11.0033 0.501102L21.2505 3.91683C21.3377 3.9459 21.3428 4.06739 21.2582 4.10361L11.0364 8.48441C11.013 8.49443 10.9867 8.49516 10.9628 8.48648L0.77533 4.78194C0.512027 4.68619 0.512026 4.31381 0.775328 4.21806Z"
        fill="currentColor"
        stroke="white"
      />
      <path
        d="M11 18.1962V8.49951L21.4861 4.00549C21.4927 4.00266 21.5 4.0075 21.5 4.01468V13.9929C21.5 13.9969 21.4976 14.0005 21.4939 14.0021L11.2788 18.38C11.1468 18.4366 11 18.3398 11 18.1962Z"
        fill="currentColor"
        stroke="white"
      />
      <path d="M5.5 6.5L16 2" stroke="white" />
    </Fragment>
  ),
  viewBox: "0 0 22 19",
}
