/** @jsx jsx */
import { Fragment } from "react"
import { jsx } from "theme-ui"

export const stethoscopeIcon = {
  path: (
    <Fragment>
      <path
        d="M14.3599 2.09773C14.3599 2.09773 18.7058 1.3992 17.5206 5.39084C16.3353 9.38248 14.6562 12.7754 11.693 12.7754C8.63108 12.7754 7.05072 9.28269 5.86546 5.39084C4.68019 1.49899 9.02617 2.09773 9.02617 2.09773"
        stroke="currentColor"
        strokeWidth="0.5"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M11.6929 12.7754C11.6929 12.7754 12.977 21.1578 8.2359 22.7545C3.98869 24.2514 2.40834 18.4635 5.96414 17.266C8.63098 16.3679 13.9647 16.1683 16.0389 18.8627"
        stroke="currentColor"
        strokeWidth="0.5"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M17.7182 22.455C18.9183 22.455 19.8911 21.4721 19.8911 20.2596C19.8911 19.0471 18.9183 18.0642 17.7182 18.0642C16.518 18.0642 15.5452 19.0471 15.5452 20.2596C15.5452 21.4721 16.518 22.455 17.7182 22.455Z"
        stroke="currentColor"
        strokeWidth="0.5"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M9.32251 1V3.09561"
        stroke="currentColor"
        strokeWidth="0.5"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M13.9646 1V3.09561"
        stroke="currentColor"
        strokeWidth="0.5"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M17.6194 19.162C17.6194 19.162 18.6071 19.0622 18.8047 20.1599"
        stroke="currentColor"
        strokeWidth="0.5"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </Fragment>
  ),
  viewBox: "0 0 24 24",
  fill: "none",
}
