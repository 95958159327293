/** @jsx jsx */
import { jsx } from "@trueskin-web/theme"

// Inspiration https://almonk.github.io/pylon/
const HStack = ({ spacing = 4, ...otherPros }) => (
  <div
    {...otherPros}
    sx={{
      display: "flex",
      alignItems: "center",
      "& > *:not(:last-of-type)": {
        mr: spacing,
      },
    }}
  />
)

export default HStack
