/** @jsx jsx */
import { Fragment } from "react"
import { jsx } from "theme-ui"

export const plusCircleIcon = {
  path: (
    <Fragment>
      <path
        d="M8.3297 13.8637h1.3407V9.6703h3.6799V8.4437h-3.68v-3.737H8.3298v3.737h-3.68v1.2266h3.68v4.1934z"
        fill="currentColor"
      />
      <path
        d="M9.0143 1.2551c-4.279 0-7.7306 3.4802-7.7306 7.7306 0 4.279 3.4802 7.7306 7.7306 7.7306 4.2789 0 7.7306-3.4802 7.7306-7.7306s-3.4802-7.7305-7.7306-7.7305zm0 16.7449C4.0507 18 0 13.9778 0 9.0143 0 4.0507 4.0507 0 9.0143 0 13.9778 0 18 4.0507 18 9.0143 18 13.9778 13.9778 18 9.0143 18z"
        fill="currentColor"
      />
    </Fragment>
  ),
  viewBox: "0 0 18 18",
}
