/** @jsx jsx */
import { Fragment } from "react"
import { jsx } from "theme-ui"

export const phoneIcon = {
  path: (
    <Fragment>
      <path
        d="M18.9153 17.9802V23.8901C18.9116 24.1833 18.7934 24.4634 18.5861 24.6708C18.3788 24.8781 18.0986 24.9963 17.8054 25H7.10992C6.81555 25 6.53323 24.8831 6.32509 24.6749C6.11694 24.4668 6 24.1845 6 23.8901V2.10991C6 1.81554 6.11694 1.53323 6.32509 1.32509C6.53323 1.11694 6.81555 1 7.10992 1H17.8054C18.0986 1.00374 18.3788 1.12187 18.5861 1.32922C18.7934 1.53656 18.9116 1.8167 18.9153 2.10991V17.9658V17.9802Z"
        stroke="currentColor"
        strokeMiterlimit="10"
      />
      <path
        d="M6 3.78192H18.9153"
        stroke="currentColor"
        strokeMiterlimit="10"
      />
      <path
        d="M6 21.8432H18.9153"
        stroke="currentColor"
        strokeMiterlimit="10"
      />
      <path
        d="M10.3101 23.2991H14.5911"
        stroke="currentColor"
        strokeMiterlimit="10"
      />
    </Fragment>
  ),
  viewBox: "0 0 24 26",
  fill: "none",
}
