/** @jsx jsx */
import { Fragment } from "react"

import { jsx } from "@trueskin-web/theme"
import { americanExpressIcon } from "@trueskin-web/theme/icons/americanExpressIcon"
import { applePayIcon } from "@trueskin-web/theme/icons/applePayIcon"
import { klarnaIcon } from "@trueskin-web/theme/icons/klarnaIcon"
import { mastercardIcon } from "@trueskin-web/theme/icons/mastercardIcon"
import { paypalIcon } from "@trueskin-web/theme/icons/paypalIcon"
import { visaIcon } from "@trueskin-web/theme/icons/visaIcon"

import BrandLogo from "./brand-logo"

const spaces = {
  xlg: 2,
  lg: 2,
  md: 1,
}

const PaymentLogos = ({ children, className, size, cardsOnly }) => (
  <div
    sx={{
      display: "flex",
      flexFlow: "wrap",
      gap: spaces[size],
    }}
    className={className}
  >
    {cardsOnly ? null : (
      <Fragment>
        {process.env.GATSBY_FEATURE_FLAG_KLARNA && (
          <BrandLogo icon={klarnaIcon} size={size} />
        )}
        {process.env.GATSBY_FEATURE_FLAG_APPLE_PAY && (
          <BrandLogo icon={applePayIcon} size={size} />
        )}
        <BrandLogo icon={paypalIcon} size={size} />
      </Fragment>
    )}
    <BrandLogo icon={visaIcon} size={size} />
    <BrandLogo icon={mastercardIcon} size={size} />
    <BrandLogo icon={americanExpressIcon} size={size} />
    {children}
  </div>
)

export default PaymentLogos
