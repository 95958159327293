/** @jsx jsx */
import { useEffect, useState } from "react"

import { useAuth } from "@trueskin-web/context"
import { getHomepageUrl } from "@trueskin-web/functions"
import { authService } from "@trueskin-web/services"
import { jsx } from "@trueskin-web/theme"

import Button, { variants } from "./button"
import Link from "./link"

const AccountLink = ({ url, className }) => {
  const { status, user, userIdentity: { firstName } = {} } = useAuth()
  const [displayName, setDisplayName] = useState(getName(firstName))
  const [href, setHref] = useState(url)

  function getName(value) {
    return value || authService.getFirstname()
  }

  useEffect(() => {
    setDisplayName(getName(firstName))
  }, [firstName])

  useEffect(() => {
    if (url) {
      setHref(url)
      return
    }

    if (status === "success") {
      setHref(getHomepageUrl(user))
    }
  }, [status, url, user])

  if (!displayName) {
    return null
  }

  return (
    <Button
      as={Link}
      variant="link"
      size="sm"
      className={className}
      href={href}
      partiallyActive
      activeClassName="active"
      sx={{
        display: "inline-flex",
        fontSize: 1,
        fontWeight: "bold",
        px: 0,
        py: 0,
        textDecoration: "none",
        "> div": {
          ...variants.solid,
          border: "none",
          position: "relative",
          ":before": {
            visibility: "hidden",
            content: `""`,
            position: "absolute",
            height: 2,
            left: "25%",
            right: "25%",
            bottom: "-6px",
            backgroundColor: "notification",
          },
        },
        "&:hover, &:focus, &.focus": {
          "> div": {
            ...variants.solid["&:hover, &:focus, &.focus"],
          },
        },
        "&.active": {
          overflow: "initial",
          "> div": {
            ...variants.solid["&:active, &.active"],
            ":before": {
              visibility: "visible",
            },
          },
        },
      }}
    >
      <span
        sx={{
          mx: 2,
        }}
      >
        {displayName}
      </span>
      <div
        sx={{
          position: "relative",
          width: 28,
          height: 28,
        }}
      >
        <span
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            fontSize: 0,
          }}
        >
          {displayName.trim().charAt(0).toUpperCase()}
        </span>
      </div>
    </Button>
  )
}

export default AccountLink
