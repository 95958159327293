/** @jsx jsx */
import { Fragment } from "react"
import { jsx } from "theme-ui"

export const userDrawingIcon = {
  path: (
    <Fragment>
      <path
        d="M15.8 3.93333V3.2H15.0299C14.8466 1.95333 13.7833 1 12.5 1C11.2166 1 10.1533 1.95333 9.96995 3.2H9.19995V3.93333H3.69995V23H21.3V3.93333H15.8ZM15.8 7.6V6.13333H19.1V20.8H5.89995V6.13333H9.19995V7.6H15.8ZM12.5 1.73333C13.4166 1.73333 14.1133 2.35667 14.2966 3.2H10.7033C10.8866 2.35667 11.5833 1.73333 12.5 1.73333ZM9.93328 3.93333H15.0666V6.86667H9.93328V3.93333ZM20.5666 22.2667H4.43328V4.66667H9.19995V5.4H5.16662V21.5333H19.8333V5.4H15.8V4.66667H20.5666V22.2667Z"
        fill="currentColor"
      />
      <path
        d="M8.10034 17.4334V21.4668H16.9003V17.4334C16.9003 16.4068 16.0937 15.6001 15.067 15.6001H9.93368C8.90701 15.6001 8.10034 16.4068 8.10034 17.4334ZM8.83368 17.4334C8.83368 16.8101 9.31034 16.3334 9.93368 16.3334H15.067C15.6903 16.3334 16.167 16.8101 16.167 17.4334V20.7334H8.83368V17.4334Z"
        fill="currentColor"
      />
      <path
        d="M12.4996 15.1002C13.7096 15.1002 14.6996 14.1102 14.6996 12.9002C14.6996 11.6902 13.7096 10.7002 12.4996 10.7002C11.2896 10.7002 10.2996 11.6902 10.2996 12.9002C10.2996 14.1102 11.2896 15.1002 12.4996 15.1002ZM12.4996 11.4335C13.3062 11.4335 13.9662 12.0935 13.9662 12.9002C13.9662 13.7069 13.3062 14.3669 12.4996 14.3669C11.6929 14.3669 11.0329 13.7069 11.0329 12.9002C11.0329 12.0935 11.6929 11.4335 12.4996 11.4335Z"
        fill="currentColor"
      />
    </Fragment>
  ),
  viewBox: "0 0 24 24",
  fill: "none",
}
