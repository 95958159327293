/** @jsx jsx */
import { useLocation } from "@reach/router"

import { jsx } from "@trueskin-web/theme"

const healthEcHostname = "formelskin.claras-apotheke.de"
const healthEcUrl =
  "https://versandhandel.dimdi.de/websearch/servlet/Gate?accessid=dimdi_var&term=da46557df5f8553fdb233941f6250f0c"

const HealthECBadge = ({ logo }) => {
  const location = useLocation()

  const isHealthEC = location.hostname === healthEcHostname

  if (!isHealthEC) {
    return null
  }

  return <a href={healthEcUrl}>{logo}</a>
}

export default HealthECBadge
