/** @jsx jsx */
import { jsx } from "@trueskin-web/theme"

const DashList = (props) => (
  <ul
    sx={{
      display: "grid",
      gap: 3,
      my: 0,
      pl: 0,
      listStyleType: "none",
    }}
    {...props}
  />
)

export const DashListItem = (props) => (
  <li
    sx={{
      display: "flex",
      fontFamily: "monospace",
      "&:before": {
        content: "'-'",
        mr: 3,
      },
    }}
    {...props}
  />
)

export default DashList
