import { useLocation } from "@reach/router"
import { useEffect } from "react"
import { toast } from "react-toastify"

import { getCountries, saveCountry } from "@trueskin-web/locales"

const localeParam = "funnel_c"
const availableCountries = getCountries()

const UrlLocaleSwitcher = () => {
  const { search } = useLocation()

  const searchParams = new URLSearchParams(search)
  const urlLocale = searchParams.get(localeParam) || ""
  const country = availableCountries.find(
    ({ code }) => code === urlLocale.toUpperCase()
  )

  useEffect(() => {
    if (country) {
      saveCountry(country.code)
      toast(`Your country has been changed to ${country.label}`)
    }
  }, [country])

  return null
}

export default UrlLocaleSwitcher
