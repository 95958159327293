/** @jsx jsx */
import { Fragment } from "react"
import { jsx } from "theme-ui"

export const packageIcon = {
  path: (
    <Fragment>
      <g clipPath="url(#clip0_813:8813)">
        <path
          d="M3.19995 17.6001V6.6001L12.2 11.7001V22.7001L3.19995 17.6001Z"
          stroke="currentColor"
          strokeWidth="0.5"
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M12.2 22.7001V11.7001L21.2 6.6001V17.6001L12.2 22.7001Z"
          stroke="currentColor"
          strokeWidth="0.5"
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M3.19995 6.6002L12.2 1.2002L21.2 6.6002"
          stroke="currentColor"
          strokeWidth="0.5"
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M6.72559 4.71445L15.426 9.91465V13.5146L18.526 11.6146V8.01465L9.5 3"
          stroke="currentColor"
          strokeWidth="0.5"
          strokeMiterlimit="10"
          strokeLinecap="square"
          strokeLinejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_813:8813">
          <rect
            width="18.3"
            height="21.9"
            fill="white"
            transform="translate(3 1)"
          />
        </clipPath>
      </defs>
    </Fragment>
  ),
  viewBox: "0 0 24 24",
  fill: "none",
}
