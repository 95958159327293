/** @jsx jsx */
import { Fragment } from "react"
import { jsx } from "theme-ui"

export const faceSideEffectIcon = {
  path: (
    <Fragment>
      <path
        d="M16.7803 9.04613V9.04613C17.2712 8.55518 18.0405 8.47901 18.6182 8.86414L18.8912 9.04613"
        stroke="currentColor"
        strokeWidth="0.5"
      />
      <path
        d="M17.0336 10.7348V10.7348C17.5118 10.2566 18.2757 10.222 18.7953 10.6549L18.8912 10.7348"
        stroke="currentColor"
        strokeWidth="0.5"
      />
      <path
        d="M16.3581 5.66874C17.0389 3.62654 19.4476 4.24748 18.8912 3.1357C17.291 -0.0613822 12.2673 -0.167193 8.93267 1.12193C8.4533 1.30725 7.9704 1.55066 7.49249 1.86916C5.44343 3.2347 4.1151 6.47115 4.1151 8.62382C4.1151 12.8457 5.38162 13.6899 7.49249 14.9564C9.18118 15.9696 11.0106 13.6899 11.7142 12.4234C11.4328 12.0012 11.292 11.4103 11.292 10.7348C11.292 9.89047 12.1364 9.4683 12.5586 10.3126C12.9807 11.157 15.5138 8.20178 16.3581 5.66874Z"
        stroke="currentColor"
        strokeWidth="0.5"
      />
      <circle cx="15.9359" cy="14.1122" r="0.422173" fill="currentColor" />
      <circle cx="17.6246" cy="13.2679" r="0.422173" fill="currentColor" />
      <circle cx="17.6246" cy="14.9566" r="0.422173" fill="currentColor" />
      <path
        d="M18.8912 3.55788C19.4541 4.12077 20.1577 5.66874 20.1577 7.35743C20.1577 9.4683 19.6763 9.29076 20.1577 10.7348C20.5799 12.0013 22.2685 12.8457 21.002 13.2679C19.7355 13.69 20.1577 14.1122 20.5799 14.5344C20.8967 14.8512 19.7355 14.9565 19.7355 14.9565C20.5799 15.3787 20.5616 15.5658 20.3265 15.8009C20.0915 16.036 20.1577 16.6452 20.1577 17.0674C20.1577 17.4896 20.1577 18.7561 16.7803 18.3339C14.0784 17.9962 14.6694 20.867 14.6695 22.5557"
        stroke="currentColor"
        strokeWidth="0.5"
      />
      <path
        d="M7.07031 22.1335C7.86612 20.4117 9.27868 16.6502 8.56245 15.3787"
        stroke="currentColor"
        strokeWidth="0.5"
      />
      <path
        d="M4.11512 9.04612C3.27078 8.9054 1.24433 8.03291 1.58206 5.66874C1.70438 4.8125 1.99652 4.13347 2.34617 3.6156C3.18654 2.3709 4.83155 2.57792 6.22597 3.1357V3.1357"
        stroke="currentColor"
        strokeWidth="0.5"
      />
      <path
        d="M11.7142 12.4235C12.1364 12.8457 12.8119 13.6056 13.8251 13.2679"
        stroke="currentColor"
        strokeWidth="0.5"
      />
    </Fragment>
  ),
  viewBox: "0 0 24 24",
  fill: "none",
}
