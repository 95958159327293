/** @jsx jsx */
import { Link as GatsbyLink } from "gatsby"

import { usePageContext } from "@trueskin-web/context"
import { jsx } from "@trueskin-web/theme"

// https://www.gatsbyjs.org/docs/gatsby-link/#reminder-use-link-only-for-internal-links

const Link = ({
  children,
  href,
  activeClassName,
  partiallyActive,
  activeStyle,
  newTab,
  ...otherProps
}) => {
  const pageContext = usePageContext()
  const isInternalLink = /^\/(?!\/)/.test(href) && !newTab

  if (pageContext?.isExternal) {
    return (
      <a href={href} {...otherProps}>
        {children}
      </a>
    )
  }

  if (isInternalLink) {
    return (
      <GatsbyLink
        to={href}
        activeClassName={activeClassName}
        partiallyActive={partiallyActive}
        activeStyle={activeStyle}
        {...otherProps}
      >
        {children}
      </GatsbyLink>
    )
  }

  return (
    <a href={href} {...otherProps}>
      {children}
    </a>
  )
}

export default Link
