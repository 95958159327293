/** @jsx jsx */
import { Fragment } from "react"
import { jsx } from "theme-ui"

export const sunIcon = {
  path: (
    <Fragment>
      <path
        d="M12.5706 2.85714V0.571429C12.5706 0.257143 12.3134 0 11.9992 0C11.6849 0 11.4277 0.257143 11.4277 0.571429V2.85714C11.4277 3.17143 11.6849 3.42857 11.9992 3.42857C12.3134 3.42857 12.5706 3.17143 12.5706 2.85714Z"
        fill="currentColor"
      />
      <path
        d="M11.9992 20.5714C11.6849 20.5714 11.4277 20.8286 11.4277 21.1428V23.4286C11.4277 23.7428 11.6849 24 11.9992 24C12.3134 24 12.5706 23.7428 12.5706 23.4286V21.1428C12.5706 20.8286 12.3134 20.5714 11.9992 20.5714Z"
        fill="currentColor"
      />
      <path
        d="M5.13209 5.93705C5.24637 6.05133 5.38923 6.10276 5.5378 6.10276C5.68637 6.10276 5.82923 6.04562 5.94352 5.93705C6.16637 5.71419 6.16637 5.35419 5.94352 5.13133L4.32637 3.51419C4.10352 3.29133 3.74352 3.29133 3.52066 3.51419C3.2978 3.73705 3.2978 4.09705 3.52066 4.3199L5.13209 5.93705Z"
        fill="currentColor"
      />
      <path
        d="M18.8693 18.0628C18.6465 17.8399 18.2865 17.8399 18.0636 18.0628C17.8408 18.2856 17.8408 18.6456 18.0636 18.8685L19.6808 20.4856C19.7951 20.5999 19.9379 20.6513 20.0865 20.6513C20.2351 20.6513 20.3779 20.5942 20.4922 20.4856C20.7151 20.2628 20.7151 19.9028 20.4922 19.6799L18.8693 18.0628Z"
        fill="currentColor"
      />
      <path
        d="M2.85714 11.4285H0.571429C0.257143 11.4285 0 11.6856 0 11.9999C0 12.3142 0.257143 12.5713 0.571429 12.5713H2.85714C3.17143 12.5713 3.42857 12.3142 3.42857 11.9999C3.42857 11.6856 3.17143 11.4285 2.85714 11.4285Z"
        fill="currentColor"
      />
      <path
        d="M23.4294 11.4285H21.1437C20.8294 11.4285 20.5723 11.6856 20.5723 11.9999C20.5723 12.3142 20.8294 12.5713 21.1437 12.5713H23.4294C23.7437 12.5713 24.0008 12.3142 24.0008 11.9999C24.0008 11.6856 23.7437 11.4285 23.4294 11.4285Z"
        fill="currentColor"
      />
      <path
        d="M5.13194 18.0628L3.5148 19.6799C3.29194 19.9028 3.29194 20.2628 3.5148 20.4856C3.62908 20.5999 3.77194 20.6513 3.92051 20.6513C4.06908 20.6513 4.21194 20.5942 4.32623 20.4856L5.94337 18.8685C6.16623 18.6456 6.16623 18.2856 5.94337 18.0628C5.72051 17.8399 5.3548 17.8399 5.13194 18.0628Z"
        fill="currentColor"
      />
      <path
        d="M18.8693 5.93705L20.4865 4.3199C20.7093 4.09705 20.7093 3.73705 20.4865 3.51419C20.2636 3.29133 19.9036 3.29133 19.6808 3.51419L18.0636 5.13133C17.8408 5.35419 17.8408 5.71419 18.0636 5.93705C18.1779 6.05133 18.3208 6.10276 18.4693 6.10276C18.6179 6.10276 18.7608 6.05133 18.8693 5.93705Z"
        fill="currentColor"
      />
      <path
        d="M11.9997 5.14294C8.21686 5.14294 5.14258 8.21723 5.14258 12.0001C5.14258 15.7829 8.21686 18.8572 11.9997 18.8572C15.7826 18.8572 18.8569 15.7829 18.8569 12.0001C18.8569 8.21723 15.7826 5.14294 11.9997 5.14294ZM11.9997 17.7144C8.85115 17.7144 6.28544 15.1487 6.28544 12.0001C6.28544 8.85152 8.85115 6.2858 11.9997 6.2858C15.1483 6.2858 17.714 8.85152 17.714 12.0001C17.714 15.1487 15.1483 17.7144 11.9997 17.7144Z"
        fill="currentColor"
      />
    </Fragment>
  ),
  viewBox: "0 0 24 24",
}
