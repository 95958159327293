/** @jsx jsx */
import { Fragment } from "react"

import {
  Button,
  IconButton,
  buttonSizes,
  buttonVariants,
} from "@trueskin-web/components"
import { jsx } from "@trueskin-web/theme"
import { checkIcon } from "@trueskin-web/theme/icons/checkIcon"

import Styleguide from "./styleguide"

const variants = Object.keys(buttonVariants)
const sizes = Object.keys(buttonSizes)
const colors = ["primary", "black", "white"]

const Demo = ({ variant, color }) => (
  <div
    sx={{
      display: "inline-grid",
      gridTemplateColumns: "repeat(7, auto)",
      gridGap: 2,
      p: 2,
      bg: color === "white" ? "black" : "background",
    }}
  >
    {sizes.map((size) => (
      <Fragment key={size}>
        <div
          sx={{
            alignSelf: "center",
            ml: "auto",
          }}
        >
          {size}
        </div>

        <div
          sx={{
            alignSelf: "center",
            ml: "auto",
          }}
        >
          <IconButton
            icon={checkIcon}
            iconSize={size}
            variant={variant}
            color={color}
          />
        </div>

        <div>
          <Button size={size} variant={variant} color={color}>
            normal
          </Button>
        </div>

        <div>
          <Button size={size} variant={variant} color={color} className="focus">
            focus
          </Button>
        </div>

        <div>
          <Button
            size={size}
            variant={variant}
            color={color}
            className="active"
          >
            active
          </Button>
        </div>

        <div>
          <Button size={size} variant={variant} color={color} isDisabled>
            disabled
          </Button>
        </div>

        <div>
          <Button size={size} variant={variant} color={color} isLoading>
            loading
          </Button>
        </div>
      </Fragment>
    ))}
  </div>
)

const createVariantComponent = (variant, color) => ({
  name: color || variant,
  component: () =>
    variant === "outline" && !color ? (
      <Styleguide
        items={colors.map((color) => createVariantComponent(variant, color))}
      />
    ) : (
      <Demo variant={variant} color={color} />
    ),
  type: color ? "type" : "section",
})

const components = variants.map((variant) => createVariantComponent(variant))

const Buttons = () => <Styleguide items={components} />

export default Buttons
