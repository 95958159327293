/** @jsx jsx */
import { Fragment } from "react"
import { jsx } from "theme-ui"

export const sentimentVerySadIcon = {
  path: (
    <Fragment>
      <path
        d="M7.7002 18C8.5002 16.8 9.6202 15 12.5002 15C15.3802 15 16.5002 16.8 17.3002 18"
        stroke="currentColor"
        strokeWidth="1.8"
      />
      <circle
        cx="12.5"
        cy="12"
        r="11.1"
        stroke="currentColor"
        strokeWidth="1.8"
      />
      <circle cx="9.2002" cy="9.90039" r="1.5" fill="currentColor" />
      <circle
        cx="1.5"
        cy="1.5"
        r="1.5"
        transform="matrix(-1 0 0 1 17.417 8.40039)"
        fill="currentColor"
      />
      <rect
        x="8.2998"
        y="4.7998"
        width="3.6"
        height="1.8"
        transform="rotate(30 8.2998 4.7998)"
        fill="currentColor"
      />
      <rect
        width="3.6"
        height="1.8"
        transform="matrix(-0.866025 0.5 0.5 0.866025 16.8174 4.7998)"
        fill="currentColor"
      />
    </Fragment>
  ),
  viewBox: "0 0 25 24",
  fill: "none",
}
