/** @jsx jsx */
import { Fragment } from "react"
import { jsx } from "theme-ui"

export const creditCardIcon = {
  path: (
    <Fragment>
      <path
        d="M18.9017 15.6319H3.26605C2.35228 15.6319 1.54004 14.6863 1.54004 13.6226V4.16743C1.54004 3.10372 2.35228 2.1582 3.26605 2.1582H18.8002C19.8155 2.1582 20.6277 3.10372 20.6277 4.16743V13.5044C20.6277 14.6863 19.8155 15.6319 18.9017 15.6319Z"
        stroke="currentColor"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M21.1376 3.90332H20.6376V4.90332H21.1376V3.90332ZM4.28516 15.9692V15.4692H3.28516V15.9692H4.28516ZM21.1376 4.90332C21.5719 4.90332 21.8584 5.07177 22.0493 5.31956C22.2524 5.58319 22.3728 5.9735 22.3728 6.43033H23.3728C23.3728 5.81404 23.2125 5.19085 22.8415 4.70924C22.4582 4.21182 21.8771 3.90332 21.1376 3.90332V4.90332ZM22.3728 6.43033V15.85H23.3728V6.43033H22.3728ZM22.3728 15.85C22.3728 16.2619 22.2149 16.6545 21.9699 16.9407C21.7233 17.2287 21.4194 17.377 21.1376 17.377V18.377C21.7744 18.377 22.3381 18.0483 22.7295 17.591C23.1225 17.132 23.3728 16.5111 23.3728 15.85H22.3728ZM21.1376 17.377H5.41833V18.377H21.1376V17.377ZM5.41833 17.377C4.84588 17.377 4.28516 16.8419 4.28516 15.9692H3.28516C3.28516 17.2427 4.15345 18.377 5.41833 18.377V17.377Z"
        fill="currentColor"
      />
      <rect
        x="1.54004"
        y="5.52637"
        width="19.0877"
        height="2.24561"
        fill="currentColor"
      />
    </Fragment>
  ),
  viewBox: "0 0 24 20",
  fill: "none",
}
