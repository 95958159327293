/** @jsx jsx */
import { Fragment } from "react"
import { jsx } from "theme-ui"

export const sentimentHappyIcon = {
  path: (
    <Fragment>
      <circle
        cx="12.5"
        cy="12"
        r="11.1"
        stroke="currentColor"
        strokeWidth="1.8"
      />
      <path
        d="M17.2998 15C16.4998 16.2 15.3798 18 12.4998 18C9.6198 18 8.4998 16.2 7.6998 15"
        stroke="currentColor"
        strokeWidth="1.8"
      />
      <circle cx="9.2002" cy="9.90039" r="1.5" fill="currentColor" />
      <circle
        cx="1.5"
        cy="1.5"
        r="1.5"
        transform="matrix(-1 0 0 1 17.417 8.40039)"
        fill="currentColor"
      />
    </Fragment>
  ),
  viewBox: "0 0 25 24",
  fill: "none",
}
