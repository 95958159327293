/** @jsx jsx */
import { useId } from "@reach/auto-id"
import { forwardRef } from "react"

import { jsx } from "@trueskin-web/theme"

import FormControl from "./form-control"
import FormErrorMessage from "./form-error-message"
import FormLabel from "./form-label"
import Textarea from "./textarea"

const TextareaControl = forwardRef(
  ({ input, meta, label, required, className, ...props }, ref) => {
    const id = useId()

    return (
      <FormControl
        isInvalid={(meta.error || meta.submitError) && meta.touched}
        isRequired={required}
        className={className}
      >
        {label && <FormLabel htmlFor={id}>{label}</FormLabel>}
        <Textarea id={id} ref={ref} {...input} {...props} />
        <FormErrorMessage>{meta.error || meta.submitError}</FormErrorMessage>
      </FormControl>
    )
  }
)

export default TextareaControl
