/** @jsx jsx */
import { jsx } from "theme-ui"

export const triangleUpIcon = {
  path: (
    <path
      d="M4.83782 0.224719C4.91765 0.114107 5.08235 0.114107 5.16218 0.224719L9.10132 5.68296C9.19678 5.81523 9.10227 6 8.93915 6L1.06085 6C0.897733 6 0.803218 5.81523 0.898678 5.68296L4.83782 0.224719Z"
      fill="currentColor"
    />
  ),
  viewBox: "0 0 10 6",
  fill: "none",
}
