/** @jsx jsx */
import { useId } from "@reach/auto-id"

import { jsx } from "@trueskin-web/theme"

import FormControl from "./form-control"
import FormErrorMessage from "./form-error-message"
import FormLabel from "./form-label"
import Select from "./select"

const SelectControl = ({
  input,
  meta,
  label,
  required,
  className,
  ...props
}) => {
  const id = useId()

  return (
    <FormControl
      isInvalid={
        (meta.error || (meta.submitError && !meta.dirtySinceLastSubmit)) &&
        meta.touched
      }
      isRequired={required}
      className={className}
    >
      {label && <FormLabel htmlFor={id}>{label}</FormLabel>}
      <Select id={id} {...input} {...props} />
      <FormErrorMessage>{meta.error || meta.submitError}</FormErrorMessage>
    </FormControl>
  )
}

export default SelectControl
