/** @jsx jsx */
import { jsx } from "@trueskin-web/theme"

const ALink = ({ isTextColor, isUnderlined, inheritColor, ...otherProps }) => (
  // eslint-disable-next-line jsx-a11y/anchor-has-content
  <a
    sx={{
      color: inheritColor ? "inherit" : isTextColor ? "text" : "primary",
      textDecoration: isUnderlined ? "underline" : "none",
      "&:hover, &:focus": {
        color: inheritColor ? "inherit" : "primaryLight",
      },
    }}
    {...otherProps}
  />
)

export default ALink
