/** @jsx jsx */
import { useState } from "react"

import { jsx } from "@trueskin-web/theme"
import { arrowRightIcon } from "@trueskin-web/theme/icons/arrowRightIcon"
import i18next, { useTranslation } from "@trueskin-web/translations"

import Button from "./button"
import Icon from "./icon"

const FakeSelect = ({
  size = "lg",
  placeholder = i18next.t("FakeSelect.label"),
  color = "primary",
  variant = "outline",
  className,
  iconProps,
  ...otherProps
}) => {
  const [currentOption, setCurrentOption] = useState(placeholder)
  const { t } = useTranslation()
  return (
    <div
      className={className}
      sx={{
        position: "relative",
        display: [null, "flex"],
        color,
      }}
    >
      <Button
        sx={{
          width: "100%",
          px: [null, 4, 5],
          py: [2, 3],
          pr: [null, 8, 8],
          bg: "background",
        }}
        variant={variant}
        {...otherProps}
      >
        {currentOption}
      </Button>

      <div
        sx={{
          position: "absolute",
          right: 3,
          top: "50%",
          transform: "translateY(-50%)",
          pointerEvents: "none",
        }}
      >
        <Icon
          icon={arrowRightIcon}
          size={size}
          sx={{ transform: "rotate(90deg)", color: "inherit" }}
          {...iconProps}
        />
      </div>

      <select
        defaultValue={placeholder}
        onChange={(ev) =>
          setCurrentOption(ev.target.options[ev.target.selectedIndex].text)
        }
        sx={{
          position: "absolute",
          top: 0,
          left: 0,
          right: 0,
          bottom: 0,
          width: "100%",
          height: "100%",
          outline: "none",
          opacity: 0,
          "&:focus": {
            outline: "none",
          },
        }}
      >
        <option value={placeholder} disabled>
          {placeholder}
        </option>
        <option value="Akne">{t("FakeSelect.acne")}</option>
        <option value="SlowAging">{t("FakeSelect.slowAging")}</option>
        <option value="Pickel">{t("FakeSelect.pimple")}</option>
        <option value="Unreinheiten">{t("FakeSelect.imperfections")}</option>
        <option value="Flecken">{t("FakeSelect.stains")}</option>
        <option value="Rosacea">{t("FakeSelect.rosacea")}</option>
        <option value="Andere">{t("FakeSelect.other")}</option>
      </select>
    </div>
  )
}

export default FakeSelect
