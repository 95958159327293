/** @jsx jsx */
import { Fragment } from "react"
import { jsx } from "theme-ui"

export const truckIcon = {
  path: (
    <Fragment>
      <path
        d="M11.6667 17H17M17 17V1H1V17H5M17 17V6.33333H23.6667L26.1067 8.77333C26.6018 9.269 26.9944 9.85736 27.2621 10.5048C27.5297 11.1522 27.6672 11.8461 27.6667 12.5467V17H25M17 17H18.3333"
        stroke="currentColor"
        strokeWidth="0.7"
        strokeLinecap="square"
      />
      <path
        d="M8.33333 20.9997C10.1743 20.9997 11.6667 19.5073 11.6667 17.6663C11.6667 15.8254 10.1743 14.333 8.33333 14.333C6.49238 14.333 5 15.8254 5 17.6663C5 19.5073 6.49238 20.9997 8.33333 20.9997Z"
        stroke="currentColor"
        strokeWidth="0.7"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M21.6668 20.9997C23.5078 20.9997 25.0002 19.5073 25.0002 17.6663C25.0002 15.8254 23.5078 14.333 21.6668 14.333C19.8259 14.333 18.3335 15.8254 18.3335 17.6663C18.3335 19.5073 19.8259 20.9997 21.6668 20.9997Z"
        stroke="currentColor"
        strokeWidth="0.7"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </Fragment>
  ),
  viewBox: "0 0 29 22",
  fill: "none",
}
