/** @jsx jsx */
import { Fragment } from "react"
import { jsx } from "theme-ui"

export const prizeIcon = {
  path: (
    <Fragment>
      <g clipPath="url(#clip0_2510_7793)">
        <path
          d="M10.9282 27.9793H20.9758V31.5001H10.9282V27.9793Z"
          stroke="currentColor"
        />
        <path d="M15.9526 28.2974V19.8215" stroke="currentColor" />
        <path
          d="M24.7142 3.02997H7.38086L7.42257 5.73722C7.51479 11.723 10.7568 17.2166 15.9523 20.1907C21.3549 17.2324 24.7142 11.5632 24.7142 5.40366V3.02997Z"
          stroke="currentColor"
        />
        <path
          d="M7.38095 4.50629H3.57143L3.49557 5.43708C3.21413 8.89058 5.59256 11.9965 9 12.6252V12.6252"
          stroke="currentColor"
        />
        <path
          d="M25.0953 4.50629H28.9048L28.9121 5.02912C28.9643 8.77639 26.5663 12.1195 23 13.2711V13.2711"
          stroke="currentColor"
        />
      </g>
      <defs>
        <clipPath id="clip0_2510_7793">
          <rect width="32" height="32" fill="white" />
        </clipPath>
      </defs>
    </Fragment>
  ),
  viewBox: "0 0 32 32",
  fill: "none",
}
