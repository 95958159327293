/** @jsx jsx */
import { Fragment } from "react"
import { jsx } from "theme-ui"

export const medicalConsultationIcon = {
  path: (
    <Fragment>
      <g clipPath="url(#clip0_891_3197)">
        <rect
          x="1.25781"
          y="4.3091"
          width="22.0208"
          height="25.6909"
          stroke="currentColor"
          strokeLinejoin="round"
        />
        <rect
          x="3.70459"
          y="6.75587"
          width="17.1273"
          height="20.7974"
          stroke="currentColor"
          strokeLinejoin="round"
        />
        <path
          d="M6.15137 23.2129C6.15137 21.5561 7.49451 20.2129 9.15137 20.2129H15.3852C17.042 20.2129 18.3852 21.5561 18.3852 23.2129V27.5532H6.15137V23.2129Z"
          stroke="currentColor"
        />
        <path
          d="M14.7148 3.08567C14.7148 2.43675 14.457 1.81441 13.9982 1.35555C13.5393 0.896699 12.917 0.638916 12.268 0.638916C11.6191 0.638916 10.9968 0.896698 10.5379 1.35555C10.0791 1.81441 9.82129 2.43675 9.82129 3.08567L12.268 3.08567H14.7148Z"
          stroke="currentColor"
        />
        <rect
          x="8.59814"
          y="3.08571"
          width="7.34027"
          height="4.89351"
          fill="white"
          stroke="currentColor"
          strokeLinejoin="round"
        />
        <circle cx="12.2683" cy="15.3193" r="3.17014" stroke="currentColor" />
      </g>
      <defs>
        <clipPath id="clip0_891_3197">
          <rect width="24" height="24" fill="white" />
        </clipPath>
      </defs>
    </Fragment>
  ),
  fill: "none",
  viewBox: "0 0 24 24",
}
