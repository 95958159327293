/** @jsx jsx */
import { Fragment } from "react"
import { jsx } from "theme-ui"

export const mixingDropsIcon = {
  path: (
    <Fragment>
      <path
        d="M22.3477 41.4029C22.3477 41.3791 22.3555 41.3556 22.3704 41.3345C22.3854 41.3135 22.407 41.2956 22.4334 41.2823C22.4598 41.2691 22.6252 41.1653 22.6567 41.163C22.6883 41.1607 23.0248 40.8786 23.7608 40.5943C26.3371 39.7412 26.3719 39.8699 28.5453 39.7412C30.7192 39.8064 33.3297 40.0256 34.9996 41.1688C35.0295 41.157 35.0629 41.1514 35.0966 41.1525C35.1303 41.1535 35.163 41.1612 35.1915 41.1747C35.22 41.1883 35.2432 41.2072 35.259 41.2297C35.2747 41.2521 35.2823 41.2772 35.2811 41.3026L35.5329 70.4738C35.5414 70.5929 35.5074 70.7115 35.4344 70.8174C35.3615 70.9232 35.2523 71.0125 35.1181 71.0759C33.2802 71.8531 31.3281 72.1132 29.0884 72.191C26.9878 72.264 24.8997 71.9096 23.044 71.1651C22.9092 71.1014 22.7987 71.0124 22.7234 70.9068C22.648 70.8013 22.6105 70.6828 22.6143 70.563L22.3477 41.4029Z"
        fill="white"
      />
      <path
        d="M31.2004 55.7334V62.788C30.0389 63.3495 28.7197 63.6027 27.4004 63.5171C26.0182 63.5878 24.6391 63.3364 23.4004 62.788V55.7728C24.6719 56.0936 25.9898 56.2463 27.3115 56.226C28.629 56.2454 29.9417 56.0792 31.2004 55.7334Z"
        fill="#008878"
      />
      <path
        d="M20.8008 40.5803C20.8044 40.5468 20.8154 40.5146 20.8331 40.4863C20.8507 40.4579 20.8744 40.4341 20.9024 40.4168C20.9303 40.3995 20.9618 40.389 20.9943 40.3863C21.0268 40.3836 21.0595 40.3886 21.0898 40.4011C22.8962 41.6048 25.0367 42.1578 27.1787 41.9743C29.4195 42.0715 31.6383 41.4826 33.5565 40.2816C33.5863 40.2638 33.6202 40.2546 33.6546 40.2549C33.6889 40.2552 33.7226 40.265 33.7521 40.2833C33.7816 40.3016 33.8058 40.3277 33.8223 40.3589C33.8387 40.3901 33.8467 40.4253 33.8456 40.4609L34.0768 67.3044C34.0786 67.4692 34.0408 67.6319 33.9669 67.7778C33.8929 67.9238 33.785 68.0485 33.6529 68.1408C31.7713 69.2232 29.6391 69.7535 27.487 69.6741C25.3357 69.7794 23.1974 69.2769 21.3018 68.2204C21.162 68.1301 21.0475 68.0035 20.9698 67.8533C20.892 67.7031 20.8537 67.5344 20.8586 67.3641L20.8008 40.5803Z"
        stroke="currentColor"
      />
      <path
        d="M20.8008 48.9639C22.6591 50.11 24.8058 50.6577 26.9667 50.537C29.2965 50.6275 31.6085 50.0851 33.6722 48.9639"
        stroke="currentColor"
      />
      <path
        d="M20.8008 40.2414C22.6141 38.9642 24.7531 38.2712 26.9474 38.25C29.3124 38.2553 31.6286 38.9452 33.6336 40.2414"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M57.6576 41.6539C57.6576 44.1545 55.8332 46.1816 53.5827 46.1816C51.3322 46.1816 49.5078 44.1545 49.5078 41.6539C49.5078 39.1534 51.1378 35.5093 53.5827 32.5986C56.0277 35.5093 57.6576 39.1534 57.6576 41.6539Z"
        fill="#89ABF2"
      />
      <path
        d="M53.1009 36.0926L50.0422 29.4951L47.557 36.0926C47.3658 36.4806 47.3658 36.8687 47.3658 37.2568C47.1747 39.0032 48.704 40.1675 50.2334 40.1675C51.9539 40.1675 53.2921 38.8092 53.2921 37.2568C53.2921 36.8687 53.1009 36.4806 53.1009 36.0926Z"
        stroke="currentColor"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M66.8652 5.23965L69.1592 2.52305C69.7328 1.74688 70.8798 1.74688 71.4533 2.32901C72.2179 2.91114 72.2179 4.07539 71.6444 4.65752L69.3504 7.37412"
        stroke="currentColor"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M60.9408 11.6436L51.0001 23.2861C50.4266 23.8682 50.4266 24.6444 50.6177 25.4206L49.4707 26.7789C49.0884 27.167 49.2796 27.555 49.4707 27.9431H49.6619C50.0442 28.3312 50.4266 28.1372 50.8089 27.9431L51.9559 26.5848C52.7206 26.7789 53.4853 26.5848 54.0588 26.0027L64.1907 14.1661"
        stroke="currentColor"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M61.1289 6.98609L63.2318 4.46354C63.6141 3.88141 64.5699 3.88141 64.9523 4.26949L70.305 8.92652C70.8785 9.3146 70.8785 10.2848 70.4961 10.6729L68.3933 13.1955"
        stroke="currentColor"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M66.4828 15.7178L59.2184 9.50841C58.8361 9.12032 58.8361 8.34415 59.2184 7.76203C59.6007 7.37394 60.3654 7.1799 60.9389 7.56798L68.0121 13.7773C68.5856 14.1654 68.5856 14.9416 68.2033 15.5237C67.6298 16.1059 66.8651 16.1059 66.4828 15.7178Z"
        stroke="currentColor"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </Fragment>
  ),
  viewBox: "0 0 81 82",
  fill: "none",
}
