/** @jsx jsx */
import { jsx } from "@trueskin-web/theme"
import { questionIcon } from "@trueskin-web/theme/icons/questionIcon"

const sizes = {
  xlg: {
    height: 40,
    width: 40,
  },
  lg: {
    height: 32,
    width: 32,
  },
  md: {
    height: 24,
    width: 24,
  },
  sm: {
    height: 16,
    width: 16,
  },
  xsm: {
    height: 12,
    width: 12,
  },
}

const Icon = ({
  icon: { path = questionIcon.path, viewBox = questionIcon.viewBox, fill } = {},
  size = "md",
  color = "currentColor",
  role = "presentation",
  focusable = false,
  ...rest
}) => (
  <svg
    sx={{
      display: "inline-block",
      verticalAlign: "middle",
      flexShrink: 0,
      backfaceVisibility: "hidden",
      color,
      "&:not(:root)": {
        overflow: "hidden",
      },
      ...sizes[size],
    }}
    viewBox={viewBox}
    focusable={focusable}
    role={role}
    fill={fill}
    {...rest}
  >
    {path}
  </svg>
)

export default Icon
export { sizes }
