/** @jsx jsx */
import { jsx } from "theme-ui"

export const copyIcon = {
  path: (
    <path
      d="M14.5959 0H4.16276C3.38759 0 2.75862 0.628966 2.75862 1.40414V2.75862H1.40414C0.628966 2.75862 0 3.38759 0 4.16276V14.5959C0 15.371 0.628966 16 1.40414 16H11.8372C12.6124 16 13.2414 15.371 13.2414 14.5959V13.2414H14.5959C15.371 13.2414 16 12.6124 16 11.8372V1.40414C16 0.628966 15.371 0 14.5959 0ZM12.1379 14.5959C12.1379 14.7614 12.0028 14.8966 11.8372 14.8966H1.40414C1.23862 14.8966 1.10345 14.7614 1.10345 14.5959V4.16276C1.10345 3.99724 1.23862 3.86207 1.40414 3.86207H11.8372C12.0028 3.86207 12.1379 3.99724 12.1379 4.16276V14.5959ZM14.8966 11.8372C14.8966 12.0028 14.7614 12.1379 14.5959 12.1379H13.2414V4.16276C13.2414 3.38759 12.6124 2.75862 11.8372 2.75862H3.86207V1.40414C3.86207 1.23862 3.99724 1.10345 4.16276 1.10345H14.5959C14.7614 1.10345 14.8966 1.23862 14.8966 1.40414V11.8372Z"
      fill="currentColor"
    />
  ),
  viewBox: "0 0 16 16",
}
